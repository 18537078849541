import { isEqual, cloneDeep } from 'lodash';
// import history from '../../containers/History'
import { reset, getFormValues, initialize, clearFields, SubmissionError } from 'redux-form';
import {
  getDataSource,
  getMe,
  deleteTeamMemberInvite,
  getTeamMemberInvites,
  postTeamMemberInvite,
  getPhysiciansAndEkgStackCount,
  getTeamTriggers,
  putTeamTriggers,
  denormalizeTriggersBackToAPIStructure,
  postPassword,
  getTeamMemberInfo,
  putTeamMemberInfo,
  postAppointmentNotification,
  fetchMemberAppointmentNotification,
} from '../../Store/reducers/app';
// import { getJwtWithNewCredentials } from '../../containers/Auth'
import {
  SETTINGS_PAGE_TABS,
  DATASOURCES,
  TABLE_BULK_ACTIONS,
  TEAM_INVITES_TABLE_COLUMN_HEADERS,
  TEAM_MEMBERS_TABLE_COLUMN_HEADERS,
  SORT_TYPES,
  getPermissionOptions,
  ROUTES,
  FORMS,
  CLEAR_STATE,
  TRIAGE,
  TRIGGER_FREQUENCY_TYPES,
  CUSTOM_TRIGGER_OPTION,
  TRIGGER_TYPES,
} from '../../constants/app';
import {
  filterTeamInvitesIds,
  sortTeamInvitesIds,
} from '../../Utilities/Settings/TeamInviteUtilities';
import {
  filterTeamMembersIds,
  sortTeamMembersIds,
} from '../../Utilities/Settings/TeamMemberUtilities';
import { getNextSortDirection, getSelectedList } from '../../Utilities/Settings/TableUtilities';
import { handleOnTeamMemberClick } from '../../Components/TeamMemberFile/module';
import { ValidatePassword } from '../../Utilities/Utilities';
import { authLogin } from '../../Store/actions/user';
import { fetchPutTeamMember } from '../../Utilities/ApiUrls';

// ------------------------------------
// Constants
// ------------------------------------
const PREFIX = 'Settings.';
export const UPDATE_BULK_ACTION_IS_FETCHING = `${PREFIX}UPDATE_BULK_ACTION_IS_FETCHING`;
export const UPDATE_BULK_ACTION_COMPLETE = `${PREFIX}UPDATE_BULK_ACTION_COMPLETE`;
export const UPDATE_REFRESH_CLICKED = `${PREFIX}UPDATE_REFRESH_CLICKED`;
export const RESET_FILTERS = `${PREFIX}RESET_FILTERS`;
export const RESET_SORTS = `${PREFIX}RESET_SORTS`;
export const UPDATE_SELECTED_INDEX = `${PREFIX}UPDATE_SELECTED_INDEX`;
export const UPDATE_SELECTED_PAGE_TAB = `${PREFIX}UPDATE_SELECTED_PAGE_TAB`;
export const UPDATE_SELECTED_POPOVER = `${PREFIX}UPDATE_SELECTED_POPOVER`;

export const UPDATE_TEAM_INVITES_SORTS = `${PREFIX}UPDATE_TEAM_INVITES_SORTS`;
export const UPDATE_SELECTED_TEAM_INVITES = `${PREFIX}UPDATE_SELECTED_TEAM_INVITES`;
export const UPDATE_SELECTED_TEAM_INVITES_EMAIL = `${PREFIX}UPDATE_SELECTED_TEAM_INVITES_EMAIL`;
export const UPDATE_SELECTED_TEAM_INVITES_FIRST_NAME = `${PREFIX}UPDATE_SELECTED_TEAM_INVITES_FIRST_NAME`;
export const UPDATE_SELECTED_TEAM_INVITES_LAST_NAME = `${PREFIX}UPDATE_SELECTED_TEAM_INVITES_LAST_NAME`;

export const UPDATE_TEAM_MEMBERS_SORTS = `${PREFIX}UPDATE_TEAM_MEMBERS_SORTS`;
export const UPDATE_SELECTED_TEAM_MEMBERS = `${PREFIX}UPDATE_SELECTED_TEAM_MEMBERS`;
export const UPDATE_SELECTED_TEAM_MEMBERS_ID = `${PREFIX}UPDATE_SELECTED_TEAM_MEMBERS_ID`;
export const UPDATE_SELECTED_TEAM_MEMBERS_EMAIL = `${PREFIX}UPDATE_SELECTED_TEAM_MEMBERS_EMAIL`;
export const UPDATE_SELECTED_TEAM_MEMBERS_COUNTRY = `${PREFIX}UPDATE_SELECTED_TEAM_MEMBERS_COUNTRY`;
export const UPDATE_SELECTED_TEAM_MEMBERS_REGION = `${PREFIX}UPDATE_SELECTED_TEAM_MEMBERS_REGION`;
export const UPDATE_SELECTED_TEAM_MEMBERS_ADMIN_PERMISSION_ID = `${PREFIX}UPDATE_SELECTED_TEAM_MEMBERS_ADMIN_PERMISSION_ID`;
export const UPDATE_SELECTED_TEAM_MEMBERS_PHYSICIAN_PERMISSION_ID = `${PREFIX}UPDATE_SELECTED_TEAM_MEMBERS_PHYSICIAN_PERMISSION_ID`;
export const UPDATE_SELECTED_TEAM_MEMBERS_MANAGES_AVAILABILITY = `${PREFIX}UPDATE_SELECTED_TEAM_MEMBERS_MANAGES_AVAILABILITY`;
export const DISPLAY_VALIDATION_ERROR = `${PREFIX}DISPLAY_VALIDATION_ERROR`;
export const UPDATE_PASSWORD_VALIDATION = `${PREFIX}UPDATE_PASSWORD_VALIDATION`;
export const UPDATE_INPUTFIELD_VALIDATION = `${PREFIX}UPDATE_INPUTFIELD_VALIDATION`;
export const UPDATE_CURRENT_PASSWORD = `${PREFIX}UPDATE_CURRENT_PASSWORD`;
export const REQUEST_POST_PASSWORD = `${PREFIX}REQUEST_POST_PASSWORD`;
export const POST_PASSWORD_SUCCESS = `${PREFIX}POST_PASSWORD_SUCCESS`;
export const POST_PASSWORD_FAILURE = `${PREFIX}POST_PASSWORD_FAILURE`;

export const REQUEST_APPOINTMENT_NOTIFICATION = `${PREFIX}REQUEST_APPOINTMENT_NOTIFICATION`;
export const REQUEST_APPOINTMENT_NOTIFICATION_SUCCESS = `${PREFIX}REQUEST_APPOINTMENT_NOTIFICATION_SUCCESS`;
export const REQUEST_APPOINTMENT_NOTIFICATION_FAILURE = `${PREFIX}REQUEST_APPOINTMENT_NOTIFICATION_FAILURE`;
export const REQUEST_POST_APPOINTMENT_NOTIFICATION = `${PREFIX}REQUEST_POST_APPOINTMENT_NOTIFICATION`;
export const POST_APPOINTMENT_NOTIFICATION_SUCCESS = `${PREFIX}POST_APPOINTMENT_NOTIFICATION_SUCCESS`;
export const POST_APPOINTMENT_NOTIFICATION_FAILURE = `${PREFIX}POST_APPOINTMENT_NOTIFICATION_FAILURE`;

export const DATA_REQUEST = `${PREFIX}DATA_REQUEST`;
export const DATA_SUCCESS = `${PREFIX}DATA_SUCCESS`;
export const DATA_FAILURE = `${PREFIX}DATA_FAILURE`;

export const noMatchError = 'The passwords you entered do not match, please try again';
export const noValidateError = 'Your password must meet all the criteria, please try again';

// ------------------------------------
// Actions
// ------------------------------------
export const updateBulkActionIsFetching = (flag) => ({
  type: UPDATE_BULK_ACTION_IS_FETCHING,
  payload: flag,
});
export const updateBulkActionComplete = (flag) => ({
  type: UPDATE_BULK_ACTION_COMPLETE,
  payload: flag,
});
export const updateRefreshClicked = (clicked) => ({
  type: UPDATE_REFRESH_CLICKED,
  payload: clicked,
});
export const resetFilters = () => ({
  type: RESET_FILTERS,
});
export const resetSorts = () => ({
  type: RESET_SORTS,
});
export const updateSelectedIndex = (index) => ({
  type: UPDATE_SELECTED_INDEX,
  payload: index,
});
export const updateSelectedPageTab = (tab) => ({
  type: UPDATE_SELECTED_PAGE_TAB,
  payload: tab,
});
export const updateSelectedPopover = (popover) => ({
  type: UPDATE_SELECTED_POPOVER,
  payload: popover,
});

export const updateTeamInvitesSorts = (sortBy, sortDirection) => ({
  type: UPDATE_TEAM_INVITES_SORTS,
  payload: {
    sortBy,
    sortDirection,
  },
});
export const updateSelectedTeamInvites = (selected) => ({
  type: UPDATE_SELECTED_TEAM_INVITES,
  payload: selected,
});
export const updateSelectedTeamInvitesEmail = (email) => ({
  type: UPDATE_SELECTED_TEAM_INVITES_EMAIL,
  payload: email,
});
export const updateSelectedTeamInvitesFirstName = (firstName) => ({
  type: UPDATE_SELECTED_TEAM_INVITES_FIRST_NAME,
  payload: firstName,
});
export const updateSelectedTeamInvitesLastName = (lastName) => ({
  type: UPDATE_SELECTED_TEAM_INVITES_LAST_NAME,
  payload: lastName,
});

export const updateTeamMembersSorts = (sortBy, sortDirection) => ({
  type: UPDATE_TEAM_MEMBERS_SORTS,
  payload: {
    sortBy,
    sortDirection,
  },
});
export const updateSelectedTeamMembers = (selected) => ({
  type: UPDATE_SELECTED_TEAM_MEMBERS,
  payload: selected,
});
export const updateSelectedTeamMembersId = (id) => ({
  type: UPDATE_SELECTED_TEAM_MEMBERS_ID,
  payload: id,
});
export const updateSelectedTeamMembersEmail = (email) => ({
  type: UPDATE_SELECTED_TEAM_MEMBERS_EMAIL,
  payload: email,
});
export const updateSelectedTeamMembersCountry = (country) => ({
  type: UPDATE_SELECTED_TEAM_MEMBERS_COUNTRY,
  payload: country,
});
export const updateSelectedTeamMembersRegion = (region) => ({
  type: UPDATE_SELECTED_TEAM_MEMBERS_REGION,
  payload: region,
});
export const updateSelectedTeamMembersAdminPermissionId = (id) => ({
  type: UPDATE_SELECTED_TEAM_MEMBERS_ADMIN_PERMISSION_ID,
  payload: id,
});
export const updateSelectedTeamMembersPhysicianPermissionId = (id) => ({
  type: UPDATE_SELECTED_TEAM_MEMBERS_PHYSICIAN_PERMISSION_ID,
  payload: id,
});
export const updateSelectedTeamMembersManagesAvailability = (id) => ({
  type: UPDATE_SELECTED_TEAM_MEMBERS_MANAGES_AVAILABILITY,
  payload: id,
});
export const displayValidationError = (msg) => ({
  type: DISPLAY_VALIDATION_ERROR,
  payload: msg,
});

export const updatePasswordValidation = (validationObj) => ({
  type: UPDATE_PASSWORD_VALIDATION,
  payload: validationObj,
});

export const updateInputValidation = (validation) => ({
  type: UPDATE_INPUTFIELD_VALIDATION,
  payload: validation,
});

export const updateCurrentPasswordField = (newpass) => ({
  type: UPDATE_CURRENT_PASSWORD,
  payload: newpass,
});

export const requestPostPassword = () => ({
  type: REQUEST_POST_PASSWORD,
});
export const postPasswordSuccess = () => ({
  type: POST_PASSWORD_SUCCESS,
});
export const postPasswordFailure = (error) => ({
  type: POST_PASSWORD_FAILURE,
  payload: error,
});
// Appointment notification
export const requestAppointmentNotificationsChanges = () => ({
  type: REQUEST_POST_APPOINTMENT_NOTIFICATION,
});
export const postAppointmentNotificationsChangesSuccess = () => ({
  type: POST_APPOINTMENT_NOTIFICATION_SUCCESS,
});
export const postAppointmentNotificationsChangesFailure = (error) => ({
  type: POST_APPOINTMENT_NOTIFICATION_FAILURE,
  payload: error,
});
export const fetchAppointmentNotifications = () => ({
  type: REQUEST_APPOINTMENT_NOTIFICATION,
});
export const fetchAppointmentNotificationsSuccess = () => ({
  type: REQUEST_APPOINTMENT_NOTIFICATION_SUCCESS,
});
export const fetchAppointmentNotificationsFailure = (error) => ({
  type: REQUEST_APPOINTMENT_NOTIFICATION_FAILURE,
  payload: error,
});
export const dataRequest = () => ({
  type: DATA_REQUEST,
});
export const dataSuccess = () => ({
  type: DATA_SUCCESS,
});
export const dataFailure = () => ({
  type: DATA_FAILURE,
});

export const getSettingsData =
  (forceUpdate = false) =>
  (dispatch, getState) => {
    dispatch(dataRequest());
    const meDataSource = getDataSource(getState(), DATASOURCES.ME);

    let promises;

    if (meDataSource.data && !forceUpdate) {
      promises = Promise.resolve();
    } else {
      promises = dispatch(getMe());
    }

    return promises
      .then(() => {
        const me = getDataSource(getState(), DATASOURCES.ME);

        const { teams } = me.data;

        if (me.data.profile.permissions.isAdmin) {
          return Promise.all([
            dispatch(getTeamMemberInvites(teams[0].id)),
            dispatch(getPhysiciansAndEkgStackCount(teams[0].id)),
            dispatch(getTeamTriggers(teams[0].id)),
          ]);
        } else {
          return Promise.resolve();
        }
      })
      .then(() => dispatch(dataSuccess()))
      .catch((err) => {
        console.log('getSettingsData', err);
        dispatch(dataFailure());
      });
  };

export const handleOnRefreshClick = () => (dispatch, getState) => {
  dispatch(updateRefreshClicked(true));
  return dispatch(getSettingsData(true)).then(() => dispatch(updateRefreshClicked(false)));
};

export const handleOnInviteSubmit = (form) => (dispatch, getState) => {
  dispatch(dataRequest());

  const newTeamMember = {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    country: '',
    permissions: {
      isAdmin: false,
      isPhysician: false,
    },
    notifications: {
      isText: false,
      isEmail: false,
    },
  };
  const regions =
    (form.members && form.members.length && form.members.map((item) => item.region)) || [];
  if (form.region) {
    regions.push(form.region);
  }

  let country;
  if (form.country) {
    country = form.country;
  } else {
    country = '';
    if (regions && regions.length > 0) {
      country = 'US';
    }
  }

  newTeamMember.email = form.email;
  newTeamMember.firstName = form.firstName;
  newTeamMember.lastName = form.lastName;
  newTeamMember.phone = form.phone;
  newTeamMember.country = country;
  newTeamMember.regions = regions;

  newTeamMember.permissions.isAdmin =
    form.isAdmin !== undefined ? form.isAdmin : newTeamMember.permissions.isAdmin;
  newTeamMember.permissions.isPhysician =
    form.isPhysician !== undefined ? form.isPhysician : newTeamMember.permissions.isPhysician;
  newTeamMember.notifications.isText = form.isText;
  newTeamMember.notifications.isEmail = form.isEmail;
  const meDataSource = getDataSource(getState(), DATASOURCES.ME);

  let promises;

  if (meDataSource.data) {
    promises = Promise.resolve();
  } else {
    promises = dispatch(getMe());
  }
  return promises
    .then(() => {
      const me = getDataSource(getState(), DATASOURCES.ME);

      const { teams } = me.data;

      return dispatch(postTeamMemberInvite(teams[0].id, newTeamMember));
    })
    .then(() => {
      const me = getDataSource(getState(), DATASOURCES.ME);

      const { teams } = me.data;

      return dispatch(getTeamMemberInvites(teams[0].id));
    })
    .then(() => {
      dispatch(reset(FORMS.ADD_NEW_MEMBER_FORM));
    })
    .then(() => dispatch(dataSuccess()))
    .catch((err) => {
      dispatch(dataFailure());
      throw new SubmissionError({ email: err });
    });
};

let previousTeamInvitesIdsFiltersAndSorts;
let cacheTeamInvitesIds = [];
export const getTeamInvitesIdsFromDataSource = (state) => {
  const me = getDataSource(state, DATASOURCES.ME);
  const teamInvites = getDataSource(state, DATASOURCES.TEAM_INVITES);
  const {
    selectedTeamInvitesEmail,
    selectedTeamInvitesFirstName,
    selectedTeamInvitesLastName,
    teamInvitesSortBy,
    teamInvitesSortDirection,
    dataIsFetching,
  } = state.settings;

  const filtersAndSorts = {
    meIsFetching: me && me.isFetching,
    teamInvitesIsFetching: teamInvites && teamInvites.isFetching,
    dataIsFetching,
    selectedEmail: selectedTeamInvitesEmail,
    selectedFirstName: selectedTeamInvitesFirstName,
    selectedLastName: selectedTeamInvitesLastName,
    sortBy: teamInvitesSortBy,
    sortDirection: teamInvitesSortDirection,
  };

  let teamInvitesIds = [];
  let teamInvitesById = {};

  if (me && me.data && teamInvites && teamInvites.data) {
    teamInvitesIds = me.data.teams[0].invites;
    teamInvitesById = teamInvites.data.byId;

    if (!isEqual(previousTeamInvitesIdsFiltersAndSorts, filtersAndSorts)) {
      teamInvitesIds = filterTeamInvitesIds(filtersAndSorts, teamInvitesIds, teamInvitesById);
      teamInvitesIds = sortTeamInvitesIds(filtersAndSorts, teamInvitesIds, teamInvitesById);

      cacheTeamInvitesIds = teamInvitesIds;
    }

    previousTeamInvitesIdsFiltersAndSorts = filtersAndSorts;
  }
  return cacheTeamInvitesIds;
};

export const handleOnFilterButtonClick = (columnHeader) => (dispatch, getState) => {
  const { selectedPopover } = getState().settings;

  if (selectedPopover === columnHeader) {
    dispatch(updateSelectedPopover(null));
  } else {
    dispatch(updateSelectedPopover(columnHeader));
  }
};

export const handleOnTeamInvitesFilterChange = (columnHeader, data) => (dispatch, getState) => {
  switch (columnHeader) {
    case TEAM_INVITES_TABLE_COLUMN_HEADERS.EMAIL:
      dispatch(updateSelectedTeamInvitesEmail(data));
      break;
    case TEAM_INVITES_TABLE_COLUMN_HEADERS.FIRST_NAME:
      dispatch(updateSelectedTeamInvitesFirstName(data));
      break;
    case TEAM_INVITES_TABLE_COLUMN_HEADERS.LAST_NAME:
      dispatch(updateSelectedTeamInvitesLastName(data));
      break;
    default:
      break;
  }
};

export const handleOnTeamInvitesSortClick = (columnHeader) => (dispatch, getState) => {
  const { teamInvitesSortBy, teamInvitesSortDirection } = getState().settings;

  if (teamInvitesSortBy === columnHeader) {
    const nextSortDirection = getNextSortDirection(teamInvitesSortDirection);
    dispatch(updateTeamInvitesSorts(columnHeader, nextSortDirection));
  } else {
    const nextSortDirection = getNextSortDirection();
    dispatch(updateTeamInvitesSorts(columnHeader, nextSortDirection));
  }
};

export const handleOnTeamInvitesBulkAction = (bulkActionType, data) => (dispatch, getState) => {
  dispatch(dataRequest());
  const { selectedTeamInvites } = getState().settings;
  const selectedIds = getSelectedList(selectedTeamInvites);
  // console.log(bulkActionType, selectedTeamInvites, selectedIds)

  let promises = Promise.resolve();

  switch (bulkActionType) {
    case TABLE_BULK_ACTIONS.REVOKE_FROM_TEAM:
      promises = promises.then(() => {
        const deleteInvites = selectedIds.map((id) => dispatch(deleteTeamMemberInvite(id)));

        return Promise.all(deleteInvites);
      });
      break;
    default:
      break;
  }

  dispatch(updateBulkActionIsFetching(true));
  return (
    promises
      .then(() => {
        window.setTimeout(() => {
          dispatch(handleOnSelectionClick({}));
        }, 3000);
        dispatch(updateBulkActionIsFetching(false));
        return dispatch(updateBulkActionComplete(true));
      })
      // .then(() => dispatch(updateSelectedTeamInvites({})))
      .then(() => dispatch(dataSuccess()))
      .catch((err) => dispatch(dataFailure()))
  );
};

let previousTeamMembersIdsFiltersAndSorts;
let cacheTeamMembersIds = [];
export const getTeamMembersIdsFromDataSource = (state) => {
  const physicians = getDataSource(state, DATASOURCES.PHYSICIANS);
  const {
    selectedTeamMembersId,
    selectedTeamMembersEmail,
    selectedTeamMembersCountry,
    selectedTeamMembersRegion,
    selectedTeamMembersAdminPermissionId,
    selectedTeamMembersPhysicianPermissionId,
    selectedTeamMembersManagesAvailability,
    teamMembersSortBy,
    teamMembersSortDirection,
    dataIsFetching,
  } = state.settings;

  const filtersAndSorts = {
    physiciansIsFetching: physicians && physicians.isFetching,
    selectedId: selectedTeamMembersId,
    selectedEmail: selectedTeamMembersEmail,
    selectedCountry: selectedTeamMembersCountry,
    selectedRegion: selectedTeamMembersRegion,
    selectedAdminPermissionId: selectedTeamMembersAdminPermissionId,
    selectedPhysicianPermissionId: selectedTeamMembersPhysicianPermissionId,
    selectedTeamMembersManagesAvailability: selectedTeamMembersManagesAvailability,
    dataIsFetching,
    sortBy: teamMembersSortBy,
    sortDirection: teamMembersSortDirection,
  };

  let teamMembersIds = [];
  let teamMembersById = {};

  if (physicians && physicians.data) {
    teamMembersIds = physicians.data.sortedByLastName;
    teamMembersById = physicians.data.byId;

    if (!isEqual(previousTeamMembersIdsFiltersAndSorts, filtersAndSorts)) {
      teamMembersIds = filterTeamMembersIds(filtersAndSorts, teamMembersIds, teamMembersById);
      teamMembersIds = sortTeamMembersIds(filtersAndSorts, teamMembersIds, teamMembersById);

      cacheTeamMembersIds = teamMembersIds;
    }

    previousTeamMembersIdsFiltersAndSorts = filtersAndSorts;
  }

  return cacheTeamMembersIds;
};

export const handleOnTeamMembersFilterChange = (columnHeader, data) => (dispatch, getState) => {
  const { selectedTeamMembersAdminPermissionId, selectedTeamMembersPhysicianPermissionId } =
    getState().settings;

  switch (columnHeader) {
    case TEAM_MEMBERS_TABLE_COLUMN_HEADERS.NAME:
      dispatch(updateSelectedTeamMembersId(data));
      // dispatch(updateSelectedPopover(null));
      break;
    case TEAM_MEMBERS_TABLE_COLUMN_HEADERS.EMAIL:
      dispatch(updateSelectedTeamMembersId(data));
      // dispatch(updateSelectedTeamMembersEmail(data));
      break;
    case TEAM_MEMBERS_TABLE_COLUMN_HEADERS.COUNTRY:
      dispatch(updateSelectedTeamMembersCountry(data));
      break;
    case TEAM_MEMBERS_TABLE_COLUMN_HEADERS.REGION:
      dispatch(updateSelectedTeamMembersRegion(data));
      break;
    case TEAM_MEMBERS_TABLE_COLUMN_HEADERS.ADMIN_PERMISSION:
      if (selectedTeamMembersAdminPermissionId === data) {
        dispatch(
          updateSelectedTeamMembersAdminPermissionId(filters.selectedTeamMembersAdminPermissionId),
        );
      } else {
        dispatch(updateSelectedTeamMembersAdminPermissionId(data));
      }
      break;
    case TEAM_MEMBERS_TABLE_COLUMN_HEADERS.PHYSICIAN_PERMISSION:
      if (selectedTeamMembersPhysicianPermissionId === data) {
        dispatch(
          updateSelectedTeamMembersPhysicianPermissionId(
            filters.selectedTeamMembersPhysicianPermissionId,
          ),
        );
      } else {
        dispatch(updateSelectedTeamMembersPhysicianPermissionId(data));
      }
      break;
    case TEAM_MEMBERS_TABLE_COLUMN_HEADERS.MANAGE_AVAILABILITY:
      // if (selectedTeamMembersPhysicianPermissionId === data) {
      //   dispatch(
      //     updateSelectedTeamMembersPhysicianPermissionId(
      //       filters.selectedTeamMembersPhysicianPermissionId,
      //     ),
      //   );
      // } else {
      //   dispatch(updateSelectedTeamMembersPhysicianPermissionId(data));
      // }
      dispatch(updateSelectedTeamMembersManagesAvailability(data));
      break;
    default:
      break;
  }
};

export const handleOnTeamMembersSortClick = (columnHeader) => (dispatch, getState) => {
  const { teamMembersSortBy, teamMembersSortDirection } = getState().settings;

  if (teamMembersSortBy === columnHeader) {
    const nextSortDirection = getNextSortDirection(teamMembersSortDirection);
    dispatch(updateTeamMembersSorts(columnHeader, nextSortDirection));
  } else {
    const nextSortDirection = getNextSortDirection();
    dispatch(updateTeamMembersSorts(columnHeader, nextSortDirection));
  }
};

export const handleOnTabClick = (tab) => (dispatch, getState) => {
  dispatch(resetFilters());
  dispatch(resetSorts());
  dispatch(updateSelectedPageTab(tab));
  dispatch(updateSelectedIndex(-1));
  dispatch(updateSelectedPopover(initialState.selectedPopover));

  dispatch(dataRequest());
  const meDataSource = getDataSource(getState(), DATASOURCES.ME);

  let promises;

  if (meDataSource.data) {
    promises = Promise.resolve();
  } else {
    promises = dispatch(getMe());
  }

  switch (tab) {
    case SETTINGS_PAGE_TABS.INVITE_TEAM_MEMBER:
      promises = promises.then(() => {
        const me = getDataSource(getState(), DATASOURCES.ME);

        const { teams } = me.data;

        return dispatch(getTeamMemberInvites(teams[0].id));
      });
      break;
    case SETTINGS_PAGE_TABS.TEAM_MEMBERS:
      promises = promises.then(() => {
        const me = getDataSource(getState(), DATASOURCES.ME);

        const { teams } = me.data;

        return dispatch(getPhysiciansAndEkgStackCount(teams[0].id));
      });
      break;
    case SETTINGS_PAGE_TABS.INBOX_SETTINGS:
      promises = promises.then(() => {
        const me = getDataSource(getState(), DATASOURCES.ME);

        const { teams } = me.data;

        return dispatch(getTeamTriggers(teams[0].id));
      });
      break;
    default:
      break;
  }

  return promises
    .then(() => dispatch(dataSuccess()))
    .catch((err) => {
      console.log('getSettingsData', err);
      dispatch(dataFailure());
    });
};

export const handleOnSelectionClick = (selected) => (dispatch, getState) => {
  const ids = getSelectedList(selected);
  dispatch(updateSelectedTeamInvites(selected));

  if (ids.length === 0) {
    dispatch(updateBulkActionComplete(false));
  }
};

export const handleOnTeamMemberRowClick =
  ({ index, rowData }) =>
  (dispatch, getState) => {
    dispatch(handleOnTeamMemberClick(rowData.id));
    dispatch(updateSelectedIndex(index));
    dispatch(handleOnSelectionClick({}));
  };

export const handleOnNavClick =
  (
    resetFiltersAndSorts = true,
    handleRouting = false,
    tab = initialState.selectedPageTab,
    history,
  ) =>
  (dispatch, getState) => {
    if (resetFiltersAndSorts) {
      dispatch(resetFilters());
      dispatch(resetSorts());
    }

    dispatch(updateBulkActionIsFetching(false));
    dispatch(updateBulkActionComplete(false));
    dispatch(updateSelectedPageTab(tab));
    dispatch(updateSelectedIndex(-1));
    dispatch(updateSelectedPopover(initialState.selectedPopover));

    if (handleRouting) {
      history.push(ROUTES.SETTINGS);
    }
  };
// Appointment Notifications change
export const getAppointmentNotificationsSettings = (data) => (dispatch, getState) => {
  const meDataSource = getDataSource(getState(), DATASOURCES.ME);
  let promises;

  if (meDataSource.data) {
    promises = Promise.resolve();
  } else {
    promises = dispatch(getMe());
  }
  dispatch(fetchAppointmentNotifications());

  return promises
    .then(() => {
      const me = getDataSource(getState(), DATASOURCES.ME);
      const { profile, teams } = me.data;
      return Promise.all([dispatch(fetchMemberAppointmentNotification(teams[0].id, profile.id))]);
    })
    .then((data) => dispatch(fetchAppointmentNotificationsSuccess(data)))
    .catch((err) => {
      dispatch(fetchAppointmentNotificationsFailure(err));
    });
};

export const handleAppointmentNotificationsChange = (data) => (dispatch, getState) => {
  dispatch(requestAppointmentNotificationsChanges());
  const me = getDataSource(getState(), DATASOURCES.ME);
  const { profile, teams } = me.data;
  const postDta = {
    emailEnabled: data.isEmail,
    memberId: profile.id,
    smsEnabled: data.isText,
    teamId: teams[0].id,
  };
  return dispatch(postAppointmentNotification(teams[0].id, profile.id, postDta))
    .then(() => dispatch(postAppointmentNotificationsChangesSuccess()))
    .catch((err) => {
      dispatch(postAppointmentNotificationsChangesFailure(err));
    });
};

export const handleOnEditTeamMember = (form) => (dispatch, getState) => {
  dispatch(dataRequest());
  const meDataSource = getDataSource(getState(), DATASOURCES.ME);
  const physiciansDataSource = getDataSource(getState(), DATASOURCES.PHYSICIANS);
  let promises;

  if (meDataSource.data) {
    promises = Promise.resolve();
  } else {
    promises = dispatch(getMe());
  }

  if (physiciansDataSource.data) {
    // no-op
  } else {
    promises = promises.then(() => {
      const me = getDataSource(getState(), DATASOURCES.ME);
      const { teams } = me.data;
      // return dispatch(getTeamMember(teams[0].id, selectedTeamMemberId))
    });
  }
  return promises
    .then(() => {
      const me = getDataSource(getState(), DATASOURCES.ME);
      const physicians = getDataSource(getState(), DATASOURCES.PHYSICIANS);

      const { teams } = me.data;
      dispatch(fetchPutTeamMember(teams[0].id, form.id, form)).then(() =>
        dispatch(handleOnTabClick('TEAM_MEMBERS')),
      );
    })
    .then(() => dispatch(dataSuccess()))
    .catch((err) => {
      console.log('handleOnEditTeamMember', err);
      dispatch(dataFailure());
    });
};

// For editing the info on My Info page in case of Telekardia Enabled
export const handleOnEditTeamMemberSubmit = (form) => (dispatch, getState) => {
  dispatch(dataRequest());
  const meDataSource = getDataSource(getState(), DATASOURCES.ME);

  let promises;

  if (meDataSource.data) {
    promises = Promise.resolve();
  } else {
    promises = dispatch(getMe());
  }

  return promises
    .then(() => {
      const me = getDataSource(getState(), DATASOURCES.ME);

      const { teams, profile } = me.data;

      // const regionArray = [ form.region ]
      const regions =
        (form.members && form.members.length && form.members.map((item) => item.region)) || [];
      if (form.region) {
        regions.push(form.region);
      }
      // console.log('form', form, regions);
      const {
        id,
        firstName,
        lastName,
        email,
        phone,
        country,
        permissions: { isAdmin, isPhysician, isApiUser, isScreener },
      } = form;

      // Payload for put team member
      const teamMember = {
        id,
        email,
        firstName,
        lastName,
        phone,
        country,
        regions,
        permissions: {
          isAdmin,
          isPhysician,
          isApiUser,
          isScreener,
        },
      };
      // console.log('data formatted', teamMember);
      return dispatch(putTeamMemberInfo(teams[0].id, profile.id, teamMember));
    })
    .then(() => dispatch(dataSuccess()))
    .catch((err) => {
      console.log('handleOnEditTeamMemberSubmit', err);
      dispatch(dataFailure());
    });
};

export const handleUpdatePasswordChange = (evt) => (dispatch, getState) => {
  const field = {
    [evt.target.name]: evt.target.value,
  };

  if (field.newPassword || field.newPassword === '') {
    const result = ValidatePassword(field.newPassword);
    dispatch(updatePasswordValidation(result));
  }
};

export const handleUpdateInputChange = (evt) => (dispatch, getState) => {
  const field = {
    [evt.target.name]: evt.target.value,
  };
  const filteredValues = Object.keys(field).filter((item) => {
    return field[item] !== '';
  });
  dispatch(updateInputValidation(filteredValues?.length > 0));
};

export const submitUpdatePassword = (form) => (dispatch, getState) => {
  const { currentPassword, newPassword, newPasswordConfirm } = form;
  const { validationTests } = getState().settings;
  let passedTests = false;
  const testResults = Object.values(validationTests);
  for (let i = 0; i < testResults.length; i += 1) {
    if (!testResults[i]) {
      passedTests = false;
      break;
    } else {
      passedTests = true;
    }
  }

  if (!passedTests) {
    return dispatch(displayValidationError(noValidateError));
  }
  if (newPassword === newPasswordConfirm) {
    return dispatch(postUpdatePassword(currentPassword, newPassword));
  } else {
    return dispatch(displayValidationError(noMatchError));
  }
};
// For getting the info on My Info page in case of Telekardia Enabled
export const getTeamMemberFileData =
  (forceUpdate = false) =>
  (dispatch, getState) => {
    dispatch(dataRequest());
    const meDataSource = getDataSource(getState(), DATASOURCES.ME);
    let promises;

    if (meDataSource.data && !forceUpdate) {
      promises = Promise.resolve();
    } else {
      promises = dispatch(getMe());
    }

    return (
      promises
        .then(() => {
          const me = getDataSource(getState(), DATASOURCES.ME);

          const { teams, profile } = me.data;

          return dispatch(getTeamMemberInfo(teams[0].id, profile.id));
        })
        .then(() => dispatch(dataSuccess()))
        // .then(() => dispatch(getMe()))
        .catch((err) => {
          console.log('getTeamMemberFileData', err);
          dispatch(dataFailure());
        })
    );
  };
export const postUpdatePassword = (currentPassword, newPassword) => (dispatch, getState) => {
  dispatch(requestPostPassword());
  const passObj = {
    currentPassword,
    newPassword,
  };
  return dispatch(postPassword(passObj))
    .then(() => {
      const meDataSource = getDataSource(getState(), DATASOURCES.ME);

      let promises;

      if (meDataSource.data) {
        promises = Promise.resolve();
      } else {
        promises = dispatch(getMe());
      }

      return promises.then(() => {
        const me = getDataSource(getState(), DATASOURCES.ME);

        const { email } = me.data.profile;

        const credentials = {
          email: email,
          password: newPassword,
        };
        dispatch(updateCurrentPasswordField(newPassword));
        return dispatch(authLogin(credentials));
      });
    })
    .then(() => {
      dispatch(postPasswordSuccess());
      dispatch(reset(FORMS.UPDATE_PASSWORD_FORM));
    })
    .catch((err) => {
      dispatch(postPasswordFailure(err));
      dispatch(clearFields(FORMS.UPDATE_PASSWORD_FORM, false, true, 'currentPassword'));
    });
};

export const handleInboxSettingsForm = (form) => (dispatch, getState) => {
  dispatch(dataRequest());
  const { [DATASOURCES.ME]: me, [DATASOURCES.TEAM_TRIGGERS]: teamTriggers } =
    getState().app.dataSources;

  const { teams } = me.data;
  const { ekgReviewWhereRecordings, ekgReviewWhichRecordings, custom } = form;

  // same logic as handleEnrollmentEditForm in PatientFile
  const triggers = (teamTriggers && teamTriggers.data) || {};

  const newForm = {};

  let newTriggers = Object.assign({}, triggers.byId);
  // Set triggers to all false
  newTriggers = Object.keys(newTriggers).reduce((acc, tId) => {
    const trigger = newTriggers[tId];
    trigger.frequency = TRIGGER_FREQUENCY_TYPES.NEVER;
    acc[tId] = trigger;
    return acc;
  }, {});

  if (ekgReviewWhereRecordings.id === TRIAGE) {
    newForm.assignedMemberID = null;
  } else {
    newForm.assignedMemberID = ekgReviewWhereRecordings.id;
  }

  if (ekgReviewWhichRecordings && ekgReviewWhichRecordings.id !== CUSTOM_TRIGGER_OPTION.id) {
    // Non custom trigger (no recording and abnormal/non-normal recordings)
    const { id } = ekgReviewWhichRecordings;
    // console.log('DEBUG: handleInboxSettingsForm', ekgReviewWhichRecordings, newTriggers, id);
    newTriggers[id].frequency = TRIGGER_FREQUENCY_TYPES.ALWAYS;
  } else if (ekgReviewWhichRecordings && ekgReviewWhichRecordings.id === CUSTOM_TRIGGER_OPTION.id) {
    // Custom trigger
    const {
      possibleAFibResultCheckmark,
      possibleAFibResultWithHrCheckmark,
      possibleAFibResultWithHr,
      unclassifiedResultCheckmark,
      heartRateUnderCheckmark,
      heartRateUnder,
      heartRateOverCheckmark,
      heartRateOver,
      bradycardia,
      tachycardia,
      sinusRhythmWithPVCs,
      sinusRhythmWithSVEs,
      sinusRhythmWithWideQRS,
    } = custom;

    if (possibleAFibResultCheckmark) {
      const possibleAFibResultId = triggers.typeToIdLookup[TRIGGER_TYPES.POSSIBLE_AFIB_DETECTION];
      newTriggers[possibleAFibResultId].frequency = TRIGGER_FREQUENCY_TYPES.ALWAYS;
    }
    if (possibleAFibResultWithHrCheckmark) {
      const possibleAFibResultWithHrId =
        triggers.typeToIdLookup[TRIGGER_TYPES.HEART_RATE_OVER_X_AND_AFIB];
      newTriggers[possibleAFibResultWithHrId].frequency = TRIGGER_FREQUENCY_TYPES.ALWAYS;
      newTriggers[possibleAFibResultWithHrId].value = parseInt(possibleAFibResultWithHr, 10);
    }
    if (unclassifiedResultCheckmark) {
      const allUnclassifiedId = triggers.typeToIdLookup[TRIGGER_TYPES.ALL_UNCLASSIFIED];
      newTriggers[allUnclassifiedId].frequency = TRIGGER_FREQUENCY_TYPES.ALWAYS;
    }
    if (heartRateUnderCheckmark) {
      const heartRateUnderId = triggers.typeToIdLookup[TRIGGER_TYPES.HEART_RATE_UNDER_X];
      newTriggers[heartRateUnderId].frequency = TRIGGER_FREQUENCY_TYPES.ALWAYS;
      newTriggers[heartRateUnderId].value = parseInt(heartRateUnder, 10);
    }
    if (heartRateOverCheckmark) {
      const heartRateOverId = triggers.typeToIdLookup[TRIGGER_TYPES.HEART_RATE_OVER_X];
      newTriggers[heartRateOverId].frequency = TRIGGER_FREQUENCY_TYPES.ALWAYS;
      newTriggers[heartRateOverId].value = parseInt(heartRateOver, 10);
    }
    if (bradycardia) {
      const bradycardiaId = triggers.typeToIdLookup[TRIGGER_TYPES.BRADYCARDIA];
      newTriggers[bradycardiaId].frequency = TRIGGER_FREQUENCY_TYPES.ALWAYS;
    }
    if (tachycardia) {
      const tachycardiaId = triggers.typeToIdLookup[TRIGGER_TYPES.TACHYCARDIA];
      newTriggers[tachycardiaId].frequency = TRIGGER_FREQUENCY_TYPES.ALWAYS;
    }
    if (sinusRhythmWithPVCs) {
      const sinusRhythmWithPVCsId = triggers.typeToIdLookup[TRIGGER_TYPES.SINUS_RHYTHM_PVC];
      newTriggers[sinusRhythmWithPVCsId].frequency = TRIGGER_FREQUENCY_TYPES.ALWAYS;
    }
    if (sinusRhythmWithSVEs) {
      const sinusRhythmWithSVEsId = triggers.typeToIdLookup[TRIGGER_TYPES.SINUS_RHYTHM_SVE];
      newTriggers[sinusRhythmWithSVEsId].frequency = TRIGGER_FREQUENCY_TYPES.ALWAYS;
    }
    if (sinusRhythmWithWideQRS) {
      const sinusRhythmWithWideQRSId = triggers.typeToIdLookup[TRIGGER_TYPES.SINUS_RHYTHM_WIDE_QRS];
      newTriggers[sinusRhythmWithWideQRSId].frequency = TRIGGER_FREQUENCY_TYPES.ALWAYS;
    }
  } else {
    // All recordings trigger
    const id = triggers.typeToIdLookup[TRIGGER_TYPES.ALL_RECORDINGS];
    newTriggers[id].frequency = TRIGGER_FREQUENCY_TYPES.ALWAYS;
  }

  newForm.newTeamTriggers = denormalizeTriggersBackToAPIStructure(newTriggers);

  const { newTeamTriggers } = newForm;

  // console.log(form, newTeamTriggers)

  return dispatch(putTeamTriggers(teams[0].id, newTeamTriggers))
    .then(() => dispatch(dataSuccess()))
    .catch((a) => {
      const formValues = getFormValues(FORMS.INBOX_SETTINGS_FORM)(getState());
      dispatch(initialize(FORMS.INBOX_SETTINGS_FORM, formValues));
      dispatch(dataFailure());
    });
};

const filters = Object.assign(
  {},
  {
    selectedTeamInvitesEmail: '',
    selectedTeamInvitesFirstName: '',
    selectedTeamInvitesLastName: '',
    selectedTeamInvites: {},

    selectedTeamMembersId: null,
    selectedTeamMembersEmail: '',
    selectedTeamMembersCountry: '',
    selectedTeamMembersRegion: '',
    selectedTeamMembersAdminPermissionId: getPermissionOptions()[0].id,
    selectedTeamMembersPhysicianPermissionId: getPermissionOptions()[0].id,
    selectedTeamMembers: {},
  },
);
const sorts = Object.assign(
  {},
  {
    teamInvitesSortBy: TEAM_INVITES_TABLE_COLUMN_HEADERS.EMAIL,
    teamInvitesSortDirection: SORT_TYPES.ASC,

    teamMembersSortBy: TEAM_MEMBERS_TABLE_COLUMN_HEADERS.NAME,
    teamMembersSortDirection: SORT_TYPES.ASC,
  },
);
const initialState = {
  ...cloneDeep(filters),
  ...cloneDeep(sorts),
  bulkActionIsFetching: false,
  bulkActionComplete: false,
  refreshClicked: false,
  selectedIndex: -1,
  selectedPageTab: SETTINGS_PAGE_TABS.UPDATE_PASSWORD,
  selectedPopover: null,
  dataIsFetching: false,
  dataLastUpdated: null,
  pwError: false,
  pwErrorMsg: '',
  validationTests: {},
  inputFieldValidation: false,
  currentPasswordField: '',
  pwIsFetching: false,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [UPDATE_BULK_ACTION_IS_FETCHING]: (state, action) => ({
    ...state,
    bulkActionIsFetching: action.payload,
  }),
  [UPDATE_BULK_ACTION_COMPLETE]: (state, action) => ({
    ...state,
    bulkActionComplete: action.payload,
  }),
  [RESET_FILTERS]: (state, action) => ({
    ...state,
    ...filters,
  }),
  [RESET_SORTS]: (state, action) => ({
    ...state,
    ...sorts,
  }),
  [UPDATE_REFRESH_CLICKED]: (state, action) => ({
    ...state,
    refreshClicked: action.payload,
  }),
  [UPDATE_SELECTED_INDEX]: (state, action) => ({
    ...state,
    selectedIndex: action.payload,
  }),
  [UPDATE_SELECTED_PAGE_TAB]: (state, action) => ({
    ...state,
    selectedPageTab: action.payload,
  }),
  [UPDATE_SELECTED_POPOVER]: (state, action) => ({
    ...state,
    selectedPopover: action.payload,
  }),

  [UPDATE_TEAM_INVITES_SORTS]: (state, action) => ({
    ...state,
    teamInvitesSortBy: action.payload.sortBy,
    teamInvitesSortDirection: action.payload.sortDirection,
  }),
  [UPDATE_SELECTED_TEAM_INVITES]: (state, action) => ({
    ...state,
    selectedTeamInvites: action.payload,
  }),
  [UPDATE_SELECTED_TEAM_INVITES_EMAIL]: (state, action) => ({
    ...state,
    selectedTeamInvitesEmail: action.payload,
  }),
  [UPDATE_SELECTED_TEAM_INVITES_FIRST_NAME]: (state, action) => ({
    ...state,
    selectedTeamInvitesFirstName: action.payload,
  }),
  [UPDATE_SELECTED_TEAM_INVITES_LAST_NAME]: (state, action) => ({
    ...state,
    selectedTeamInvitesLastName: action.payload,
  }),

  [UPDATE_TEAM_MEMBERS_SORTS]: (state, action) => ({
    ...state,
    teamMembersSortBy: action.payload.sortBy,
    teamMembersSortDirection: action.payload.sortDirection,
  }),
  [UPDATE_SELECTED_TEAM_MEMBERS]: (state, action) => ({
    ...state,
    selectedTeamMembers: action.payload,
  }),
  [UPDATE_SELECTED_TEAM_MEMBERS_ID]: (state, action) => ({
    ...state,
    selectedTeamMembersId: action.payload,
  }),
  [UPDATE_SELECTED_TEAM_MEMBERS_EMAIL]: (state, action) => ({
    ...state,
    selectedTeamMembersEmail: action.payload,
  }),
  [UPDATE_SELECTED_TEAM_MEMBERS_COUNTRY]: (state, action) => ({
    ...state,
    selectedTeamMembersCountry: action.payload,
  }),
  [UPDATE_SELECTED_TEAM_MEMBERS_REGION]: (state, action) => ({
    ...state,
    selectedTeamMembersRegion: action.payload,
  }),
  [UPDATE_SELECTED_TEAM_MEMBERS_ADMIN_PERMISSION_ID]: (state, action) => ({
    ...state,
    selectedTeamMembersAdminPermissionId: action.payload,
  }),
  [UPDATE_SELECTED_TEAM_MEMBERS_PHYSICIAN_PERMISSION_ID]: (state, action) => ({
    ...state,
    selectedTeamMembersPhysicianPermissionId: action.payload,
  }),
  [UPDATE_SELECTED_TEAM_MEMBERS_MANAGES_AVAILABILITY]: (state, action) => ({
    ...state,
    selectedTeamMembersManagesAvailability: action.payload,
  }),
  [DISPLAY_VALIDATION_ERROR]: (state, action) => ({
    ...state,
    pwError: true,
    pwErrorMsg: action.payload,
  }),
  [UPDATE_PASSWORD_VALIDATION]: (state, action) => ({
    ...state,
    validationTests: action.payload,
  }),
  [UPDATE_INPUTFIELD_VALIDATION]: (state, action) => ({
    ...state,
    inputFieldValidation: action.payload,
  }),
  [UPDATE_CURRENT_PASSWORD]: (state, action) => ({
    ...state,
    currentPasswordField: action.payload,
  }),
  [REQUEST_POST_PASSWORD]: (state, action) => ({
    ...state,
    pwIsFetching: true,
    pwError: false,
  }),
  [POST_PASSWORD_SUCCESS]: (state, action) => ({
    ...state,
    pwIsFetching: false,
  }),
  [POST_PASSWORD_FAILURE]: (state, action) => ({
    ...state,
    pwIsFetching: false,
    pwError: true,
    pwErrorMsg: action.payload,
  }),
  [DATA_REQUEST]: (state, action) => ({
    ...state,
    dataIsFetching: true,
  }),
  [DATA_SUCCESS]: (state, action) => ({
    ...state,
    dataIsFetching: false,
    dataLastUpdated: new Date(),
  }),
  [DATA_FAILURE]: (state, action) => ({
    ...state,
    dataIsFetching: false,
    dataLastUpdated: new Date(),
  }),
  [CLEAR_STATE]: (state, action) => ({ ...initialState }),
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function settingsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
