/* eslint-disable */
import { getDataSource, getMe } from '../../Store/reducers/app';
import { DATASOURCES } from '../../constants/app';

// URL functions
import {
  postNotificationTeamSettings,
  fetchNotificationTeamSettings,
  postBusinessHoursTeamSettings,
  fetchBusinessHoursTeamSettings,
} from '../../Utilities/ApiUrls';

// Constants Types
import {
  TEAM_SETTINGS_CHANGES_REQUEST,
  TEAM_SETTINGS_CHANGES_SUCCESS,
  TEAM_SETTINGS_CHANGES_FAILURE,
  TEAM_SETTINGS_FETCH_REQUEST,
  TEAM_SETTINGS_FETCH_SUCCESS,
  TEAM_SETTINGS_FETCH_FAILURE,
  BUSINESS_HOURS_CHANGES_REQUEST,
  BUSINESS_HOURS_CHANGES_SUCCESS,
  BUSINESS_HOURS_CHANGES_FAILURE,
  BUSINESS_HOURS_FETCH_REQUEST,
  BUSINESS_HOURS_FETCH_SUCCESS,
  BUSINESS_HOURS_FETCH_FAILURE,
} from './types';

// ------------------------------------
// Actions
// ------------------------------------

export const fetchAppointmentNotificationsRequest = () => ({
  type: TEAM_SETTINGS_FETCH_REQUEST,
});
export const fetchAppointmentNotificationsSuccess = (data) => ({
  type: TEAM_SETTINGS_FETCH_SUCCESS,
  payload: data,
});
export const fetchAppointmentNotificationsFailure = (err) => ({
  type: TEAM_SETTINGS_FETCH_FAILURE,
  payload: err,
});

export const appointmentNotificationsRequest = () => ({
  type: TEAM_SETTINGS_CHANGES_REQUEST,
});
export const appointmentNotificationsSuccess = (data) => ({
  type: TEAM_SETTINGS_CHANGES_SUCCESS,
  payload: data,
});
export const appointmentNotificationsFailure = (err) => ({
  type: TEAM_SETTINGS_CHANGES_FAILURE,
  payload: err,
});

export const fetchBusinessHoursRequest = () => ({
  type: BUSINESS_HOURS_FETCH_REQUEST,
});
export const fetchBusinessHoursSuccess = (data) => ({
  type: BUSINESS_HOURS_FETCH_SUCCESS,
  payload: data,
});
export const fetchBusinessHoursFailure = (err) => ({
  type: BUSINESS_HOURS_FETCH_FAILURE,
  payload: err,
});

export const postBusinessHoursRequest = () => ({
  type: BUSINESS_HOURS_CHANGES_REQUEST,
});
export const postBusinessHoursSuccess = (data) => ({
  type: BUSINESS_HOURS_CHANGES_SUCCESS,
  payload: data,
});
export const postBusinessHoursFailure = (err) => ({
  type: BUSINESS_HOURS_CHANGES_FAILURE,
  payload: err,
});

// To fetch the APPointment Notification data
export const fetchAppointmentNotifications = () => (dispatch, getState) => {
  const meDataSource = getDataSource(getState(), DATASOURCES.ME);
  let promises;

  if (meDataSource.data) {
    promises = Promise.resolve();
  } else {
    promises = dispatch(getMe());
  }
  dispatch(fetchAppointmentNotificationsRequest());

  return promises
    .then(() => {
      const me = getDataSource(getState(), DATASOURCES.ME);
      const { profile, teams } = me.data;
      return Promise.all([dispatch(fetchNotificationTeamSettings(teams[0].id, profile.id))]);
    })
    .then((data) => dispatch(fetchAppointmentNotificationsSuccess(data)))
    .catch((err) => {
      dispatch(fetchAppointmentNotificationsFailure(err));
    });
};

// To POST the APPointment Notification data
export const handleAppointmentNotifications = (formData) => (dispatch, getState) => {
  dispatch(appointmentNotificationsRequest());
  const meDataSource = getDataSource(getState(), DATASOURCES.ME);
  let promises;

  if (meDataSource.data) {
    promises = Promise.resolve();
  } else {
    promises = dispatch(getMe());
  }

  return promises
    .then(() => {
      dispatch(appointmentNotificationsRequest());
      const me = getDataSource(getState(), DATASOURCES.ME);
      const { teams, profile } = me.data;
      const apiCalls = [];
      apiCalls.push(dispatch(postNotificationTeamSettings(teams[0].id, profile.id, formData)));
      return Promise.all(apiCalls);
    })
    .then((data) => {
      return dispatch(appointmentNotificationsSuccess(data));
    })
    .catch((err) => {
      return dispatch(appointmentNotificationsFailure(err));
    });
};

// For fetching the Busines Hours Data
export const fetchBusinessHours = () => (dispatch, getState) => {
  const meDataSource = getDataSource(getState(), DATASOURCES.ME);
  let promises;

  if (meDataSource.data) {
    promises = Promise.resolve();
  } else {
    promises = dispatch(getMe());
  }
  dispatch(fetchBusinessHoursRequest());

  return promises
    .then(() => {
      const me = getDataSource(getState(), DATASOURCES.ME);
      const { profile, teams } = me.data;
      return Promise.all([dispatch(fetchBusinessHoursTeamSettings(teams[0].id, profile.id))]);
    })
    .then((data) => dispatch(fetchBusinessHoursSuccess(data)))
    .catch((err) => {
      dispatch(fetchBusinessHoursFailure(err));
    });
};

// Business Hours POST handling

export const postBusinessHours =
  (formData, businessHoursData, dayState) => (dispatch, getState) => {
    // console.log('formdata', formData);
    dispatch(postBusinessHoursRequest());
    const meDataSource = getDataSource(getState(), DATASOURCES.ME);
    let promises;
    if (meDataSource.data) {
      promises = Promise.resolve();
    } else {
      promises = dispatch(getMe());
    }

    // check if fetch data is there, means we have already set the data
    // businessHoursData have length
    if (businessHoursData.length) {
      let multiple = false;
      let data = [];
      // For converting the array of dayOfweek into different objects containing time and single dayofWeek
      // data will be updated data acc. to the requirement of API
      formData.forEach((item) => {
        if (item.dayOfWeek.length > 1) {
          multiple = true;
          item.dayOfWeek.forEach((obj, i) => {
            data.push({
              businessHourEnd: item.businessHourEnd,
              businessHourStart: item.businessHourStart,
              dayOfWeek: obj,
              id: item.id.length > i ? item.id[i] : null,
            });
          });
        } else {
          const obj = {
            businessHourEnd: item.businessHourEnd,
            businessHourStart: item.businessHourStart,
            dayOfWeek: item.dayOfWeek[0],
            id: item.id.length > 0 ? item.id[0] : null,
          };
          data = [...data, obj];
        }
      });
      // console.log('data', data);

      // DELETE Loop if we unselect any day from existing
      businessHoursData.map((item) => {
        let find = false;
        let timeOnly = false;
        // data is updated data
        data.forEach((obj) => {
          // check if item.dayOfWeek is missing in the updated object
          // obj.dayofweek is string & item.dayofeek is integer
          if (
            item.businessHourStart === obj.businessHourStart &&
            item.businessHourEnd === obj.businessHourEnd &&
            item.dayOfWeek == obj.dayOfWeek
          ) {
            find = true;
          }
          if (
            (item.businessHourStart !== obj.businessHourStart ||
              item.businessHourEnd !== obj.businessHourEnd) &&
            item.dayOfWeek == obj.dayOfWeek
          ) {
            timeOnly = true;
          }
        });
        // console.log('tiemonly', timeOnly);
        // if item is missing, call DELETE API
        if (!timeOnly && !find) {
          return promises
            .then(() => {
              const me = getDataSource(getState(), DATASOURCES.ME);
              const { teams, profile } = me.data;
              const apiCalls = [];
              // console.log('ITEM',item)
              apiCalls.push(
                dispatch(
                  postBusinessHoursTeamSettings(
                    teams[0].id,
                    profile.id,
                    multiple,
                    item,
                    false,
                    item.id,
                    true,
                  ),
                ),
              );
              return Promise.all(apiCalls);
            })
            .then((d) => {
              return dispatch(postBusinessHoursSuccess(d));
            })
            .catch((err) => {
              return dispatch(postBusinessHoursFailure(err));
            });
        }
      });

      // POST when new day detection loop
      data.map((item) => {
        let newAdded = false;
        let idObj = {};
        // data is updated data
        businessHoursData.forEach((obj) => {
          // check if item.dayOfWeek is matching obj.
          if (
            item.businessHourStart === obj.businessHourStart &&
            parseInt(item.dayOfWeek) != obj.dayOfWeek
          ) {
            newAdded = true;
            idObj = obj;
          }
        });
        // if new day is added, call PUT API
        if (newAdded) {
          return promises
            .then(() => {
              const me = getDataSource(getState(), DATASOURCES.ME);
              const { teams, profile } = me.data;
              const apiCalls = [];
              apiCalls.push(
                dispatch(
                  postBusinessHoursTeamSettings(
                    teams[0].id,
                    profile.id,
                    false,
                    item,
                    true,
                    null,
                    false,
                  ),
                ),
              );
              return Promise.all(apiCalls);
            })
            .then((d) => {
              return dispatch(postBusinessHoursSuccess(d));
            })
            .catch((err) => {
              return dispatch(postBusinessHoursFailure(err));
            });
        }
      });

      data.map((item) => {
        businessHoursData.forEach((obj) => {
          if (
            (item.businessHourStart !== obj.businessHourStart ||
              item.businessHourEnd !== obj.businessHourEnd) &&
            parseInt(item.dayOfWeek) == obj.dayOfWeek
          ) {
            return promises
              .then(() => {
                const me = getDataSource(getState(), DATASOURCES.ME);
                const { teams, profile } = me.data;
                const apiCalls = [];
                apiCalls.push(
                  dispatch(
                    postBusinessHoursTeamSettings(
                      teams[0].id,
                      profile.id,
                      false,
                      item,
                      false,
                      obj.id,
                      false,
                    ),
                  ),
                );
                return Promise.all(apiCalls);
              })
              .then((d) => {
                return dispatch(postBusinessHoursSuccess(d));
              })
              .catch((err) => {
                return dispatch(postBusinessHoursFailure(err));
              });
          }
        });
      });

      // POST NEW added section
      data.map((item) => {
        let newAdded = false;
        let dayAdd = false;
        let idObj = {};
        businessHoursData.forEach((obj) => {
          // console.log('item.id', item.id);
          if (
            parseInt(item.dayOfWeek) !== obj.dayOfWeek &&
            (item.businessHourStart !== obj.businessHourStart ||
              item.businessHourEnd !== obj.businessHourEnd)
          ) {
            newAdded = true;
            idObj = obj;
          }
          if (
            parseInt(item.dayOfWeek) !== obj.dayOfWeek &&
            (item.businessHourStart !== obj.businessHourStart ||
              item.businessHourEnd !== obj.businessHourEnd)
          ) {
            dayAdd = true;
            idObj = obj;
          }
        });
        // delete item.id
        // if new section added
        if (newAdded || dayAdd) {
          return promises
            .then(() => {
              const me = getDataSource(getState(), DATASOURCES.ME);
              const { teams, profile } = me.data;
              const apiCalls = [];
              apiCalls.push(
                dispatch(
                  postBusinessHoursTeamSettings(
                    teams[0].id,
                    profile.id,
                    false,
                    item,
                    true,
                    null,
                    false,
                  ),
                ),
              );
              return Promise.all(apiCalls);
            })
            .then((d) => {
              return dispatch(postBusinessHoursSuccess(d));
            })
            .catch((err) => {
              return dispatch(postBusinessHoursFailure(err));
            });
        }
      });
    }
    // else it is new POST of data
    else {
      // POST call, based on length of formData.dayOfWeek
      // check whether business hours days are multiple
      let multiple = false;
      let data;
      formData.forEach((item) => {
        if (item.dayOfWeek.length > 1) {
          multiple = true;
          data = [];
          item.dayOfWeek.forEach((obj) => {
            data.push({
              businessHourEnd: item.businessHourEnd,
              businessHourStart: item.businessHourStart,
              dayOfWeek: obj,
            });
          });
        } else {
          data = Object.assign({}, item);
          // eslint-disable-next-line prefer-destructuring
          data.dayOfWeek = item.dayOfWeek[0];
          delete data.id
        }
      });
      // if (formData[0].dayOfWeek.length > 1) {
      //   multiple = true;
      //   data = [];
      //   formData[0].dayOfWeek.forEach(obj => {
      //     data.push({
      //       businessHourEnd: formData[0].businessHourEnd,
      //       businessHourStart: formData[0].businessHourStart,
      //       dayOfWeek: obj,
      //     });
      //   });
      // } else {
      //   data = Object.assign({}, formData[0]);
      //   // eslint-disable-next-line prefer-destructuring
      //   data.dayOfWeek = formData[0].dayOfWeek[0];
      // }
      return promises
        .then(() => {
          const me = getDataSource(getState(), DATASOURCES.ME);
          const { teams, profile } = me.data;
          const apiCalls = [];
          apiCalls.push(
            dispatch(postBusinessHoursTeamSettings(teams[0].id, profile.id, multiple, data, true)),
          );
          return Promise.all(apiCalls);
        })
        .then((d) => {
          return dispatch(postBusinessHoursSuccess(d));
        })
        .catch((err) => {
          return dispatch(postBusinessHoursFailure(err));
        });
    }
  };
