import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { commonStyles } from '../../styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Box, Button, Divider, Grid, Typography } from '@material-ui/core';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { getMemberProfileHealthDetails } from '../../../../Store/actions/memberProfile';
import { useParams } from 'react-router';
import Loading from '../../../../Components/Shared/EcgLoader';
//icons
import Fruit from '../../../../Assets/images/icons/profile/fruit.svg';
import Beat from '../../../../Assets/images/icons/profile/beat.svg';
import Doctor from '../../../../Assets/images/icons/profile/doctor.svg';
import Pill from '../../../../Assets/images/icons/profile/pill.svg';
import { getTimezoneName } from '../../../../Utilities/Utilities';
import moment from 'moment';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
    '& .MuiGrid-item': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      '& .MuiDivider-root': {
        marginTop: '16px',
        color: '#142A39',
      },
    },
    '& .MuiTypography-root': {
      fontFamily: 'Work Sans',
    },
  },
  divWrapperOverride: {
    paddingTop: '32px',
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiBox-root': {
      width: '25%',
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  scheduleRow: {
    marginTop: '24px !important',
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiButton-root': {
      border: '1px solid #142A39',
      borderRadius: '4px',
      fontFamily: 'Work Sans',
      color: '#142A39',
      backgroundColor: 'white',
      fontWeight: 600,
    },
  },
}));

const HealthDetails = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const commonClasses = commonStyles();
  const { memberId } = useParams();

  const { healthData, loading, error } = useSelector((state) => state.memberProfile);
  const coachId = useSelector((state) => state.user.profile.id);
  const teamId = useSelector((state) => state.user.team.id);

  const [data, setData] = useState([]);
  //timezone related data
  const timezoneValue = getTimezoneName();
  const offsetInMinutes = new Date().getTimezoneOffset();
  useEffect(() => {
    dispatch(
      getMemberProfileHealthDetails(teamId, coachId, memberId, offsetInMinutes, timezoneValue),
    );
  }, []);

  //prepare the data\
  let constants = {
    atrialFibrillationHistory: 'Atrial Fibrillation History',
    medications: 'Medications',
    careDelivery: 'Care Delivery',
    lifeStyleDetails: 'Lifestyle Details',
    medicalHistory: 'Medical History',
    symptoms: 'Symptoms',
    episodeManagement: 'Episode Management',
    overallManagement: 'Overall Management',
    triggers: 'Triggers',
    episodes: 'Episodes', //not there
    duration: 'Duration', //not there
    heartMeds: 'Heart Meds',
    medicationList: 'Medication list',
    adherence: 'Adherence',
    newMeds: 'New Meds',
    hospitalAdmissions: 'Hospital Admissions',
    providers: 'Providers',
    emergencyRoomVisits: 'Emergency room visits',
    surgicalHistory: 'Surgical history',
    heartConditions: 'Heart conditions',
    familyHistory: 'Family History',
    otherMedicalConditions: 'Other Medical conditions',
    pregnant: 'Pregnant',
    sleep: 'Sleep',
    nutrition: 'Nutrition',
    wellbeing: 'Overall Wellbeing',
    physicalLimitations: 'Physical limitations',
    exercise: 'Exercise',
    behavior: 'Behaviour',
    title: null,
  };
  let icons = {
    atrialFibrillationHistory: (() => <Beat />)(),
    medications: (() => <Pill />)(),
    careDelivery: (() => <Doctor />)(),
    lifeStyleDetails: (() => <Fruit />)(),
    medicalHistory: (() => <AddCircleIcon />)(),
  };
  useEffect(() => {
    if (healthData && !loading) {
      const {
        lifestyleDetails,
        atrialFibrillationHistory,
        medicalHistory,
        careDelivery,
        medications,
      } = healthData;
      lifestyleDetails.title = 'lifeStyleDetails';
      atrialFibrillationHistory.title = 'atrialFibrillationHistory';
      medications.title = 'medications';
      medicalHistory.title = 'medicalHistory';
      careDelivery.title = 'careDelivery';
      let d = [
        lifestyleDetails,
        atrialFibrillationHistory,
        medicalHistory,
        careDelivery,
        medications,
      ];
      setData(d);
    }
  }, [healthData]);
  return (
    <div className={classes.root}>
      {/* All the components are the same as below, will be making this modular and re use it with data */}
      {loading && <Loading />}
      {data &&
        !loading &&
        data.map((d) => (
          <Box className={classnames(commonClasses.divWrapper, classes.divWrapperOverride)}>
            <Box className={classes.headerWrapper}>
              <Typography variant="h6">{constants[d.title]}</Typography>
              <Box style={{ marginLeft: '10px', marginRight: '32px' }}>
                <Typography variant="subtitle1">
                  Last updated:{' '}
                  {d.updatedTime ? moment(d.updatedDateTime).format('MM/DD/YYYY') : '-'}
                </Typography>
                {icons[d.title]}
              </Box>
            </Box>
            <Box style={{ margin: '16px' }}>
              <Grid container spacing={2}>
                {Object.entries(d).map((e) => {
                  if (constants[e[0]])
                    return (
                      <Grid item xs={4}>
                        <Typography variant="body1">{constants[e[0]]}</Typography>

                        {typeof e[1] === 'object' ? (
                          e[1].map((m) => <Typography variant="body2">{m}</Typography>)
                        ) : typeof e[1] === 'string' ? (
                          <Typography variant="body2">{e[1]}</Typography>
                        ) : (
                          <Typography variant="body2" style={{ color: 'grey' }}>
                            No data
                          </Typography>
                        )}

                        {(Object.values(e[1]).length === 0 || !e[1]) && (
                          <Typography variant="body2" style={{ color: 'grey' }}>
                            No data
                          </Typography>
                        )}
                        <Divider className={classes.divider} />
                      </Grid>
                    );
                })}
              </Grid>
              <div className={classes.scheduleRow}>
                <Button variant="contained">Previous data</Button>
              </div>
            </Box>
            {/* <div className={commonClasses.overlay}>Coming Soon!</div> */}
          </Box>
        ))}
    </div>
  );
};

export default HealthDetails;
