import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import SortIcon from '../../Assets/images/icons/filter.svg';
import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles({
  root: {
    position: 'relative',
    '& .MuiTableCell-head': {
      fontSize: '16px',
      fontFamily: 'Work Sans',
      fontWeight: 'bold',
    },
  },
});

//info icon and sorting icon implementation in table header
const StatusTooltip = (props) => {
  const { id, label } = props.headerValue;
  return (
    <>
      <Grid container spacing="2">
        {(id === 'status' || id === 'bloodPressure') && (
          <Grid item xs={'2'}>
            <InfoOutlinedIcon
              onClick={() => props.handleModalOpen(id)}
              style={{ cursor: 'pointer' }}
            />
          </Grid>
        )}
        <Grid item xs={'auto'}>
          {label}
        </Grid>
        {[
          'participantLastName',
          'bloodPressure',
          'lastLogin',
          'status',
          'programList',
          'coachLastName',
        ].includes(id) && (
          <Grid item style={{ marginTop: '3px' }} xs={'2'}>
            <span style={{ cursor: 'pointer' }}>
              <SortIcon onClick={() => props.handleSorting(id)} />
            </span>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export const EnhancedTableHead = (props) => {
  const classes = useStyle();
  const { columnData, order, orderBy, from, onRequestSort, handleSorting } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.root}>
      <TableRow>
        {/* when this component is rendered from memberlist screen */}
        {from === 'memberList'
          ? columnData.map((headCell) => (
              <TableCell key={headCell.id}>
                <StatusTooltip
                  headerValue={headCell}
                  handleModalOpen={props.handleModalOpen}
                  handleSorting={handleSorting}
                />
              </TableCell>
            ))
          : from === 'adminSessions'
          ? columnData.map((headCell) => (
              <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                {['date', 'coach', 'member'].includes(headCell.id) ? (
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                  >
                    <Grid container spacing="2">
                      <Grid item> {headCell.label}</Grid>
                      <Grid item style={{ marginTop: '3px' }}>
                        <SortIcon />
                      </Grid>
                    </Grid>
                  </TableSortLabel>
                ) : (
                  headCell.label
                )}
              </TableCell>
            ))
          : // when this compoment is rendered from SESSION LOG(member profile) screen
            columnData.map((headCell) => (
              <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                {headCell.id === 'scheduledStartTime' || headCell.id === 'startdatetime' ? (
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                  >
                    <Grid container spacing="2">
                      <Grid item> {headCell.label}</Grid>
                      <Grid item style={{ marginTop: '3px' }}>
                        <SortIcon />
                      </Grid>
                    </Grid>
                  </TableSortLabel>
                ) : (
                  headCell.label
                )}
              </TableCell>
            ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  columnData: PropTypes.array.isRequired,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};
