/* eslint-disable */
import moment from 'moment';
// import download from 'downloadjs';
import { isEqual, cloneDeep } from 'lodash';
import history from '../../../../../Components/History';
// import { checkIdtfFeatureEnabled, checkIdtfServiceProviderFeatureEnabled } from 'configs';

import {
  getFormValues,
  getFormInitialValues,
  initialize,
  reset,
  SubmissionError,
} from 'redux-form';

import {
  getDataSource,
  getMe,
  getPatients,
  getPatient,
  getInterpretations,
  getPhysiciansAndEkgStackCount,
  // getPrescriptions,
  getTeamTriggers,
  putPatient,
  // getPatientReports,
  getPatientTriggers,
  putPatientTriggers,
  denormalizeTriggersBackToAPIStructure,
  dataSourceClearError,
  // putUpdateReports,
  postPatientDisconnect,
  postPatientRevoke,
  // postReportsEmailExport,
  // getCustomReport,
  // postEkgsEmailExport,
  // getAddresses,
  // getInsurances,
  // getIdtfEnrollments,
  // getEnrolledPatientOverview,
} from '../../../../../Store/reducers/app';
import {
  DATASOURCES,
  KARDIA_PRO_START_DATE,
  KARDIA_PRO_END_DATE,
  ALL_PHYSICIANS_OPTION,
  ALL_PRESCRIPTIONS_OPTION,
  getBloodPressureSeverityOptions,
  getEsignOptions,
  getExportOptions,
  PATIENT_FILE_TABS,
  ROUTES,
  MAX_BPM_VALUE,
  MIN_BPM_VALUE,
  MIN_SYSTOLIC_VALUE,
  MAX_SYSTOLIC_VALUE,
  MIN_DIASTOLIC_VALUE,
  MAX_DIASTOLIC_VALUE,
  EKG_TABLE_COLUMN_HEADERS,
  SORT_TYPES,
  BLOOD_PRESSURE_TABLE_COLUMN_HEADERS,
  FORMS,
  TRIGGER_TYPES,
  CUSTOM_TRIGGER_OPTION,
  TRIGGER_FREQUENCY_TYPES,
  ENROLLMENT_TABLE_COLUMN_HEADERS,
  REPORTS_TABLE_COLUMN_HEADERS,
  TRIAGE,
  TABLE_BULK_ACTIONS,
  INITIAL_CUSTOM_REPORT_RANGE_DAYS,
  getDateFormat,
  DATETIME_API_FMT,
  CLEAR_STATE,
  WEIGHT_TABLE_COLUMN_HEADERS,
  MAX_BMI_VALUE,
  MIN_BMI_VALUE,
  MAX_WEIGHT_VALUE,
  MIN_WEIGHT_VALUE,
  ENROLLMENT_CONNECTION_STATUSES,
  parseDateOfBirth,
} from '../../../../../constants/metrics';
import { getEkgResultOptions } from '../../../../../constants/recordingMaps';
import {
  sortWeightRecordingIds,
  filterWeightRecordingIds,
} from '../../../../../Components/Tables/WeightTable/Utilities';
import {
  sortBloodPressureRecordingIds,
  filterBloodPressureRecordingIds,
} from '../../../../../Components/Tables/BloodPressureTable/Utilities';
import {
  sortEkgRecordingIds,
  filterEkgRecordingIds,
} from '../../../../../Components/Tables/EkgTable/Utilities';
// import { sortEnrollmentIds, filterEnrollmentIds } from '../../containers/Tables/EnrollmentTable/Utilities'
// import { sortReportIds, filterReportIds, downloadBillingList } from '../../containers/Tables/ReportTable/Utilities'
import { getNextSortDirection, getSelectedList } from '../../../../../Utilities/Settings/TableUtilities';
import { handleOnNavClick as handleOnSingleReportNavClick } from '../../../../../CoachScreens/Settings/module';
import { getWeightSeverityOptions } from '../../../../../Components/Utilities/Utilities';
import { useParams } from 'react-router';

// import { getEosStatus, getFetchEosReport } from '../../../legacy/containers/ApiUrls'
// import {updateDurationSerialNumber} from '../../../views/state/PatientEnrollment/PatientEnrollmentActions'

// ------------------------------------
// Constants
// ------------------------------------
const PREFIX = 'PatientFile.';
export const DATA_REQUEST = `${PREFIX}DATA_REQUEST`;
export const DATA_SUCCESS = `${PREFIX}DATA_SUCCESS`;
export const DATA_FAILURE = `${PREFIX}DATA_FAILURE`;
export const UPDATE_SELECTED_INDEX = `${PREFIX}UPDATE_SELECTED_INDEX`;
export const UPDATE_SELECTED_TAB = `${PREFIX}UPDATE_SELECTED_TAB`;
export const UPDATE_SELECTED_PATIENT_ID = `${PREFIX}UPDATE_SELECTED_PATIENT_ID`;
export const UPDATE_SELECTED_POPOVER = `${PREFIX}UPDATE_SELECTED_POPOVER`;
export const UPDATE_REFRESH_CLICKED = `${PREFIX}UPDATE_REFRESH_CLICKED`;
export const UPDATE_BULK_ACTION_IS_FETCHING = `${PREFIX}UPDATE_BULK_ACTION_IS_FETCHING`;
export const UPDATE_BULK_ACTION_COMPLETE = `${PREFIX}UPDATE_BULK_ACTION_COMPLETE`;
export const UPDATE_BULK_ACTION_SUCCESS_MESSAGE = `${PREFIX}UPDATE_BULK_ACTION_SUCCESS_MESSAGE`;

export const RESET_FILTERS = `${PREFIX}RESET_FILTERS`;
export const RESET_SORTS = `${PREFIX}RESET_SORTS`;

export const UPDATE_SELECTED_ENROLLMENT_START_DATES = `${PREFIX}UPDATE_SELECTED_ENROLLMENT_START_DATES`;
export const UPDATE_SELECTED_ENROLLMENT_END_DATES = `${PREFIX}UPDATE_SELECTED_ENROLLMENT_END_DATES`;
export const UPDATE_SELECTED_ENROLLMENT_PRESCRIPTION_ID = `${PREFIX}UPDATE_SELECTED_ENROLLMENT_PRESCRIPTION_ID`;
export const UPDATE_SELECTED_ENROLLMENT_ICD10_CODE_ID = `${PREFIX}UPDATE_SELECTED_ENROLLMENT_ICD10_CODE_ID`;
export const UPDATE_SELECTED_ENROLLMENT_BILLING_CODE = `${PREFIX}UPDATE_SELECTED_ENROLLMENT_BILLING_CODE`;
export const UPDATE_SELECTED_ENROLLMENT_ORDER_NUMBER = `${PREFIX}UPDATE_SELECTED_ENROLLMENT_ORDER_NUMBER`;
export const UPDATE_ENROLLMENT_SORTS = `${PREFIX}UPDATE_ENROLLMENT_SORTS`;
export const UPDATE_ENROLLMENT_SORT_BY = `${PREFIX}UPDATE_ENROLLMENT_SORT_BY`;
export const UPDATE_ENROLLMENT_SORT_DIRECTION = `${PREFIX}UPDATE_ENROLLMENT_SORT_DIRECTION`;

export const UPDATE_SELECTED_EKG_RECORDING_ID = `${PREFIX}UPDATE_SELECTED_EKG_RECORDING_ID`;
export const UPDATE_SELECTED_EKG_RESULT = `${PREFIX}UPDATE_SELECTED_EKG_RESULT`;
export const UPDATE_SELECTED_EKG_PHYSICIAN_ID = `${PREFIX}UPDATE_SELECTED_EKG_PHYSICIAN_ID`;
export const UPDATE_SELECTED_EKG_PHYSICIANS = `${PREFIX}UPDATE_SELECTED_EKG_PHYSICIANS`;
export const UPDATE_SELECTED_EKG_DATES = `${PREFIX}UPDATE_SELECTED_EKG_DATES`;
export const UPDATE_SELECTED_EKG_BPM_VALUES = `${PREFIX}UPDATE_SELECTED_EKG_BPM_VALUES`;
export const UPDATE_EKG_SORTS = `${PREFIX}UPDATE_EKG_SORTS`;
export const UPDATE_EKG_SORT_BY = `${PREFIX}UPDATE_EKG_SORT_BY`;
export const UPDATE_EKG_SORT_DIRECTION = `${PREFIX}UPDATE_EKG_SORT_DIRECTION`;
export const UPDATE_SELECTED_EKGS = `${PREFIX}UPDATE_SELECTED_EKGS`;

export const UPDATE_SELECTED_BLOOD_PRESSURE_RECORDING_ID = `${PREFIX}UPDATE_SELECTED_BLOOD_PRESSURE_RECORDING_ID`;
export const UPDATE_SELECTED_BLOOD_PRESSURE_SEVERITY = `${PREFIX}UPDATE_SELECTED_BLOOD_PRESSURE_SEVERITY`;
export const UPDATE_SELECTED_BLOOD_PRESSURE_SOURCE = `${PREFIX}UPDATE_SELECTED_BLOOD_PRESSURE_SOURCE`;
export const UPDATE_SELECTED_BLOOD_PRESSURE_DATES = `${PREFIX}UPDATE_SELECTED_BLOOD_PRESSURE_DATES`;
export const UPDATE_SELECTED_BLOOD_PRESSURES = `${PREFIX}UPDATE_SELECTED_BLOOD_PRESSURES`;
export const UPDATE_SELECTED_BLOOD_PRESSURE_BPM_VALUES = `${PREFIX}UPDATE_SELECTED_BLOOD_PRESSURE_BPM_VALUES`;
export const UPDATE_SELECTED_BLOOD_PRESSURE_SYSTOLIC_VALUES = `${PREFIX}UPDATE_SELECTED_BLOOD_PRESSURE_SYSTOLIC_VALUES`;
export const UPDATE_SELECTED_BLOOD_PRESSURE_DIASTOLIC_VALUES = `${PREFIX}UPDATE_SELECTED_BLOOD_PRESSURE_DIASTOLIC_VALUES`;
export const UPDATE_BLOOD_PRESSURE_SORTS = `${PREFIX}UPDATE_BLOOD_PRESSURE_SORTS`;
export const UPDATE_BLOOD_PRESSURE_SORT_BY = `${PREFIX}UPDATE_BLOOD_PRESSURE_SORT_BY`;
export const UPDATE_BLOOD_PRESSURE_SORT_DIRECTION = `${PREFIX}UPDATE_BLOOD_PRESSURE_SORT_DIRECTION`;

export const UPDATE_SELECTED_REPORT_DATES = `${PREFIX}UPDATE_SELECTED_REPORT_DATES`;
export const UPDATE_SELECTED_REPORT_PHYSICIAN_ID = `${PREFIX}UPDATE_SELECTED_REPORT_PHYSICIAN_ID`;
export const UPDATE_SELECTED_REPORT_PHYSICIANS = `${PREFIX}UPDATE_SELECTED_REPORT_PHYSICIANS`;
export const UPDATE_SELECTED_REPORT_PRESCRIPTION_ID = `${PREFIX}UPDATE_SELECTED_REPORT_PRESCRIPTION_ID`;
export const UPDATE_SELECTED_REPORT_BILLING_CODE = `${PREFIX}UPDATE_SELECTED_REPORT_BILLING_CODE`;
export const UPDATE_SELECTED_REPORT_ESIGNED_ID = `${PREFIX}UPDATE_SELECTED_REPORT_ESIGNED_ID`;
export const UPDATE_SELECTED_REPORT_EXPORTED_ID = `${PREFIX}UPDATE_SELECTED_REPORT_EXPORTED_ID`;
export const UPDATE_REPORT_SORTS = `${PREFIX}UPDATE_REPORT_SORTS`;
export const UPDATE_REPORT_SORT_BY = `${PREFIX}UPDATE_REPORT_SORT_BY`;
export const UPDATE_REPORT_SORT_DIRECTION = `${PREFIX}UPDATE_REPORT_SORT_DIRECTION`;
export const UPDATE_SELECTED_REPORTS = `${PREFIX}UPDATE_SELECTED_REPORTS`;
export const UPDATE_CUSTOM_REPORT_POPOVER_STATE = `${PREFIX}UPDATE_CUSTOM_REPORT_POPOVER_STATE`;
export const RESET_CUSTOM_REPORT_DATE_RANGE = `${PREFIX}RESET_CUSTOM_REPORT_DATE_RANGE`;
export const SET_CUSTOM_REPORT_DATE_RANGE = `${PREFIX}SET_CUSTOM_REPORT_DATE_RANGE`;

export const UPDATE_SELECTED_WEIGHT_RECORDING_ID = `${PREFIX}UPDATE_SELECTED_WEIGHT_RECORDING_ID`;
export const UPDATE_SELECTED_WEIGHT_SEVERITY = `${PREFIX}UPDATE_SELECTED_WEIGHT_SEVERITY`;
export const UPDATE_SELECTED_WEIGHT_SOURCE = `${PREFIX}UPDATE_SELECTED_WEIGHT_SOURCE`;
export const UPDATE_SELECTED_WEIGHT_DATES = `${PREFIX}UPDATE_SELECTED_WEIGHT_DATES`;
export const UPDATE_SELECTED_WEIGHT_BMI_VALUES = `${PREFIX}UPDATE_SELECTED_WEIGHT_BMI_VALUES`;
export const UPDATE_SELECTED_WEIGHT_WEIGHT_VALUES = `${PREFIX}UPDATE_SELECTED_WEIGHT_WEIGHT_VALUES`;
export const UPDATE_SELECTED_WEIGHTS = `${PREFIX}UPDATE_SELECTED_WEIGHTS`;
export const UPDATE_WEIGHT_SORTS = `${PREFIX}UPDATE_WEIGHT_SORTS`;
export const EOS_SUMMARY_REPORT_STATUS = `${PREFIX}EOS_SUMMARY_REPORT_STATUS`;

// ------------------------------------
// Actions
// ------------------------------------
export const dataRequest = () => ({
  type: DATA_REQUEST,
});
export const dataSuccess = () => ({
  type: DATA_SUCCESS,
});
export const dataFailure = () => ({
  type: DATA_FAILURE,
});
export const updateBulkActionIsFetching = (flag) => ({
  type: UPDATE_BULK_ACTION_IS_FETCHING,
  payload: flag,
});
export const updateBulkActionComplete = (flag) => ({
  type: UPDATE_BULK_ACTION_COMPLETE,
  payload: flag,
});
export const updateBulkActionSuccessMessage = (msg) => ({
  type: UPDATE_BULK_ACTION_SUCCESS_MESSAGE,
  payload: msg,
});
export const updateRefreshClicked = (clicked) => ({
  type: UPDATE_REFRESH_CLICKED,
  payload: clicked,
});
export const updateSelectedIndex = (index) => ({
  type: UPDATE_SELECTED_INDEX,
  payload: index,
});
export const updateSelectedTab = (tab) => ({
  type: UPDATE_SELECTED_TAB,
  payload: tab,
});
export const updateSelectedPatientId = (id) => ({
  type: UPDATE_SELECTED_PATIENT_ID,
  payload: id,
});
export const updateSelectedPopover = (popover) => ({
  type: UPDATE_SELECTED_POPOVER,
  payload: popover,
});

export const updateSelectedEnrollmentStartDates = ({ startDate, endDate }) => ({
  type: UPDATE_SELECTED_ENROLLMENT_START_DATES,
  payload: {
    startDate,
    endDate,
  },
});
export const updateSelectedEnrollmentEndDates = ({ startDate, endDate }) => ({
  type: UPDATE_SELECTED_ENROLLMENT_END_DATES,
  payload: {
    startDate,
    endDate,
  },
});
export const updateSelectedEnrollmentPrescriptionId = (id) => ({
  type: UPDATE_SELECTED_ENROLLMENT_PRESCRIPTION_ID,
  payload: id,
});
export const updateSelectedEnrollmentIcd10CodeId = (id) => ({
  type: UPDATE_SELECTED_ENROLLMENT_ICD10_CODE_ID,
  payload: id,
});
export const updateSelectedEnrollmentBillingCode = (code) => ({
  type: UPDATE_SELECTED_ENROLLMENT_BILLING_CODE,
  payload: code,
});
export const updateSelectedEnrollmentOrderNumber = (orderNumber) => ({
  type: UPDATE_SELECTED_ENROLLMENT_ORDER_NUMBER,
  payload: orderNumber,
});
export const updateEnrollmentSorts = (sortBy, sortDirection) => ({
  type: UPDATE_ENROLLMENT_SORTS,
  payload: {
    sortBy,
    sortDirection,
  },
});

export const updateSelectedEkgRecordingId = (id) => ({
  type: UPDATE_SELECTED_EKG_RECORDING_ID,
  payload: id,
});
export const updateSelectedEkgs = (selected) => ({
  type: UPDATE_SELECTED_EKGS,
  payload: selected,
});
export const updateSelectedEkgResult = (id) => ({
  type: UPDATE_SELECTED_EKG_RESULT,
  payload: id,
});
export const updateSelectedEkgPhysicianId = (id) => ({
  type: UPDATE_SELECTED_EKG_PHYSICIAN_ID,
  payload: id,
});
export const updateSelectedEkgPhysicians = (id) => ({
  type: UPDATE_SELECTED_EKG_PHYSICIANS,
  payload: id,
});
export const updateSelectedReportPhysicians = (id) => ({
  type: UPDATE_SELECTED_REPORT_PHYSICIANS,
  payload: id,
});
export const updateSelectedEkgDates = ({ startDate, endDate }) => ({
  type: UPDATE_SELECTED_EKG_DATES,
  payload: {
    startDate,
    endDate,
  },
});
export const updateSelectedEkgBpmValues = ({ startValue, endValue }) => ({
  type: UPDATE_SELECTED_EKG_BPM_VALUES,
  payload: {
    startValue,
    endValue,
  },
});
export const updateEkgSorts = (sortBy, sortDirection) => ({
  type: UPDATE_EKG_SORTS,
  payload: {
    sortBy,
    sortDirection,
  },
});

export const updateSelectedBloodPressureRecordingId = (id) => ({
  type: UPDATE_SELECTED_BLOOD_PRESSURE_RECORDING_ID,
  payload: id,
});
export const updateSelectedBloodPressureSeverity = (id) => ({
  type: UPDATE_SELECTED_BLOOD_PRESSURE_SEVERITY,
  payload: id,
});
export const updateSelectedBloodPressureSource = (id) => ({
  type: UPDATE_SELECTED_BLOOD_PRESSURE_SOURCE,
  payload: id,
});
export const updateSelectedBloodPressureDates = ({ startDate, endDate }) => ({
  type: UPDATE_SELECTED_BLOOD_PRESSURE_DATES,
  payload: {
    startDate,
    endDate,
  },
});
export const updateSelectedBloodPressureBpmValues = ({ startValue, endValue }) => ({
  type: UPDATE_SELECTED_BLOOD_PRESSURE_BPM_VALUES,
  payload: {
    startValue,
    endValue,
  },
});
export const updateSelectedBloodPressureSystolicValues = ({ startValue, endValue }) => ({
  type: UPDATE_SELECTED_BLOOD_PRESSURE_SYSTOLIC_VALUES,
  payload: {
    startValue,
    endValue,
  },
});
export const updateSelectedBloodPressureDiastolicValues = ({ startValue, endValue }) => ({
  type: UPDATE_SELECTED_BLOOD_PRESSURE_DIASTOLIC_VALUES,
  payload: {
    startValue,
    endValue,
  },
});
export const updateBloodPressureSorts = (sortBy, sortDirection) => ({
  type: UPDATE_BLOOD_PRESSURE_SORTS,
  payload: {
    sortBy,
    sortDirection,
  },
});
export const updateSelectedBloodPressures = (selected) => ({
  type: UPDATE_SELECTED_BLOOD_PRESSURES,
  payload: selected,
});

export const updateReportSorts = (sortBy, sortDirection) => ({
  type: UPDATE_REPORT_SORTS,
  payload: {
    sortBy,
    sortDirection,
  },
});
export const updateSelectedReportEsignedId = (id) => ({
  type: UPDATE_SELECTED_REPORT_ESIGNED_ID,
  payload: id,
});
export const updateSelectedReportExportedId = (id) => ({
  type: UPDATE_SELECTED_REPORT_EXPORTED_ID,
  payload: id,
});
export const updateSelectedReportPhysicianId = (id) => ({
  type: UPDATE_SELECTED_REPORT_PHYSICIAN_ID,
  payload: id,
});
export const updateSelectedReportPrescriptionId = (id) => ({
  type: UPDATE_SELECTED_REPORT_PRESCRIPTION_ID,
  payload: id,
});
export const updateSelectedReportBillingCode = (id) => ({
  type: UPDATE_SELECTED_REPORT_BILLING_CODE,
  payload: id,
});
export const updateSelectedReportDates = ({ startDate, endDate }) => ({
  type: UPDATE_SELECTED_REPORT_DATES,
  payload: {
    startDate,
    endDate,
  },
});
export const updateSelectedReports = (selected) => ({
  type: UPDATE_SELECTED_REPORTS,
  payload: selected,
});

export const updateSelectedWeightRecordingId = (id) => ({
  type: UPDATE_SELECTED_WEIGHT_RECORDING_ID,
  payload: id,
});
export const updateSelectedWeightSeverity = (id) => ({
  type: UPDATE_SELECTED_WEIGHT_SEVERITY,
  payload: id,
});
export const updateSelectedWeightSource = (id) => ({
  type: UPDATE_SELECTED_WEIGHT_SOURCE,
  payload: id,
});
export const updateSelectedWeightDates = ({ startDate, endDate }) => ({
  type: UPDATE_SELECTED_WEIGHT_DATES,
  payload: {
    startDate,
    endDate,
  },
});
export const updateSelectedWeightBmiValues = ({ startValue, endValue }) => ({
  type: UPDATE_SELECTED_WEIGHT_BMI_VALUES,
  payload: {
    startValue,
    endValue,
  },
});
export const updateSelectedWeightWeightValues = ({ startValue, endValue }) => ({
  type: UPDATE_SELECTED_WEIGHT_WEIGHT_VALUES,
  payload: {
    startValue,
    endValue,
  },
});
export const updateWeightSorts = (sortBy, sortDirection) => ({
  type: UPDATE_WEIGHT_SORTS,
  payload: {
    sortBy,
    sortDirection,
  },
});
export const updateSelectedWeights = (selected) => ({
  type: UPDATE_SELECTED_WEIGHTS,
  payload: selected,
});

export const resetFilters = () => ({
  type: RESET_FILTERS,
});
export const resetSorts = () => ({
  type: RESET_SORTS,
});

export const updateCustomReportPopoverState = (state) => ({
  type: UPDATE_CUSTOM_REPORT_POPOVER_STATE,
  payload: state,
});
export const resetCustomReportDateRange = () => ({
  type: RESET_CUSTOM_REPORT_DATE_RANGE,
});
export const setCustomReportDateRange = (range) => ({
  type: SET_CUSTOM_REPORT_DATE_RANGE,
  payload: range,
});

export const setInitialPatientFileData = (match, location) => (dispatch, getState) => {
  const { memberId } = match.params;

  dispatch(updateSelectedPatientId(memberId));
};

export const getPatientFileData =
  (forceUpdate = false,patientId) =>
  (dispatch, getState) => {
    dispatch(dataRequest());
    const meDataSource = getDataSource(getState(), DATASOURCES.ME);
    const interpretationsDataSource = getDataSource(getState(), DATASOURCES.INTERPRETATIONS);
    const selectedPatientId  = patientId

    let promises;

    if (meDataSource.data && !forceUpdate) {
      promises = Promise.resolve();
    } else {
      promises = dispatch(getMe());
    }

    if (interpretationsDataSource.data && !forceUpdate) {
      // no-op
    } else {
      promises = promises.then(() => {
        const me = getDataSource(getState(), DATASOURCES.ME);
        const { teams } = me.data;

        return dispatch(getInterpretations(teams[0].id));
      });
    }

    return (
      promises
        .then(() => dispatch(getPatient(selectedPatientId)))
        // commentingout this as this is not required in metrics tab
        // .then(() => dispatch(getPatientReports(selectedPatientId)))
        // .then(() => dispatch(getPatientTriggers(selectedPatientId)))
        .then(() => {
          const apiCalls = [];

          const me = getDataSource(getState(), DATASOURCES.ME);
          const physicians = getDataSource(getState(), DATASOURCES.PHYSICIANS);
          const ekgStackCounts = getDataSource(getState(), DATASOURCES.EKG_STACK_COUNTS);
          const prescriptions = getDataSource(getState(), DATASOURCES.PRESCRIPTIONS);

          // const isIdtfEnabled = checkIdtfFeatureEnabled(me);
          // const isIdtfServiceProviderFeatureEnabled = checkIdtfServiceProviderFeatureEnabled(me);

          const { teams } = me.data;

          // idtf feature enable and service provider feature enable then call these api for the enrollment form data

          // commenting out as this does not belong to metric tab
          // if (isIdtfEnabled || isIdtfServiceProviderFeatureEnabled) {
          //   apiCalls.push(dispatch(getAddresses(selectedPatientId)));
          //   apiCalls.push(dispatch(getInsurances(selectedPatientId)));
          //   apiCalls.push(dispatch(getIdtfEnrollments(selectedPatientId)));
          //   apiCalls.push(dispatch(getEnrolledPatientOverview(selectedPatientId)));
          // }

          apiCalls.push(dispatch(getTeamTriggers(teams[0].id)));

          if (!physicians.data || !ekgStackCounts.data || forceUpdate) {
            apiCalls.push(dispatch(getPhysiciansAndEkgStackCount(teams[0].id)));
          }

          // CommentingOut as this is not required in metrics tab
          // if (!prescriptions.data || forceUpdate) {
          //   apiCalls.push(dispatch(getPrescriptions(teams[0].id)));
          // }

          return Promise.all(apiCalls);
        })
        .then(() => dispatch(dataSuccess()))
        // .then(() => {
        //   const me = getDataSource(getState(), DATASOURCES.ME)
        //   const {
        //     teams,
        //   } = me.data

        //   return dispatch(getPatients(teams[0].id))
        // })
        .catch((err) => {
         
          dispatch(dataFailure());
        })
    );
  };

export const handleOnRefreshClick = () => (dispatch, getState) => {
  const { selectedTab } = getState().patientFile;
  dispatch(updateRefreshClicked(true));
  return dispatch(getPatientFileData(true))
    .then(dispatch(handleOnTabClick(selectedTab)))
    .then(() => dispatch(updateRefreshClicked(false)));
};

export const handleEditPatientFormSubmit = (form) => (dispatch, getState) => {
  dispatch(dataRequest());
  let newForm = Object.assign({}, form);
  const {
    [DATASOURCES.PATIENTS]: patients,
    [DATASOURCES.ENROLLMENTS]: enrollments,
    [DATASOURCES.ME]: me,
    [DATASOURCES.INSURANCES]: insurance,
    [DATASOURCES.ADDRESSES]: address,
  } = getState().app.dataSources;
  const { selectedPatientId } = getState().patientFile;

  // const isIdtfEnabled = checkIdtfFeatureEnabled(me);
  // const isIdtfServiceProviderFeatureEnabled = checkIdtfServiceProviderFeatureEnabled(me);

  let newPatientAddress = Object.assign({}, {});
  let newShippingAddress = Object.assign({}, {});
  let newPrimaryInsurance = Object.assign({}, {});
  let newSecondaryInsurance = Object.assign({}, {});

  let primaryAddressId;
  let shippingAddressId;
  let primaryInsuranceId;
  let shippingInsuranceId;

  // if idtf flag and service provider flag then get these value for the enrollment form
  if (isIdtfEnabled || isIdtfServiceProviderFeatureEnabled) {
    primaryAddressId =
      address.data && address.data.primary_address && address.data.primary_address.id;
    shippingAddressId =
      address.data && address.data.shipping_address && address.data.shipping_address.id;
    primaryInsuranceId =
      insurance.data && insurance.data.primary_insurance && insurance.data.primary_insurance.id;
    shippingInsuranceId =
      insurance.data && insurance.data.secondary_insurance && insurance.data.secondary_insurance.id;
  }

  if (form.patientAddressLine1) {
    newPatientAddress.primaryLine = form.patientAddressLine1;
  }
  if (form.patientAddressLine2) {
    newPatientAddress.secondaryLine = form.patientAddressLine2;
  }
  if (form.patientCity) {
    newPatientAddress.city = form.patientCity;
  }
  if (form.patientState) {
    newPatientAddress.state =
      form && form.patientState && form.patientState.code !== undefined
        ? form.patientState.code
        : form.patientState[0] && form.patientState[0].code;
  }
  if (form.patientZip) {
    newPatientAddress.postalCode = form.patientZip;
  }

  if (form.shippingAddressLine1) {
    newShippingAddress.primaryLine = form.shippingAddressLine1;
  }
  if (form.shippingAddressLine2) {
    newShippingAddress.secondaryLine = form.shippingAddressLine2;
  }
  if (form.shippingCity) {
    newShippingAddress.city = form.shippingCity;
  }
  if (form.shippingState) {
    newShippingAddress.state =
      form && form.shippingState && form.shippingState.code !== undefined
        ? form.shippingState.code
        : form.shippingState[0] && form.shippingState[0].code;
  }
  if (form.shippingZip) {
    newShippingAddress.postalCode = form.shippingZip;
  }

  if (form.primaryInsuranceType) {
    newPrimaryInsurance.insuranceType = form.primaryInsuranceType.value;
  }
  if (form.primaryInsuranceCompanyName) {
    newPrimaryInsurance.insuranceCompanyName = form.primaryInsuranceCompanyName;
  }
  if (form.primaryInsuranceGroup) {
    newPrimaryInsurance.insuranceGroup = form.primaryInsuranceGroup;
  }
  if (form.primaryInsuranceAuthorizationNumber) {
    newPrimaryInsurance.insuranceAuthorizationNumber = form.primaryInsuranceAuthorizationNumber;
  }
  if (form.primaryPolicyHolderName) {
    newPrimaryInsurance.policyHolderName = form.primaryPolicyHolderName;
  }
  if (form.primaryPolicyHolderRelationship) {
    newPrimaryInsurance.policyHolderRelationship = form.primaryPolicyHolderRelationship;
  }
  if (form.primarySubscriberId) {
    newPrimaryInsurance.subscriberId = form.primarySubscriberId;
  }
  if (form.primaryMedicareId) {
    newPrimaryInsurance.medicareId = form.primaryMedicareId;
  }

  if (form.secondaryInsuranceType) {
    newSecondaryInsurance.insuranceType = form.secondaryInsuranceType.value;
  }
  if (form.secondaryInsuranceCompanyName) {
    newSecondaryInsurance.insuranceCompanyName = form.secondaryInsuranceCompanyName;
  }
  if (form.secondaryInsuranceGroup) {
    newSecondaryInsurance.insuranceGroup = form.secondaryInsuranceGroup;
  }
  if (form.secondaryInsuranceAuthorizationNumber) {
    newSecondaryInsurance.insuranceAuthorizationNumber = form.secondaryInsuranceAuthorizationNumber;
  }
  if (form.secondaryPolicyHolderName) {
    newSecondaryInsurance.policyHolderName = form.secondaryPolicyHolderName;
  }
  if (form.secondaryPolicyHolderRelationship) {
    newSecondaryInsurance.policyHolderRelationship = form.secondaryPolicyHolderRelationship;
  }
  if (form.secondarySubscriberId) {
    newSecondaryInsurance.subscriberId = form.secondarySubscriberId;
  }
  if (form.secondaryMedicareId) {
    newSecondaryInsurance.medicareId = form.secondaryMedicareId;
  }

  let addressInsuranceUpdate;
  if (isIdtfEnabled || isIdtfServiceProviderFeatureEnabled) {
    addressInsuranceUpdate = {
      primaryAddressId: primaryAddressId,
      shippingAddressId: shippingAddressId,
      newPatientAddress: newPatientAddress,
      newShippingAddress: newShippingAddress,
      primaryInsuranceId: primaryInsuranceId,
      shippingInsuranceId: shippingInsuranceId,
      newPrimaryInsurance: newPrimaryInsurance,
      newSecondaryInsurance: newSecondaryInsurance,
    };
  } else {
    addressInsuranceUpdate = {};
  }

  if (form.dob && form.dob.length > 2) {
    const { year, month, day } = parseDateOfBirth(form.dob, me);

    if (
      Number.isInteger(parseInt(year, 10)) &&
      Number.isInteger(parseInt(month, 10)) &&
      Number.isInteger(parseInt(day, 10))
    ) {
      newForm.dob = `${year}-${month}-${day}`;
    }
  } else {
    newForm.dob = null;
  }

  if (form.phone) {
    const strippedPhoneNumber = form.phone.replace(/[()-\s]/g, ''); // remove anything but number
    newForm.phone = strippedPhoneNumber;
  } else {
    newForm.phone = '';
  }

  if (form.sex) {
    newForm.sex = form.sex.value;
  } else {
    newForm.sex = 'not_known';
  }

  if (form.socialSecurityNumber) {
    newForm.socialSecurityNumber = form.socialSecurityNumber;
  }

  if (form.middleName) {
    newForm.middleName = form.middleName;
  }
  if (form.alternatePhone) {
    const strippedPhoneNumber = form.alternatePhone.replace(/[()-\s]/g, ''); // remove anything but number
    newForm.alternatePhone = strippedPhoneNumber;
  }
  if (form.emergencyContactName) {
    newForm.emergencyContactName = form.emergencyContactName;
  }
  if (form.emergencyContactPhone) {
    const strippedPhoneNumber = form.emergencyContactPhone.replace(/[()-\s]/g, ''); // remove anything but number
    newForm.emergencyContactPhone = strippedPhoneNumber;
  }
  // NEED TO HAVE ORDERINGPHYSICIANMEMBERID TO SAVE
  if (form.orderingPhysician) {
    newForm.orderingPhysicianMemberID = form.orderingPhysician.id;
    delete newForm.orderingPhysician;
  }

  // Needs this part as enrollment information update and patient information update are the same endpoint and
  // require the same payload to be sent to the backend
  if (patients.data) {
    const p = patients.data.byId[selectedPatientId];
    const currentEnrollmentId =
      (p.enrollments &&
        p.enrollments.find(
          (enrollmentId) =>
            enrollments.data.byId[enrollmentId].connectionStatus === p.connectionStatus,
        )) ||
      {};
    const currentEnrollment = enrollments.data.byId[currentEnrollmentId];
    if (currentEnrollment) {
      newForm.billingCodes = currentEnrollment.billingCodes;
      newForm.icd10Codes = currentEnrollment.icd10Codes;
      newForm.orderNumber = currentEnrollment.orderNumber;
    }
  }

  newForm = prunePatient(newForm, isIdtfEnabled);

  return dispatch(
    putPatient(
      selectedPatientId,
      newForm,
      addressInsuranceUpdate,
      isIdtfEnabled,
      isIdtfServiceProviderFeatureEnabled,
    ),
  )
    .then((patientId) => {
      dispatch(dataSourceClearError(DATASOURCES.PATIENT));
      return dispatch(getPatient(patientId)).then(() => dispatch(getPatientTriggers(patientId)));
    })
    .then(() => {
      const formValues = getFormValues(FORMS.EDIT_PATIENT_FORM)(getState());
      dispatch(initialize(FORMS.EDIT_PATIENT_FORM, formValues));
    })
    .then(() => {
      dispatch(getPatientFileData(true));
      dispatch(dataSuccess());
    })
    .catch((err) => {
      dispatch(dataFailure());
      const error = JSON.parse(err);
      throw new SubmissionError({ [error.type]: error.msg });
    });
};
// prune data to minimum required data for api call
const prunePatient = (p, isIdtfEnabled) => {
  const patient = {
    ...p,
  };

  if (patient.activity !== undefined) {
    delete patient.activity;
  }
  if (patient.archived !== undefined) {
    delete patient.archived;
  }
  if (patient.bloodPressure !== undefined) {
    delete patient.bloodPressure;
  }
  if (patient.codes !== undefined) {
    delete patient.codes;
  }
  if (patient.connectionStatus !== undefined) {
    delete patient.connectionStatus;
  }
  if (patient.enrollment !== undefined) {
    delete patient.enrollment;
  }
  if (patient.enrollments !== undefined) {
    delete patient.enrollments;
  }
  if (patient.hasHeight !== undefined) {
    delete patient.hasHeight;
  }
  if (patient.id !== undefined) {
    delete patient.id;
  }
  if (patient.recordings !== undefined) {
    delete patient.recordings;
  }
  if (patient.recommendation_types_sent !== undefined) {
    delete patient.recommendation_types_sent;
  }
  if (patient.reports !== undefined) {
    delete patient.reports;
  }
  if (patient.weight !== undefined) {
    delete patient.weight;
  }
  if (patient.triggers !== undefined) {
    delete patient.triggers;
  }

  if (!isIdtfEnabled) {
    delete patient.socialSecurityNumber,
      delete patient.alternatePhone,
      delete patient.emergencyContactName;
    delete patient.emergencyContactPhone,
      delete patient.patientAddressLine1,
      delete patient.patientAddressLine2,
      delete patient.patientCity,
      delete patient.patientState,
      delete patient.patientZip,
      delete patient.shippingAddressLine1,
      delete patient.shippingAddressLine2,
      delete patient.shippingCity,
      delete patient.shippingState,
      delete patient.shippingZip,
      delete patient.primaryInsuranceType,
      delete patient.primaryMedicareId,
      delete patient.primaryInsuranceCompanyName,
      delete patient.primaryInsuranceGroup,
      delete patient.primaryInsuranceAuthorizationNumber,
      delete patient.primaryPolicyHolderName,
      delete patient.primaryPolicyHolderRelationship,
      delete patient.primarySubscriberId,
      delete patient.secondaryInsuranceType,
      delete patient.secondaryMedicareId,
      delete patient.secondaryInsuranceCompanyName,
      delete patient.secondaryInsuranceGroup,
      delete patient.secondaryInsuranceAuthorizationNumber,
      delete patient.secondaryPolicyHolderName,
      delete patient.secondaryPolicyHolderRelationship,
      delete patient.secondarySubscriberId,
      delete patient.idtf_connection_id,
      delete patient.middleName;
  }

  return patient;
};

export const handlePrescribeForm = (form) => (dispatch, getState) => {
  dispatch(dataRequest());
  const {
    [DATASOURCES.ME]: me,
    [DATASOURCES.TEAM_TRIGGERS]: teamTriggers,
    [DATASOURCES.PATIENTS]: patients,
  } = getState().app.dataSources;
  const { selectedPatientId } = getState().patientFile;
  const { teams } = me.data;
  const {
    prescriptionPlan,
    ekgReviewWhereRecordings,
    ekgReviewWhichRecordings,
    custom,
    icd10Codes,
    billingCodes,
    orderNumber,
    email,
  } = form;

  const isIdtfChecked = prescriptionPlan && prescriptionPlan.isIDTF;

  const triggers = {
    ...teamTriggers.data,
  };

  const patient = (patients && patients.data && patients.data.byId[selectedPatientId]) || {};

  if (email && patient.email !== email) {
    patient.email = email;
  }

  const newForm = {
    ...prunePatient(patient),
    prescribed: true,
    billingCodes: [],
    icd10Codes: [],
    orderNumber: null,
  };

  let newTriggers = Object.assign({}, triggers.byId);
  // Set triggers to all false
  newTriggers = Object.keys(newTriggers).reduce((acc, tId) => {
    const trigger = newTriggers[tId];
    trigger.frequency = TRIGGER_FREQUENCY_TYPES.NEVER;
    acc[tId] = trigger;
    return acc;
  }, {});

  if (prescriptionPlan) {
    newForm.connectionTemplateID = prescriptionPlan.id;
  }

  if (ekgReviewWhichRecordings && ekgReviewWhichRecordings.id !== CUSTOM_TRIGGER_OPTION.id) {
    // Non custom trigger (no recording and abnormal/non-normal recordings)
    const { id } = ekgReviewWhichRecordings;

    newTriggers[id].frequency = TRIGGER_FREQUENCY_TYPES.ALWAYS;
  } else if (ekgReviewWhichRecordings && ekgReviewWhichRecordings.id === CUSTOM_TRIGGER_OPTION.id) {
    // Custom trigger
    const {
      possibleAFibResultCheckmark,
      possibleAFibResultWithHrCheckmark,
      possibleAFibResultWithHr,
      unclassifiedResultCheckmark,
      heartRateUnderCheckmark,
      heartRateUnder,
      heartRateOverCheckmark,
      heartRateOver,
      bradycardia,
      tachycardia,
      sinusRhythmWithPVCs,
      sinusRhythmWithSVEs,
      sinusRhythmWithWideQRS,
    } = custom;

    if (possibleAFibResultCheckmark) {
      const possibleAFibResultId = triggers.typeToIdLookup[TRIGGER_TYPES.POSSIBLE_AFIB_DETECTION];
      newTriggers[possibleAFibResultId].frequency = TRIGGER_FREQUENCY_TYPES.ALWAYS;
    }
    if (possibleAFibResultWithHrCheckmark) {
      const possibleAFibResultWithHrId =
        triggers.typeToIdLookup[TRIGGER_TYPES.HEART_RATE_OVER_X_AND_AFIB];
      newTriggers[possibleAFibResultWithHrId].frequency = TRIGGER_FREQUENCY_TYPES.ALWAYS;
      newTriggers[possibleAFibResultWithHrId].value = parseInt(possibleAFibResultWithHr, 10);
    }
    if (unclassifiedResultCheckmark) {
      const allUnclassifiedId = triggers.typeToIdLookup[TRIGGER_TYPES.ALL_UNCLASSIFIED];
      newTriggers[allUnclassifiedId].frequency = TRIGGER_FREQUENCY_TYPES.ALWAYS;
    }
    if (heartRateUnderCheckmark) {
      const heartRateUnderId = triggers.typeToIdLookup[TRIGGER_TYPES.HEART_RATE_UNDER_X];
      newTriggers[heartRateUnderId].frequency = TRIGGER_FREQUENCY_TYPES.ALWAYS;
      newTriggers[heartRateUnderId].value = parseInt(heartRateUnder, 10);
    }
    if (heartRateOverCheckmark) {
      const heartRateOverId = triggers.typeToIdLookup[TRIGGER_TYPES.HEART_RATE_OVER_X];
      newTriggers[heartRateOverId].frequency = TRIGGER_FREQUENCY_TYPES.ALWAYS;
      newTriggers[heartRateOverId].value = parseInt(heartRateOver, 10);
    }
    if (bradycardia) {
      const bradycardiaId = triggers.typeToIdLookup[TRIGGER_TYPES.BRADYCARDIA];
      newTriggers[bradycardiaId].frequency = TRIGGER_FREQUENCY_TYPES.ALWAYS;
    }
    if (tachycardia) {
      const tachycardiaId = triggers.typeToIdLookup[TRIGGER_TYPES.TACHYCARDIA];
      newTriggers[tachycardiaId].frequency = TRIGGER_FREQUENCY_TYPES.ALWAYS;
    }
    if (sinusRhythmWithPVCs) {
      const sinusRhythmWithPVCsId = triggers.typeToIdLookup[TRIGGER_TYPES.SINUS_RHYTHM_PVC];
      newTriggers[sinusRhythmWithPVCsId].frequency = TRIGGER_FREQUENCY_TYPES.ALWAYS;
    }
    if (sinusRhythmWithSVEs) {
      const sinusRhythmWithSVEsId = triggers.typeToIdLookup[TRIGGER_TYPES.SINUS_RHYTHM_SVE];
      newTriggers[sinusRhythmWithSVEsId].frequency = TRIGGER_FREQUENCY_TYPES.ALWAYS;
    }
    if (sinusRhythmWithWideQRS) {
      const sinusRhythmWithWideQRSId = triggers.typeToIdLookup[TRIGGER_TYPES.SINUS_RHYTHM_WIDE_QRS];
      newTriggers[sinusRhythmWithWideQRSId].frequency = TRIGGER_FREQUENCY_TYPES.ALWAYS;
    }
  } else {
    // All recordings trigger
    const id = triggers.typeToIdLookup[TRIGGER_TYPES.ALL_RECORDINGS];
    newTriggers[id].frequency = TRIGGER_FREQUENCY_TYPES.ALWAYS;
  }

  newForm.participantTriggers = denormalizeTriggersBackToAPIStructure(newTriggers);

  if (ekgReviewWhereRecordings.id !== TRIAGE) {
    newForm.assignedMemberID = ekgReviewWhereRecordings.id;
  } else {
    newForm.assignedMemberID = null;
  }

  if (billingCodes) {
    newForm.billingCodes.push(billingCodes);
  }
  if (isIdtfChecked) {
    if (typeof icd10Codes !== 'string' && !Array.isArray(icd10Codes)) {
      // if value is object add it
      newForm.icd10Codes.push(icd10Codes);
    } else if (Array.isArray(icd10Codes)) {
      // if value is array just use it
      newForm.icd10Codes = icd10Codes;
    }
  } else {
    if (icd10Codes) {
      newForm.icd10Codes.push(icd10Codes);
    }
  }

  if (orderNumber) {
    newForm.orderNumber = orderNumber;
  }

  const { participantTriggers, ...profile } = newForm;

  return dispatch(putPatientTriggers(selectedPatientId, participantTriggers))
    .then(() => dispatch(putPatient(selectedPatientId, profile)))
    .then((patientId) => {
      dispatch(dataSourceClearError(DATASOURCES.PATIENT));
      return dispatch(getPatient(patientId)).then(() => dispatch(getPatientTriggers(patientId)));
    })
    .then(() => dispatch(dataSuccess()))
    .catch((a) => {
      const formValues = getFormValues(FORMS.PRESCRIBE_FORM)(getState());
      dispatch(initialize(FORMS.PRESCRIBE_FORM, formValues));
      dispatch(dataFailure());
    });
};

export const handleEnrollmentOnRevokeClick =
  (connectionStatus = '', code = '') =>
  (dispatch, getState) => {
    const { selectedPatientId } = getState().patientFile;

    switch (connectionStatus) {
      case ENROLLMENT_CONNECTION_STATUSES.CONNECTED:
        return dispatch(postPatientDisconnect(selectedPatientId));
      case ENROLLMENT_CONNECTION_STATUSES.PENDING:
        return dispatch(postPatientRevoke(selectedPatientId, code));
      default:
    }
  };

//updateDurationSerialNumber
export const handleIdtfEnrollmentEditForm = (form) => (dispatch, getState) => {
  const formData = {
    // when not getting edited form.idtfDuration[0], else value is in form.idtfDuration
    study_duration_in_days: form.idtfDuration[0]
      ? parseInt(form.idtfDuration[0].value)
      : parseInt(form.idtfDuration.value),
    device_serial_number: form.device_serial_number,
  };
  const patientId =
    getState().app &&
    getState().app.dataSources &&
    getState().app.dataSources.data &&
    getState()
      .app.dataSources.data.id// dispatch(updateDurationSerialNumber(formData))
      .then(() => {
        dispatch(handleOnRefreshClick());
      });
};

export const handleEnrollmentEditForm = (form) => (dispatch, getState) => {
  dispatch(dataRequest());
  const {
    [DATASOURCES.ME]: me,
    [DATASOURCES.TEAM_TRIGGERS]: teamTriggers,
    [DATASOURCES.PATIENTS]: patients,
  } = getState().app.dataSources;
  const { selectedPatientId } = getState().patientFile;
  const { teams } = me.data;
  const {
    ekgReviewWhereRecordings,
    ekgReviewWhichRecordings,
    custom,
    icd10Codes,
    billingCodes,
    orderNumber,
  } = form;

  const patient = (patients && patients.data && patients.data.byId[selectedPatientId]) || {};

  const triggers = {
    ...patient.triggers,
  };

  const newForm = {
    ...prunePatient(patient),
    billingCodes: [],
    icd10Codes: [],
    // billingCodes: null,
    // icd10Codes: null,
    orderNumber: null,
  };

  let newTriggers = Object.assign({}, triggers.byId);
  // Set triggers to all false
  newTriggers = Object.keys(newTriggers).reduce((acc, tId) => {
    const trigger = newTriggers[tId];
    trigger.frequency = TRIGGER_FREQUENCY_TYPES.NEVER;
    acc[tId] = trigger;
    return acc;
  }, {});

  if (typeof billingCodes !== 'string' && !Array.isArray(billingCodes)) {
    // if value is object add it
    newForm.billingCodes.push(billingCodes);
  } else if (Array.isArray(billingCodes)) {
    // if value is array just use it
    newForm.billingCodes = billingCodes;
  }

  if (typeof icd10Codes !== 'string' && !Array.isArray(icd10Codes)) {
    // if value is object add it
    newForm.icd10Codes.push(icd10Codes);
  } else if (Array.isArray(icd10Codes)) {
    // if value is array just use it
    newForm.icd10Codes = icd10Codes;
  }

  if (typeof orderNumber === 'string' && orderNumber.trim().length > 0) {
    newForm.orderNumber = orderNumber;
  }

  if (ekgReviewWhereRecordings.id === TRIAGE) {
    newForm.assignedMemberID = null;
  } else {
    newForm.assignedMemberID = ekgReviewWhereRecordings.id;
  }

  if (ekgReviewWhichRecordings && ekgReviewWhichRecordings.id !== CUSTOM_TRIGGER_OPTION.id) {
    // Non custom trigger (no recording and abnormal/non-normal recordings)
    const { id } = ekgReviewWhichRecordings;
    // console.log('DEBUG: handleEnrollmentEditForm', ekgReviewWhichRecordings, newTriggers, id);
    newTriggers[id].frequency = TRIGGER_FREQUENCY_TYPES.ALWAYS;
  } else if (ekgReviewWhichRecordings && ekgReviewWhichRecordings.id === CUSTOM_TRIGGER_OPTION.id) {
    // Custom trigger
    const {
      possibleAFibResultCheckmark,
      possibleAFibResultWithHrCheckmark,
      possibleAFibResultWithHr,
      unclassifiedResultCheckmark,
      heartRateUnderCheckmark,
      heartRateUnder,
      heartRateOverCheckmark,
      heartRateOver,
      bradycardia,
      tachycardia,
      sinusRhythmWithPVCs,
      sinusRhythmWithSVEs,
      sinusRhythmWithWideQRS,
    } = custom;

    if (possibleAFibResultCheckmark) {
      const possibleAFibResultId = triggers.typeToIdLookup[TRIGGER_TYPES.POSSIBLE_AFIB_DETECTION];
      newTriggers[possibleAFibResultId].frequency = TRIGGER_FREQUENCY_TYPES.ALWAYS;
    }
    if (possibleAFibResultWithHrCheckmark) {
      const possibleAFibResultWithHrId =
        triggers.typeToIdLookup[TRIGGER_TYPES.HEART_RATE_OVER_X_AND_AFIB];
      newTriggers[possibleAFibResultWithHrId].frequency = TRIGGER_FREQUENCY_TYPES.ALWAYS;
      newTriggers[possibleAFibResultWithHrId].value = parseInt(possibleAFibResultWithHr, 10);
    }
    if (unclassifiedResultCheckmark) {
      const allUnclassifiedId = triggers.typeToIdLookup[TRIGGER_TYPES.ALL_UNCLASSIFIED];
      newTriggers[allUnclassifiedId].frequency = TRIGGER_FREQUENCY_TYPES.ALWAYS;
    }
    if (heartRateUnderCheckmark) {
      const heartRateUnderId = triggers.typeToIdLookup[TRIGGER_TYPES.HEART_RATE_UNDER_X];
      newTriggers[heartRateUnderId].frequency = TRIGGER_FREQUENCY_TYPES.ALWAYS;
      newTriggers[heartRateUnderId].value = parseInt(heartRateUnder, 10);
    }
    if (heartRateOverCheckmark) {
      const heartRateOverId = triggers.typeToIdLookup[TRIGGER_TYPES.HEART_RATE_OVER_X];
      newTriggers[heartRateOverId].frequency = TRIGGER_FREQUENCY_TYPES.ALWAYS;
      newTriggers[heartRateOverId].value = parseInt(heartRateOver, 10);
    }
    if (bradycardia) {
      const bradycardiaId = triggers.typeToIdLookup[TRIGGER_TYPES.BRADYCARDIA];
      newTriggers[bradycardiaId].frequency = TRIGGER_FREQUENCY_TYPES.ALWAYS;
    }
    if (tachycardia) {
      const tachycardiaId = triggers.typeToIdLookup[TRIGGER_TYPES.TACHYCARDIA];
      newTriggers[tachycardiaId].frequency = TRIGGER_FREQUENCY_TYPES.ALWAYS;
    }
    if (sinusRhythmWithPVCs) {
      const sinusRhythmWithPVCsId = triggers.typeToIdLookup[TRIGGER_TYPES.SINUS_RHYTHM_PVC];
      newTriggers[sinusRhythmWithPVCsId].frequency = TRIGGER_FREQUENCY_TYPES.ALWAYS;
    }
    if (sinusRhythmWithSVEs) {
      const sinusRhythmWithSVEsId = triggers.typeToIdLookup[TRIGGER_TYPES.SINUS_RHYTHM_SVE];
      newTriggers[sinusRhythmWithSVEsId].frequency = TRIGGER_FREQUENCY_TYPES.ALWAYS;
    }
    if (sinusRhythmWithWideQRS) {
      const sinusRhythmWithWideQRSId = triggers.typeToIdLookup[TRIGGER_TYPES.SINUS_RHYTHM_WIDE_QRS];
      newTriggers[sinusRhythmWithWideQRSId].frequency = TRIGGER_FREQUENCY_TYPES.ALWAYS;
    }
  } else {
    // All recordings trigger
    const id = triggers.typeToIdLookup[TRIGGER_TYPES.ALL_RECORDINGS];
    newTriggers[id].frequency = TRIGGER_FREQUENCY_TYPES.ALWAYS;
  }

  newForm.participantTriggers = denormalizeTriggersBackToAPIStructure(newTriggers);

  const { participantTriggers, ...profile } = newForm;

  // console.log(form, participantTriggers, profile)

  return dispatch(putPatientTriggers(selectedPatientId, participantTriggers))
    .then(() => dispatch(putPatient(selectedPatientId, profile)))
    .then((patientId) => {
      dispatch(dataSourceClearError(DATASOURCES.PATIENT));
      return dispatch(getPatient(patientId)).then(() => dispatch(getPatientTriggers(patientId)));
    })
    .then(() => dispatch(dataSuccess()))
    .catch((a) => {
      const formValues = getFormValues(FORMS.ENROLLMENT_FORM)(getState());
      dispatch(initialize(FORMS.ENROLLMENT_FORM, formValues));
      dispatch(dataFailure());
    });
};

let previousReportsIdsFiltersAndSorts;
let cacheReportIds = [];
export const getReportIdsFromDataSource = (state) => {
  const {
    [DATASOURCES.PATIENTS]: patients,
    [DATASOURCES.PATIENT]: patient,
    [DATASOURCES.REPORTS]: reports,
    [DATASOURCES.PRESCRIPTIONS]: prescriptions,
    [DATASOURCES.PHYSICIANS]: physicians,
    [DATASOURCES.BILLING_CODES]: billingCodes,
  } = state.app.dataSources;
  const {
    selectedPatientId,
    selectedReportStartDate,
    selectedReportEndDate,
    selectedReportPhysicianId,
    selectedReportPhysicians,
    selectedReportPrescriptionId,
    selectedReportBillingCode,
    selectedReportEsignedId,
    selectedReportExportedId,
    reportSortBy,
    reportSortDirection,
    // dataIsFetching,
  } = state.patientFile;

  const filtersAndSorts = {
    reportsIsFetching: reports && reports.isFetching,
    // dataIsFetching,
    selectedPatientId,
    selectedStartDate: selectedReportStartDate,
    selectedEndDate: selectedReportEndDate,
    selectedPhysicianId: selectedReportPhysicianId,
    selectedPhysicians: selectedReportPhysicians,
    selectedPrescriptionId: selectedReportPrescriptionId,
    selectedBillingCode: selectedReportBillingCode,
    selectedEsignedId: selectedReportEsignedId,
    selectedExportedId: selectedReportExportedId,
    sortBy: reportSortBy,
    sortDirection: reportSortDirection,
  };

  let reportIds = [];
  let reportByIds = {};
  let patientByIds = {};
  let prescriptionByIds = {};
  let physicianByIds = {};
  let billingCodeByIds = {};

  if (
    patient &&
    patient.data &&
    patient.data.id === selectedPatientId &&
    patients &&
    patients.data &&
    patients.data.byId[selectedPatientId] &&
    reports &&
    reports.data &&
    prescriptions &&
    prescriptions.data &&
    physicians &&
    physicians.data &&
    billingCodes &&
    billingCodes.data
  ) {
    reportIds = patients.data.byId[selectedPatientId].reports;
    reportByIds = reports.data.byId;
    patientByIds = patients.data.byId;
    prescriptionByIds = prescriptions.data.byId;
    physicianByIds = physicians.data.byId;
    billingCodeByIds = billingCodes.data.byId;

    // if (!isEqual(previousReportsIdsFiltersAndSorts, filtersAndSorts)) {
    //   reportIds = filterReportIds(filtersAndSorts, reportIds, reportByIds, billingCodeByIds)
    //   reportIds = sortReportIds(filtersAndSorts, reportIds, reportByIds, patientByIds, prescriptionByIds, physicianByIds, billingCodeByIds)

    //   cacheReportIds = reportIds
    // }

    previousReportsIdsFiltersAndSorts = filtersAndSorts;
  }

  return cacheReportIds;
};

export const handleOnReportSortClick = (columnHeader) => (dispatch, getState) => {
  const { reportSortBy, reportSortDirection } = getState().patientFile;

  if (reportSortBy === columnHeader) {
    const nextSortDirection = getNextSortDirection(reportSortDirection);
    dispatch(updateReportSorts(columnHeader, nextSortDirection));
  } else {
    const nextSortDirection = getNextSortDirection();
    dispatch(updateReportSorts(columnHeader, nextSortDirection));
  }
};

export const handleOnReportFilterChange = (columnHeader, data) => (dispatch, getState) => {
  const { selectedReportEsignedId, selectedReportExportedId } = getState().patientFile;

  switch (columnHeader) {
    case REPORTS_TABLE_COLUMN_HEADERS.END_DATE:
      dispatch(updateSelectedReportDates(data));
      break;
    case REPORTS_TABLE_COLUMN_HEADERS.PHYSICIAN:
      dispatch(updateSelectedReportPhysicianId(data));
      dispatch(updateSelectedPopover(null));
      break;
    case REPORTS_TABLE_COLUMN_HEADERS.ORDERING_PHYSICIAN:
      dispatch(updateSelectedReportPhysicians(data));
      break;
    case REPORTS_TABLE_COLUMN_HEADERS.PRESCRIPTION:
      dispatch(updateSelectedReportPrescriptionId(data));
      dispatch(updateSelectedPopover(null));
      break;
    case REPORTS_TABLE_COLUMN_HEADERS.BILLING_CODE:
      dispatch(updateSelectedReportBillingCode(data));
      break;
    case REPORTS_TABLE_COLUMN_HEADERS.ESIGNED:
      if (selectedReportEsignedId === data) {
        dispatch(updateSelectedReportEsignedId(filters.selectedReportEsignedId));
      } else {
        dispatch(updateSelectedReportEsignedId(data));
      }
      break;
    case REPORTS_TABLE_COLUMN_HEADERS.EXPORTED:
      if (selectedReportExportedId === data) {
        dispatch(updateSelectedReportExportedId(filters.selectedReportExportedId));
      } else {
        dispatch(updateSelectedReportExportedId(data));
      }
      break;
    default:
      break;
  }
};

export const handleOnSelectionClick = (selected, callback) => (dispatch, getState) => {
  const ids = getSelectedList(selected);
  if (typeof callback === 'function') {
    dispatch(callback(selected));
  }

  if (ids.length === 0) {
    dispatch(updateBulkActionComplete(false));
  }
};

export const handleOnReportsBulkAction = (bulkActionType, data) => (dispatch, getState) => {
  dispatch(dataRequest());
  const { selectedReports } = getState().patientFile;
  const {
    [DATASOURCES.REPORTS]: reports,
    [DATASOURCES.PATIENTS]: patients,
    [DATASOURCES.PHYSICIANS]: physicians,
    [DATASOURCES.BILLING_CODES]: billingCodes,
  } = getState().app.dataSources;

  const selectedIds = getSelectedList(selectedReports);
  let reportIds = [];
  let payload = selectedIds.map((id) => {
    const report = reports.data.byId[id];

    return {
      exported: report.exportedAt !== null,
      esigned: report.esignedAt !== null,
      comments: report.comments ? report.comments : null,
      esignMemberId: report.esignMemberId ? report.esignMemberId : null,
      id,
    };
  });

  const meDataSource = getDataSource(getState(), DATASOURCES.ME);

  let successMessage;
  let promises;

  // ensure we have team id
  if (meDataSource.data) {
    promises = Promise.resolve();
  } else {
    promises = dispatch(getMe());
  }

  switch (bulkActionType) {
    case TABLE_BULK_ACTIONS.MARK_AS_EXPORT:
      payload = payload.map((rep) => ({
        ...rep,
        // exported: false,
        // esigned: false,
        exported: true,
      }));

      reportIds = payload
        .filter((report) => report.exported === true)
        .reduce((acc, report) => {
          acc.push(report.id);
          return acc;
        }, []);

      successMessage =
        reportIds.length === 1
          ? `${reportIds.length} report has been exported. The file will be sent to your email once ready.`
          : `${reportIds.length} reports have been exported. The files will be sent to your email once ready.`;

      promises = promises.then(() => {
        const me = getDataSource(getState(), DATASOURCES.ME);

        const { teams } = me.data;

        return Promise.all([
          dispatch(putUpdateReports(payload)),
          dispatch(postReportsEmailExport(teams[0].id, reportIds)),
        ]);
      });
      break;
    case TABLE_BULK_ACTIONS.DOWNLOAD_LIST:
      successMessage = 'Billing report CSV has been downloaded.';
      // promises = promises
      //   .then(() => downloadBillingList(selectedIds, reports, patients, physicians, billingCodes))
      break;
    default:
      break;
  }

  dispatch(updateBulkActionIsFetching(true));
  return (
    promises
      .then(() => {
        window.setTimeout(() => {
          dispatch(handleOnSelectionClick({}, updateSelectedReports));
        }, 3000);
        dispatch(updateBulkActionIsFetching(false));
        dispatch(updateBulkActionSuccessMessage(successMessage));
        return dispatch(updateBulkActionComplete(true));
      })
      // .then(() => dispatch(updateSelectedReports({})))
      .then(() => dispatch(dataSuccess()))
      .catch((err) => dispatch(dataFailure()))
  );
};

let previousEnrollmentIdsFiltersAndSorts;
let cacheEnrollmentIds = [];
export const getEnrollmentIdsFromDataSource = (state) => {
  const {
    [DATASOURCES.PATIENTS]: patients,
    [DATASOURCES.PATIENT]: patient,
    [DATASOURCES.ENROLLMENTS]: enrollments,
    [DATASOURCES.ICD10_CODES]: icd10Codes,
    [DATASOURCES.BILLING_CODES]: billingCodes,
  } = state.app.dataSources;
  const {
    selectedPatientId,
    selectedEnrollmentStartStartDate,
    selectedEnrollmentStartEndDate,
    selectedEnrollmentEndStartDate,
    selectedEnrollmentEndEndDate,
    selectedEnrollmentPrescriptionId,
    selectedEnrollmentIcd10CodeId,
    selectedEnrollmentBillingCode,
    selectedEnrollmentOrderNumber,
    enrollmentSortBy,
    enrollmentSortDirection,
    dataIsFetching,
  } = state.patientFile;

  const filtersAndSorts = {
    patientIsFetching: patient && patient.isFetching,
    dataIsFetching,
    selectedPatientId,
    selectedStartStartDate: selectedEnrollmentStartStartDate,
    selectedStartEndDate: selectedEnrollmentStartEndDate,
    selectedEndStartDate: selectedEnrollmentEndStartDate,
    selectedEndEndDate: selectedEnrollmentEndEndDate,
    selectedPrescriptionId: selectedEnrollmentPrescriptionId,
    selectedIcd10CodeId: selectedEnrollmentIcd10CodeId,
    selectedBillingCode: selectedEnrollmentBillingCode,
    selectedOrderNumber: selectedEnrollmentOrderNumber,
    sortBy: enrollmentSortBy,
    sortDirection: enrollmentSortDirection,
  };

  let enrollmentIds = [];
  let enrollmentByIds = {};
  let icd10CodeByIds = {};
  let billingCodeByIds = {};

  if (
    patient &&
    patient.data &&
    patient.data.id === selectedPatientId &&
    patients &&
    patients.data &&
    patients.data.byId[selectedPatientId] &&
    enrollments &&
    enrollments.data &&
    icd10Codes &&
    icd10Codes.data &&
    billingCodes &&
    billingCodes.data
  ) {
    enrollmentIds = patients.data.byId[selectedPatientId].enrollments;
    enrollmentByIds = enrollments.data.byId;
    icd10CodeByIds = icd10Codes.data.byId;
    billingCodeByIds = billingCodes.data.byId;

    // if (!isEqual(previousEnrollmentIdsFiltersAndSorts, filtersAndSorts)) {
    //   enrollmentIds = filterEnrollmentIds(filtersAndSorts, enrollmentIds, enrollmentByIds, icd10CodeByIds, billingCodeByIds)
    //   enrollmentIds = sortEnrollmentIds(filtersAndSorts, enrollmentIds, enrollmentByIds, icd10CodeByIds, billingCodeByIds)

    //   cacheEnrollmentIds = enrollmentIds
    // }

    previousEnrollmentIdsFiltersAndSorts = filtersAndSorts;
  }

  return cacheEnrollmentIds;
};

export const handleOnEnrollmentSortClick = (columnHeader) => (dispatch, getState) => {
  const { enrollmentSortBy, enrollmentSortDirection } = getState().patientFile;

  if (enrollmentSortBy === columnHeader) {
    const nextSortDirection = getNextSortDirection(enrollmentSortDirection);
    dispatch(updateEnrollmentSorts(columnHeader, nextSortDirection));
  } else {
    const nextSortDirection = getNextSortDirection();
    dispatch(updateEnrollmentSorts(columnHeader, nextSortDirection));
  }
};

export const handleOnEnrollmentFilterChange = (columnHeader, data) => (dispatch, getState) => {
  switch (columnHeader) {
    case ENROLLMENT_TABLE_COLUMN_HEADERS.START_DATE:
      dispatch(updateSelectedEnrollmentStartDates(data));
      break;
    case ENROLLMENT_TABLE_COLUMN_HEADERS.END_DATE:
      dispatch(updateSelectedEnrollmentEndDates(data));
      break;
    case ENROLLMENT_TABLE_COLUMN_HEADERS.PRESCRIPTION:
      dispatch(updateSelectedEnrollmentPrescriptionId(data));
      dispatch(updateSelectedPopover(null));
      break;
    case ENROLLMENT_TABLE_COLUMN_HEADERS.ICD10_CODE:
      dispatch(updateSelectedEnrollmentIcd10CodeId(data));
      dispatch(updateSelectedPopover(null));
      break;
    case ENROLLMENT_TABLE_COLUMN_HEADERS.BILLING_CODE:
      dispatch(updateSelectedEnrollmentBillingCode(data));
      break;
    case ENROLLMENT_TABLE_COLUMN_HEADERS.ORDER_NUMBER:
      dispatch(updateSelectedEnrollmentOrderNumber(data));
      break;
    default:
      break;
  }
};

let previousBloodPressureIdsFiltersAndSorts;
let cacheBloodPressureIds = [];
export const getBloodPressureRecordingIdsFromDataSource = (state) => {
  const {
    [DATASOURCES.PATIENTS]: patients,
    [DATASOURCES.PATIENT]: patient,
    [DATASOURCES.BLOOD_PRESSURES]: bps,
  } = state.app.dataSources;
  const {
    selectedPatientId,
    selectedBloodPressureSource,
    selectedBloodPressureSeverity,
    selectedBloodPressureStartDate,
    selectedBloodPressureEndDate,
    selectedBloodPressureBpmStartValue,
    selectedBloodPressureBpmEndValue,
    selectedBloodPressureSystolicStartValue,
    selectedBloodPressureSystolicEndValue,
    selectedBloodPressureDiastolicStartValue,
    selectedBloodPressureDiastolicEndValue,
    bloodPressureSortBy,
    bloodPressureSortDirection,
    dataIsFetching,
  } = state.patientFile;

  const filtersAndSorts = {
    patientIsFetching: patient && patient.isFetching,
    dataIsFetching,
    selectedPatientId,
    selectedSource: selectedBloodPressureSource,
    selectedSeverity: selectedBloodPressureSeverity,
    selectedStartDate: selectedBloodPressureStartDate,
    selectedEndDate: selectedBloodPressureEndDate,
    selectedBpmStartValue: selectedBloodPressureBpmStartValue,
    selectedBpmEndValue: selectedBloodPressureBpmEndValue,
    selectedSystolicStartValue: selectedBloodPressureSystolicStartValue,
    selectedSystolicEndValue: selectedBloodPressureSystolicEndValue,
    selectedDiastolicStartValue: selectedBloodPressureDiastolicStartValue,
    selectedDiastolicEndValue: selectedBloodPressureDiastolicEndValue,
    sortBy: bloodPressureSortBy,
    sortDirection: bloodPressureSortDirection,
  };

  let recordingIds = [];
  let recordingByIds = {};

  if (
    patient &&
    patient.data &&
    patient.data.id === selectedPatientId &&
    patients &&
    patients.data &&
    patients.data.byId[selectedPatientId] &&
    bps &&
    bps.data
  ) {
    recordingIds = patients.data.byId[selectedPatientId].bloodPressure;
    recordingByIds = bps.data.byId;

    if (!isEqual(previousBloodPressureIdsFiltersAndSorts, filtersAndSorts)) {
      recordingIds = filterBloodPressureRecordingIds(filtersAndSorts, recordingIds, recordingByIds);
      recordingIds = sortBloodPressureRecordingIds(filtersAndSorts, recordingIds, recordingByIds);

      cacheBloodPressureIds = recordingIds;
    }
    previousBloodPressureIdsFiltersAndSorts = filtersAndSorts;
  }
  // console.log('cacheBloodPressureIds = ', cacheBloodPressureIds)
  return cacheBloodPressureIds;
};

export const handleOnBloodPressureSortClick = (columnHeader) => (dispatch, getState) => {
  const { bloodPressureSortBy, bloodPressureSortDirection } = getState().patientFile;

  if (bloodPressureSortBy === columnHeader) {
    const nextSortDirection = getNextSortDirection(bloodPressureSortDirection);
    dispatch(updateBloodPressureSorts(columnHeader, nextSortDirection));
  } else {
    const nextSortDirection = getNextSortDirection();
    dispatch(updateBloodPressureSorts(columnHeader, nextSortDirection));
  }
};

export const handleOnBloodPressureFilterChange = (columnHeader, data) => (dispatch, getState) => {
  const { selectedBloodPressureSource, selectedBloodPressureSeverity } = getState().patientFile;
  switch (columnHeader) {
    case BLOOD_PRESSURE_TABLE_COLUMN_HEADERS.RECORDED_AT:
      dispatch(updateSelectedBloodPressureDates(data));
      break;
    case BLOOD_PRESSURE_TABLE_COLUMN_HEADERS.SOURCE:
      if (selectedBloodPressureSource === data) {
        dispatch(updateSelectedBloodPressureSource(filters.selectedBloodPressureSource));
      } else {
        dispatch(updateSelectedBloodPressureSource(data));
      }
      break;
    case BLOOD_PRESSURE_TABLE_COLUMN_HEADERS.CATEGORY:
      if (selectedBloodPressureSeverity === data) {
        dispatch(updateSelectedBloodPressureSeverity(filters.selectedBloodPressureSeverity));
      } else {
        dispatch(updateSelectedBloodPressureSeverity(data));
      }
      break;
    case BLOOD_PRESSURE_TABLE_COLUMN_HEADERS.BPM:
      dispatch(
        updateSelectedBloodPressureBpmValues({
          startValue: data[0],
          endValue: data[1],
        }),
      );
      break;
    case BLOOD_PRESSURE_TABLE_COLUMN_HEADERS.SYSTOLIC:
      dispatch(
        updateSelectedBloodPressureSystolicValues({
          startValue: data[0],
          endValue: data[1],
        }),
      );
      break;
    case BLOOD_PRESSURE_TABLE_COLUMN_HEADERS.DIASTOLIC:
      dispatch(
        updateSelectedBloodPressureDiastolicValues({
          startValue: data[0],
          endValue: data[1],
        }),
      );
      break;
    default:
      break;
  }
};

let previousEkgsIdsFiltersAndSorts;
let cacheEkgsIds = [];
export const getEkgRecordingIdsFromDataSource = (state) => {
  const {
    [DATASOURCES.PATIENTS]: patients,
    [DATASOURCES.PATIENT]: patient,
    [DATASOURCES.EKGS]: ekgs,
  } = state.app.dataSources;
  const {
    selectedPatientId,
    selectedEkgPhysicianId,
    selectedEkgPhysicians,
    selectedEkgResult,
    selectedEkgStartDate,
    selectedEkgEndDate,
    selectedEkgBpmStartValue,
    selectedEkgBpmEndValue,
    ekgSortBy,
    ekgSortDirection,
    dataIsFetching,
  } = state.patientFile;

  const patientId = selectedPatientId
  // using patientId from url instead of selected selectedPatientId from state
  // needed for having ekg tab default
  // order of calls are wrong and timeline graph wasn't updating correctly
  const filtersAndSorts = {
    patientIsFetching: patient && patient.isFetching,
    dataIsFetching,
    patientId,
    selectedPhysicianId: selectedEkgPhysicianId,
    selectedPhysicians: selectedEkgPhysicians,
    selectedEkgResult,
    selectedStartDate: selectedEkgStartDate,
    selectedEndDate: selectedEkgEndDate,
    selectedBpmStartValue: selectedEkgBpmStartValue,
    selectedBpmEndValue: selectedEkgBpmEndValue,
    sortBy: ekgSortBy,
    sortDirection: ekgSortDirection,
  };

  let recordingIds = [];
  let recordingByIds = {};

  if (
    patient &&
    patient.data &&
    patient.data.id === patientId &&
    patients &&
    patients.data &&
    patients.data.byId[patientId] &&
    ekgs &&
    ekgs.data
  ) {
    recordingIds = patients.data.byId[patientId].recordings;
    recordingByIds = ekgs.data.byId;

    if (!isEqual(previousEkgsIdsFiltersAndSorts, filtersAndSorts)) {
      recordingIds = filterEkgRecordingIds(filtersAndSorts, recordingIds, recordingByIds);
      recordingIds = sortEkgRecordingIds(filtersAndSorts, recordingIds, recordingByIds);

      cacheEkgsIds = recordingIds;
    }
    previousEkgsIdsFiltersAndSorts = filtersAndSorts;
  } else {
    // clearing cached ekg ids if patient is incorrect
    // prevents old data to be passed to graph components
    cacheEkgsIds = [];
  }

  return cacheEkgsIds;
};

export const handleOnEkgSortClick = (columnHeader) => (dispatch, getState) => {
  const { ekgSortBy, ekgSortDirection } = getState().patientFile;

  if (ekgSortBy === columnHeader) {
    const nextSortDirection = getNextSortDirection(ekgSortDirection);
    dispatch(updateEkgSorts(columnHeader, nextSortDirection));
  } else {
    const nextSortDirection = getNextSortDirection();
    dispatch(updateEkgSorts(columnHeader, nextSortDirection));
  }
};

export const handleOnFilterButtonClick = (columnHeader) => (dispatch, getState) => {
  const { selectedPopover } = getState().patientFile;

  if (selectedPopover === columnHeader) {
    dispatch(updateSelectedPopover(null));
  } else {
    dispatch(updateSelectedPopover(columnHeader));
  }
};

export const handleOnEkgFilterChange = (columnHeader, data) => (dispatch, getState) => {
  switch (columnHeader) {
    case EKG_TABLE_COLUMN_HEADERS.RECORDED_AT:
      dispatch(updateSelectedEkgDates(data));
      break;
    case EKG_TABLE_COLUMN_HEADERS.ANALYSIS:
      dispatch(updateSelectedEkgResult(data));
      break;
    case EKG_TABLE_COLUMN_HEADERS.ANALYSIS_BY:
      dispatch(updateSelectedEkgPhysicianId(data));
      dispatch(updateSelectedPopover(null));
      break;
    case EKG_TABLE_COLUMN_HEADERS.ORDERING_PHYSICIAN:
      dispatch(updateSelectedEkgPhysicians(data));
      break;
    case EKG_TABLE_COLUMN_HEADERS.BPM:
      dispatch(
        updateSelectedEkgBpmValues({
          startValue: data[0],
          endValue: data[1],
        }),
      );
      break;
    default:
      break;
  }
};

let previousWeightsIdsFiltersAndSorts;
let cacheWeightsIds = [];
export const getWeightRecordingIdsFromDataSource = (state) => {
  const {
    [DATASOURCES.PATIENTS]: patients,
    [DATASOURCES.PATIENT]: patient,
    [DATASOURCES.WEIGHTS]: weights,
    [DATASOURCES.ME]: me,
  } = state.app.dataSources;
  const {
    selectedPatientId,
    selectedWeightSource,
    selectedWeightSeverity,
    selectedWeightStartDate,
    selectedWeightEndDate,
    selectedWeightBmiStartValue,
    selectedWeightBmiEndValue,
    selectedWeightWeightStartValue,
    selectedWeightWeightEndValue,
    weightSortBy,
    weightSortDirection,
    dataIsFetching,
  } = state.patientFile;

  const filtersAndSorts = {
    patientIsFetching: patient && patient.isFetching,
    dataIsFetching,
    selectedPatientId,
    selectedSource: selectedWeightSource,
    selectedSeverity: selectedWeightSeverity,
    selectedStartDate: selectedWeightStartDate,
    selectedEndDate: selectedWeightEndDate,
    selectedBmiStartValue: selectedWeightBmiStartValue,
    selectedBmiEndValue: selectedWeightBmiEndValue,
    selectedWeightStartValue: selectedWeightWeightStartValue,
    selectedWeightEndValue: selectedWeightWeightEndValue,
    sortBy: weightSortBy,
    sortDirection: weightSortDirection,
  };

  let recordingIds = [];
  let recordingByIds = {};

  if (
    patient &&
    patient.data &&
    patient.data.id === selectedPatientId &&
    patients &&
    patients.data &&
    patients.data.byId[selectedPatientId] &&
    weights &&
    weights.data
  ) {
    recordingIds = patients.data.byId[selectedPatientId].weight;
    recordingByIds = weights.data.byId;

    if (!isEqual(previousWeightsIdsFiltersAndSorts, filtersAndSorts)) {
      recordingIds = filterWeightRecordingIds(filtersAndSorts, recordingIds, recordingByIds, me);
      recordingIds = sortWeightRecordingIds(filtersAndSorts, recordingIds, recordingByIds);

      cacheWeightsIds = recordingIds;
    }

    previousWeightsIdsFiltersAndSorts = filtersAndSorts;
  }

  return cacheWeightsIds;
};

export const handleOnWeightSortClick = (columnHeader) => (dispatch, getState) => {
  const { weightSortBy, weightSortDirection } = getState().patientFile;

  if (weightSortBy === columnHeader) {
    const nextSortDirection = getNextSortDirection(weightSortDirection);
    dispatch(updateWeightSorts(columnHeader, nextSortDirection));
  } else {
    const nextSortDirection = getNextSortDirection();
    dispatch(updateWeightSorts(columnHeader, nextSortDirection));
  }
};

export const handleOnWeightFilterChange = (columnHeader, data) => (dispatch, getState) => {
  const { selectedWeightSource, selectedWeightSeverity } = getState().patientFile;
  switch (columnHeader) {
    case WEIGHT_TABLE_COLUMN_HEADERS.RECORDED_AT:
      dispatch(updateSelectedWeightDates(data));
      break;
    case WEIGHT_TABLE_COLUMN_HEADERS.SOURCE:
      if (selectedWeightSource === data) {
        dispatch(updateSelectedWeightSource(filters.selectedWeightSource));
      } else {
        dispatch(updateSelectedWeightSource(data));
      }
      break;
    case WEIGHT_TABLE_COLUMN_HEADERS.CATEGORY:
      if (selectedWeightSeverity === data) {
        dispatch(updateSelectedWeightSeverity(filters.selectedWeightSeverity));
      } else {
        dispatch(updateSelectedWeightSeverity(data));
      }
      break;
    case WEIGHT_TABLE_COLUMN_HEADERS.BMI:
      dispatch(
        updateSelectedWeightBmiValues({
          startValue: data[0],
          endValue: data[1],
        }),
      );
      break;
    case WEIGHT_TABLE_COLUMN_HEADERS.WEIGHT:
      dispatch(
        updateSelectedWeightWeightValues({
          startValue: data[0],
          endValue: data[1],
        }),
      );
      break;
    default:
      break;
  }
};

// export const handleOnEkgTableScroll = (index, recording) => (dispatch, getState) => {
//   // dispatch(updateSelectedIndex(index))
//   if (recording) {
//     dispatch(updateSelectedEkgRecordingId(recording.id))
//   }
// }

export const handleOnEkgTableScroll = () => (dispatch, getState) => {
  const { selectedEkgRecordingId } = getState().patientFile;
  if (selectedEkgRecordingId) {
    dispatch(updateSelectedEkgRecordingId(null));
    dispatch(updateSelectedIndex(-1));
  }
};

export const handleOnBloodPressureTableScroll = () => (dispatch, getState) => {
  const { selectedBloodPressureRecordingId } = getState().patientFile;
  if (selectedBloodPressureRecordingId) {
    dispatch(updateSelectedBloodPressureRecordingId(null));
    dispatch(updateSelectedIndex(-1));
  }
};

export const handleOnWeightTableScroll = () => (dispatch, getState) => {
  const { selectedWeightRecordingId } = getState().patientFile;
  if (selectedWeightRecordingId) {
    dispatch(updateSelectedWeightRecordingId(null));
    dispatch(updateSelectedIndex(-1));
  }
};

export const handleOnEkgRowClick =
  ({ index, rowData }) =>
  (dispatch, getState) => {
    history.push(
      `${ROUTES.PATIENTS_PATIENT_FILE}/${rowData.participantID}/recordings/ekg/${rowData.id}`,
    );
    dispatch(updateSelectedIndex(index));
    dispatch(updateSelectedEkgRecordingId(rowData.id));
    dispatch(handleOnSelectionClick({}, updateSelectedEkgs));
  };

export const handleOnReportRowClick =
  ({ index, rowData }) =>
  (dispatch, getState) => {
    const { selectedPatientId } = getState().patientFile;

    dispatch(handleOnSingleReportNavClick(selectedPatientId, rowData.id));
    dispatch(updateSelectedIndex(index));
    dispatch(handleOnSelectionClick({}, updateSelectedReports));
  };

export const handleOnEkgBulkAction = (bulkActionType, data) => (dispatch, getState) => {
  dispatch(dataRequest());

  const { selectedEkgs } = getState().patientFile;

  const selectedIds = getSelectedList(selectedEkgs);

  const meDataSource = getDataSource(getState(), DATASOURCES.ME);

  let successMessage;
  let promises;

  // ensure we have team id
  if (meDataSource.data) {
    promises = Promise.resolve();
  } else {
    promises = dispatch(getMe());
  }

  switch (bulkActionType) {
    // commenting out as this is not required in metrics tab

    // case TABLE_BULK_ACTIONS.MARK_AS_EXPORT:
    //   promises = promises.then(() => {
    //     const me = getDataSource(getState(), DATASOURCES.ME);

    //     const { teams } = me.data;

    //     successMessage =
    //       selectedIds.length === 1
    //         ? `${selectedIds.length} ECG has been exported. The file will be sent to your email once ready.`
    //         : `${selectedIds.length} ECGs have been exported. The files will be sent to your email once ready.`;

    //     return dispatch(postEkgsEmailExport(teams[0].id, selectedIds));
    //   });
    //   break;
    default:
      break;
  }

  dispatch(updateBulkActionIsFetching(true));
  return (
    promises
      .then(() => {
        window.setTimeout(() => {
          dispatch(handleOnSelectionClick({}, updateSelectedEkgs));
        }, 3000);
        dispatch(updateBulkActionIsFetching(false));
        dispatch(updateBulkActionSuccessMessage(successMessage));
        return dispatch(updateBulkActionComplete(true));
      })
      // .then(() => dispatch(updateSelectedEkgs({})))
      .then(() => dispatch(dataSuccess()))
      .catch((err) => dispatch(dataFailure()))
  );
};

export const setSelectedEkgRecording = (listOfIds, recording) => (dispatch, getState) => {
  let { selectedIndex } = getState().patientFile;
  // console.log('recording - ', recording)
  if (recording) {
    dispatch(updateSelectedEkgRecordingId(recording.id));
    selectedIndex = listOfIds.indexOf(recording.id);
    dispatch(updateSelectedIndex(selectedIndex));
  }
};

export const clearSelectedEkgRecording = () => (dispatch, getState) => {
  dispatch(updateSelectedEkgRecordingId(null));
  dispatch(updateSelectedIndex(0));
};

export const setSelectedBloodPressureRecording = (listOfIds, recording) => (dispatch, getState) => {
  let { selectedIndex } = getState().patientFile;

  if (recording) {
    dispatch(updateSelectedBloodPressureRecordingId(recording.id));
    selectedIndex = listOfIds.indexOf(recording.id);
    dispatch(updateSelectedIndex(selectedIndex));
  }
};

export const clearSelectedBloodPressureRecording = () => (dispatch, getState) => {
  dispatch(updateSelectedBloodPressureRecordingId(null));
  dispatch(updateSelectedIndex(0));
};

export const setSelectedWeightRecording = (listOfIds, recording) => (dispatch, getState) => {
  let { selectedIndex } = getState().patientFile;

  if (recording) {
    dispatch(updateSelectedWeightRecordingId(recording.id));
    selectedIndex = listOfIds.indexOf(recording.id);
    dispatch(updateSelectedIndex(selectedIndex));
  }
};

export const clearSelectedWeightRecording = () => (dispatch, getState) => {
  dispatch(updateSelectedWeightRecordingId(null));
  dispatch(updateSelectedIndex(0));
};

export const handleOnTabClick = (tab) => (dispatch, getState) => {
  const { selectedPatientId } = getState().patientFile;

  dispatch(resetFilters());
  dispatch(resetSorts());
  dispatch(updateBulkActionIsFetching(false));
  dispatch(updateBulkActionComplete(false));
  dispatch(updateBulkActionSuccessMessage(null));
  dispatch(updateSelectedTab(tab));
  dispatch(updateSelectedIndex(0));
  dispatch(updateSelectedEkgRecordingId(null));
  dispatch(updateSelectedBloodPressureRecordingId(null));
  dispatch(updateSelectedWeightRecordingId(null));
  dispatch(updateSelectedPopover(initialState.selectedPopover));

  // dispatch(dataRequest())
  // const meDataSource = getDataSource(getState(), DATASOURCES.ME)
  // const interpretationsDataSource = getDataSource(getState(), DATASOURCES.INTERPRETATIONS)
  // let promises = Promise.resolve()

  // if (meDataSource.data) {
  //   promises = Promise.resolve()
  // } else {
  //   promises = dispatch(getMe())
  // }

  // switch (tab) {
  // case PATIENT_FILE_TABS.OVERVIEW:
  // case PATIENT_FILE_TABS.PROFILE:
  //   promises = promises
  //     .then(() => dispatch(getPatientTriggers(selectedPatientId)))
  //     .then(() => {
  //       const me = getDataSource(getState(), DATASOURCES.ME)
  //       const {
  //         teams,
  //       } = me.data

  //       return Promise.all([
  //         dispatch(getPhysiciansAndEkgStackCount(teams[0].id)),
  //         dispatch(getPrescriptions(teams[0].id)),
  //         dispatch(getTeamTriggers(teams[0].id)),
  //       ])
  //     })
  //   break
  // case PATIENT_FILE_TABS.REPORTS:
  //   promises = promises
  //     .then(() => {
  //       const me = getDataSource(getState(), DATASOURCES.ME)

  //       const {
  //         teams,
  //       } = me.data

  //       return dispatch(getPatientReports(selectedPatientId))
  //     })
  //   break
  // case PATIENT_FILE_TABS.EKGS:
  // case PATIENT_FILE_TABS.BLOOD_PRESSURES:
  //   if (interpretationsDataSource.data) {
  //     // no-op
  //   } else {
  //     promises = promises
  //       .then(() => {
  //         const me = getDataSource(getState(), DATASOURCES.ME)
  //         const {
  //           teams,
  //         } = me.data

  //         return dispatch(getInterpretations(teams[0].id))
  //       })
  //   }

  //   promises = promises
  //     .then(() => {
  //       const me = getDataSource(getState(), DATASOURCES.ME)

  //       const {
  //         teams,
  //       } = me.data

  //       return dispatch(getPatient(selectedPatientId))
  //     })
  //   break
  // default:
  //   break
  // }

  // return promises
  //   .then(() => dispatch(dataSuccess()))
  //   .catch(err => {
  //     console.log('handleOnTabClick', err)
  //     dispatch(dataFailure())
  //   })
};

export const handleOnPatientClick =
  (
    resetData = true,
    handleRouting = false,
    data = {
      patientId: undefined,
      referral: null,
    },
    selectedTab = undefined,
  ) =>
  (dispatch, getState) => {
    const { patientId, referral } = data;

    let patientFileRoute = `${ROUTES.PATIENTS_PATIENT_FILE}/${patientId}`;
    if (referral) {
      patientFileRoute = `${patientFileRoute}?referral=${referral}`;
    }

    let promises = Promise.resolve();
    if (handleRouting) {
      promises = promises.then(() => history.push(patientFileRoute));
    }
    let tab = initialState.selectedTab;
    if (selectedTab) {
      tab = selectedTab;
    }

    if (resetData) {
      promises = promises.then(() => {
        dispatch(resetFilters());
        dispatch(resetSorts());
        dispatch(updateBulkActionIsFetching(false));
        dispatch(updateBulkActionComplete(false));
        dispatch(updateBulkActionSuccessMessage(null));
        dispatch(updateSelectedTab(tab));
        dispatch(updateSelectedIndex(0));
        dispatch(updateSelectedEkgRecordingId(null));
        dispatch(updateSelectedBloodPressureRecordingId(null));
        dispatch(updateSelectedWeightRecordingId(null));
        dispatch(updateSelectedPopover(initialState.selectedPopover));
      });
    }

    return promises;
  };

export const handleOnPatientBackClick =
  (
    resetData = true,
    handleRouting = false,
    data = {
      patientId: undefined,
    },
  ) =>
  (dispatch, getState) => {
    const { patientId } = data;
    let promises = Promise.resolve();
    if (handleRouting) {
      promises = promises.then(() => history.push(`${ROUTES.PATIENTS_PATIENT_FILE}/${patientId}`));
    }

    if (resetData) {
      promises = promises.then(() => dispatch(updateSelectedPopover(initialState.selectedPopover)));
    }

    return promises;
  };

export const toggleCustomReportPopover = () => (dispatch, getState) => {
  const { customReportPopoverOpen } = getState().patientFile;

  dispatch(updateCustomReportPopoverState(!customReportPopoverOpen));
  dispatch(resetCustomReportDateRange());
};

export const updateCustomReportRequest = (range) => (dispatch, getState) => {
  dispatch(setCustomReportDateRange(range));
};

export const fetchCustomReport = () => (dispatch, getState) => {
  const { startDate, endDate } = getState().patientFile.customReportDateRange;
  const { selectedPatientId } = getState().patientFile;
  const endTime = moment(endDate).endOf('day');
  const startTime = moment(startDate).startOf('day');
  dispatch(toggleCustomReportPopover());
  const endTimeFmt = endTime.format(DATETIME_API_FMT);
  const startTimeFmt = startTime.format(DATETIME_API_FMT);
  const fileStartTime = startTime.format(getDateFormat());
  const fileEndTime = endTime.format(getDateFormat());

  dispatch(dataRequest());
  const meDataSource = getDataSource(getState(), DATASOURCES.ME);
  const patientsDataSource = getDataSource(getState(), DATASOURCES.PATIENTS);

  let promises;

  if (meDataSource.data) {
    promises = Promise.resolve();
  } else {
    promises = dispatch(getMe());
  }

  if (patientsDataSource.data && patientsDataSource.data.byId[selectedPatientId]) {
    // no-op
  } else {
    promises = promises.then(() => dispatch(getPatient(selectedPatientId)));
  }

  return promises
    .then(() => dispatch(getCustomReport(selectedPatientId, endTimeFmt, startTimeFmt)))
    .then(() => {
      const patients = getDataSource(getState(), DATASOURCES.PATIENTS);
      const patient = patients.data.byId[selectedPatientId];
      const { customParticipantID: MRN } = patient;
      const pdf = getDataSource(getState(), DATASOURCES.CUSTOM_REPORT_PDF);
      // return download(
      //   pdf.data,
      //   `${MRN}_custom_report_${fileStartTime}_${fileEndTime}.pdf`,
      //   'application/pdf',
      // );
    })
    .then((downloaded) => {
      dispatch(dataSuccess());
      return downloaded;
    })
    .catch((err) => {
      console.log('fetchCustomReport', err);
      dispatch(dataFailure());
    });
};

export const updateStatusEosReport = (data) => ({
  type: EOS_SUMMARY_REPORT_STATUS,
  payload: data,
});

export const getEosSumaryReportStatus = (idtfConnections) => (dispatch, getState) => {
  // dispatch(dataRequest())
  const meDataSource = getDataSource(getState(), DATASOURCES.ME);
  const { selectedPatientId } = getState().patientFile;

  let promises;
  if (meDataSource.data) {
    promises = Promise.resolve();
  } else {
    promises = dispatch(getMe());
  }

  return (
    promises
      .then(() => {
        const apiCalls = [];

        const me = getDataSource(getState(), DATASOURCES.ME);

        const { teams } = me.data;

        // apiCalls.push(dispatch(getEosStatus(idtfConnections)))
        return Promise.all(apiCalls);
        // return Promise.resolve('Success')
      })
      .then((res) => {
        if (idtfConnections) {
          return dispatch(updateStatusEosReport(res[0]));
        } else {
          return dispatch(updateStatusEosReport(null));
        }
      })
      // .then(() => dispatch(dataSuccess()))
      .catch((err) => {
        // dispatch(updateStatusEosReport(err))
        dispatch(updateStatusEosReport(null));
        console.log('getEosSumaryReportStatus', err);
        // dispatch(dataFailure())
      })
  );
};

export const getEosReportDownload = (idtfConnections) => (dispatch, getState) => {
  console.log('getEosReportDownload', idtfConnections);
  // dispatch(dataRequest())
  const meDataSource = getDataSource(getState(), DATASOURCES.ME);
  const { selectedPatientId } = getState().patientFile;

  let promises;
  if (meDataSource.data) {
    promises = Promise.resolve();
  } else {
    promises = dispatch(getMe());
  }

  return (
    promises
      .then(() => {
        const apiCalls = [];

        const me = getDataSource(getState(), DATASOURCES.ME);

        const { teams } = me.data;

        // apiCalls.push(dispatch(getFetchEosReport(idtfConnections)))
        return Promise.all(apiCalls);
        // return Promise.resolve('Success')
      })
      .then((res) => {
        // eslint-disable-next-line no-empty
        if (res[0].errors) {
        } else {
          const blob = new Blob([res[0]], { type: 'application/pdf' });
          return download(blob, `${idtfConnections}_eos_report_.pdf`, 'application/pdf');
        }
        // dispatch(updateStatusEosReport(res[0]))
      })
      // .then(() => dispatch(dataSuccess()))
      .catch((err) => {
        // dispatch(updateStatusEosReport(err))
        console.log('getEosSumaryReportStatus', err);
        dispatch(dataFailure());
      })
  );
};

const today = KARDIA_PRO_END_DATE;
const filters = Object.assign(
  {},
  {
    selectedEnrollmentStartStartDate: KARDIA_PRO_START_DATE,
    selectedEnrollmentStartEndDate: today,
    selectedEnrollmentEndStartDate: KARDIA_PRO_START_DATE,
    selectedEnrollmentEndEndDate: today,
    selectedEnrollmentPrescriptionId: ALL_PRESCRIPTIONS_OPTION.id,
    selectedEnrollmentIcd10CodeId: null,
    selectedEnrollmentBillingCode: [],
    selectedEnrollmentOrderNumber: '',

    selectedEkgRecordingId: null,
    selectedEkgResult: [],
    selectedEkgStartDate: KARDIA_PRO_START_DATE,
    selectedEkgEndDate: today,
    selectedEkgPhysicianId: ALL_PHYSICIANS_OPTION.id,
    selectedEkgPhysicians: [],
    selectedEkgBpmStartValue: MIN_BPM_VALUE,
    selectedEkgBpmEndValue: MAX_BPM_VALUE,
    selectedEkgs: {},

    selectedBloodPressureRecordingId: null,
    selectedBloodPressureSource: [],
    selectedBloodPressureSeverity: [],
    selectedBloodPressureStartDate: KARDIA_PRO_START_DATE,
    selectedBloodPressureEndDate: today,
    selectedBloodPressureBpmStartValue: MIN_BPM_VALUE,
    selectedBloodPressureBpmEndValue: MAX_BPM_VALUE,
    selectedBloodPressureSystolicStartValue: MIN_SYSTOLIC_VALUE,
    selectedBloodPressureSystolicEndValue: MAX_SYSTOLIC_VALUE,
    selectedBloodPressureDiastolicStartValue: MIN_DIASTOLIC_VALUE,
    selectedBloodPressureDiastolicEndValue: MAX_DIASTOLIC_VALUE,
    selectedBloodPressures: {},

    selectedReportStartDate: KARDIA_PRO_START_DATE,
    selectedReportEndDate: today,
    selectedReportPhysicianId: ALL_PHYSICIANS_OPTION.id,
    selectedReportPrescriptionId: ALL_PRESCRIPTIONS_OPTION.id,
    selectedReportBillingCode: [],
    selectedReportEsignedId: getEsignOptions()[0].id,
    selectedReportExportedId: getExportOptions()[0].id,
    selectedReports: {},
    selectedReportPhysicians: [],

    selectedWeightRecordingId: null,
    selectedWeightStartDate: KARDIA_PRO_START_DATE,
    selectedWeightEndDate: today,
    selectedWeightSource: [],
    selectedWeightSeverity: [],
    selectedWeightBmiStartValue: MIN_BMI_VALUE,
    selectedWeightBmiEndValue: MAX_BMI_VALUE,
    selectedWeightWeightStartValue: MIN_WEIGHT_VALUE,
    selectedWeightWeightEndValue: MAX_WEIGHT_VALUE,
    selectedWeights: {},
  },
);

const sorts = Object.assign(
  {},
  {
    reportSortBy: REPORTS_TABLE_COLUMN_HEADERS.END_DATE,
    reportSortDirection: SORT_TYPES.DESC,

    enrollmentSortBy: ENROLLMENT_TABLE_COLUMN_HEADERS.START_DATE,
    enrollmentSortDirection: SORT_TYPES.DESC,

    ekgSortBy: EKG_TABLE_COLUMN_HEADERS.RECORDED_AT,
    ekgSortDirection: SORT_TYPES.DESC,

    bloodPressureSortBy: BLOOD_PRESSURE_TABLE_COLUMN_HEADERS.RECORDED_AT,
    bloodPressureSortDirection: SORT_TYPES.DESC,

    weightSortBy: WEIGHT_TABLE_COLUMN_HEADERS.RECORDED_AT,
    weightSortDirection: SORT_TYPES.DESC,
  },
);

const initialCustomReportDateRange = {
  startDate: moment().subtract(INITIAL_CUSTOM_REPORT_RANGE_DAYS - 1, 'days'),
  endDate: today,
};

const initialState = {
  ...cloneDeep(filters),
  ...cloneDeep(sorts),
  bulkActionIsFetching: false,
  bulkActionComplete: false,
  bulkActionSuccessMessage: null,
  refreshClicked: false,
  selectedIndex: 0,
  selectedTab: PATIENT_FILE_TABS.EKGS,
  selectedPopover: null,
  selectedPatientId: '',
  dataIsFetching: false,
  dataLastUpdated: null,
  customReportDateRange: initialCustomReportDateRange,
  customReportPopoverOpen: false,
  eosSummaryReportStatus: null,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [DATA_REQUEST]: (state, action) => ({
    ...state,
    dataIsFetching: true,
  }),
  [DATA_SUCCESS]: (state, action) => ({
    ...state,
    dataIsFetching: false,
    dataLastUpdated: new Date(),
  }),
  [DATA_FAILURE]: (state, action) => ({
    ...state,
    dataIsFetching: false,
    dataLastUpdated: new Date(),
  }),
  [UPDATE_BULK_ACTION_IS_FETCHING]: (state, action) => ({
    ...state,
    bulkActionIsFetching: action.payload,
  }),
  [UPDATE_BULK_ACTION_COMPLETE]: (state, action) => ({
    ...state,
    bulkActionComplete: action.payload,
  }),
  [UPDATE_BULK_ACTION_SUCCESS_MESSAGE]: (state, action) => ({
    ...state,
    bulkActionSuccessMessage: action.payload,
  }),
  [UPDATE_REFRESH_CLICKED]: (state, action) => ({
    ...state,
    refreshClicked: action.payload,
  }),
  [UPDATE_SELECTED_INDEX]: (state, action) => ({
    ...state,
    selectedIndex: action.payload,
  }),
  [UPDATE_SELECTED_TAB]: (state, action) => ({
    ...state,
    selectedTab: action.payload,
  }),
  [UPDATE_SELECTED_PATIENT_ID]: (state, action) => ({
    ...state,
    selectedPatientId: action.payload,
  }),
  [UPDATE_SELECTED_POPOVER]: (state, action) => ({
    ...state,
    selectedPopover: action.payload,
  }),

  [RESET_FILTERS]: (state, action) => ({
    ...state,
    ...filters,
  }),
  [RESET_SORTS]: (state, action) => ({
    ...state,
    ...sorts,
  }),

  [UPDATE_SELECTED_ENROLLMENT_START_DATES]: (state, action) => ({
    ...state,
    selectedEnrollmentStartStartDate: action.payload.startDate,
    selectedEnrollmentStartEndDate: action.payload.endDate,
  }),
  [UPDATE_SELECTED_ENROLLMENT_END_DATES]: (state, action) => ({
    ...state,
    selectedEnrollmentEndStartDate: action.payload.startDate,
    selectedEnrollmentEndEndDate: action.payload.endDate,
  }),
  [UPDATE_SELECTED_ENROLLMENT_PRESCRIPTION_ID]: (state, action) => ({
    ...state,
    selectedEnrollmentPrescriptionId: action.payload,
  }),
  [UPDATE_SELECTED_ENROLLMENT_ICD10_CODE_ID]: (state, action) => ({
    ...state,
    selectedEnrollmentIcd10CodeId: action.payload,
  }),
  [UPDATE_SELECTED_ENROLLMENT_BILLING_CODE]: (state, action) => ({
    ...state,
    selectedEnrollmentBillingCode: action.payload,
  }),
  [UPDATE_SELECTED_ENROLLMENT_ORDER_NUMBER]: (state, action) => ({
    ...state,
    selectedEnrollmentOrderNumber: action.payload,
  }),
  [UPDATE_ENROLLMENT_SORTS]: (state, action) => ({
    ...state,
    enrollmentSortBy: action.payload.sortBy,
    enrollmentSortDirection: action.payload.sortDirection,
  }),

  [UPDATE_SELECTED_EKG_RECORDING_ID]: (state, action) => ({
    ...state,
    selectedEkgRecordingId: action.payload,
  }),
  [UPDATE_SELECTED_EKG_RESULT]: (state, action) => ({
    ...state,
    selectedEkgResult: action.payload,
  }),
  [UPDATE_SELECTED_EKG_PHYSICIAN_ID]: (state, action) => ({
    ...state,
    selectedEkgPhysicianId: action.payload,
  }),
  [UPDATE_SELECTED_EKG_PHYSICIANS]: (state, action) => ({
    ...state,
    selectedEkgPhysicians: action.payload,
  }),
  [UPDATE_SELECTED_EKG_DATES]: (state, action) => ({
    ...state,
    selectedEkgStartDate: action.payload.startDate,
    selectedEkgEndDate: action.payload.endDate,
  }),
  [UPDATE_SELECTED_EKG_BPM_VALUES]: (state, action) => ({
    ...state,
    selectedEkgBpmStartValue: action.payload.startValue,
    selectedEkgBpmEndValue: action.payload.endValue,
  }),
  [UPDATE_SELECTED_EKGS]: (state, action) => ({
    ...state,
    selectedEkgs: action.payload,
  }),
  [UPDATE_EKG_SORTS]: (state, action) => ({
    ...state,
    ekgSortBy: action.payload.sortBy,
    ekgSortDirection: action.payload.sortDirection,
  }),

  [UPDATE_SELECTED_BLOOD_PRESSURE_RECORDING_ID]: (state, action) => ({
    ...state,
    selectedBloodPressureRecordingId: action.payload,
  }),
  [UPDATE_SELECTED_BLOOD_PRESSURE_SEVERITY]: (state, action) => ({
    ...state,
    selectedBloodPressureSeverity: action.payload,
  }),
  [UPDATE_SELECTED_BLOOD_PRESSURE_SOURCE]: (state, action) => ({
    ...state,
    selectedBloodPressureSource: action.payload,
  }),
  [UPDATE_SELECTED_BLOOD_PRESSURE_DATES]: (state, action) => ({
    ...state,
    selectedBloodPressureStartDate: action.payload.startDate,
    selectedBloodPressureEndDate: action.payload.endDate,
  }),
  [UPDATE_SELECTED_BLOOD_PRESSURE_BPM_VALUES]: (state, action) => ({
    ...state,
    selectedBloodPressureBpmStartValue: action.payload.startValue,
    selectedBloodPressureBpmEndValue: action.payload.endValue,
  }),
  [UPDATE_SELECTED_BLOOD_PRESSURE_SYSTOLIC_VALUES]: (state, action) => ({
    ...state,
    selectedBloodPressureSystolicStartValue: action.payload.startValue,
    selectedBloodPressureSystolicEndValue: action.payload.endValue,
  }),
  [UPDATE_SELECTED_BLOOD_PRESSURE_DIASTOLIC_VALUES]: (state, action) => ({
    ...state,
    selectedBloodPressureDiastolicStartValue: action.payload.startValue,
    selectedBloodPressureDiastolicEndValue: action.payload.endValue,
  }),
  [UPDATE_SELECTED_BLOOD_PRESSURES]: (state, action) => ({
    ...state,
    selectedBloodPressures: action.payload,
  }),
  [UPDATE_BLOOD_PRESSURE_SORTS]: (state, action) => ({
    ...state,
    bloodPressureSortBy: action.payload.sortBy,
    bloodPressureSortDirection: action.payload.sortDirection,
  }),

  [UPDATE_REPORT_SORTS]: (state, action) => ({
    ...state,
    reportSortBy: action.payload.sortBy,
    reportSortDirection: action.payload.sortDirection,
  }),
  [UPDATE_SELECTED_REPORT_ESIGNED_ID]: (state, action) => ({
    ...state,
    selectedReportEsignedId: action.payload,
  }),
  [UPDATE_SELECTED_REPORT_EXPORTED_ID]: (state, action) => ({
    ...state,
    selectedReportExportedId: action.payload,
  }),
  [UPDATE_SELECTED_REPORT_PHYSICIAN_ID]: (state, action) => ({
    ...state,
    selectedReportPhysicianId: action.payload,
  }),
  [UPDATE_SELECTED_REPORT_PHYSICIANS]: (state, action) => ({
    ...state,
    selectedReportPhysicians: action.payload,
  }),
  [UPDATE_SELECTED_REPORT_PRESCRIPTION_ID]: (state, action) => ({
    ...state,
    selectedReportPrescriptionId: action.payload,
  }),
  [UPDATE_SELECTED_REPORT_BILLING_CODE]: (state, action) => ({
    ...state,
    selectedReportBillingCode: action.payload,
  }),
  [UPDATE_SELECTED_REPORT_DATES]: (state, action) => ({
    ...state,
    selectedReportStartDate: action.payload.startDate,
    selectedReportEndDate: action.payload.endDate,
  }),
  [UPDATE_SELECTED_REPORTS]: (state, action) => ({
    ...state,
    selectedReports: action.payload,
  }),
  [UPDATE_CUSTOM_REPORT_POPOVER_STATE]: (state, action) => ({
    ...state,
    customReportPopoverOpen: action.payload,
  }),
  [RESET_CUSTOM_REPORT_DATE_RANGE]: (state, action) => ({
    ...state,
    customReportDateRange: initialCustomReportDateRange,
  }),
  [SET_CUSTOM_REPORT_DATE_RANGE]: (state, action) => ({
    ...state,
    customReportDateRange: action.payload,
  }),

  [UPDATE_SELECTED_WEIGHT_RECORDING_ID]: (state, action) => ({
    ...state,
    selectedWeightRecordingId: action.payload,
  }),
  [UPDATE_SELECTED_WEIGHT_SEVERITY]: (state, action) => ({
    ...state,
    selectedWeightSeverity: action.payload,
  }),
  [UPDATE_SELECTED_WEIGHT_SOURCE]: (state, action) => ({
    ...state,
    selectedWeightSource: action.payload,
  }),
  [UPDATE_SELECTED_WEIGHT_DATES]: (state, action) => ({
    ...state,
    selectedWeightStartDate: action.payload.startDate,
    selectedWeightEndDate: action.payload.endDate,
  }),
  [UPDATE_SELECTED_WEIGHT_BMI_VALUES]: (state, action) => ({
    ...state,
    selectedWeightBmiStartValue: action.payload.startValue,
    selectedWeightBmiEndValue: action.payload.endValue,
  }),
  [UPDATE_SELECTED_WEIGHT_WEIGHT_VALUES]: (state, action) => ({
    ...state,
    selectedWeightWeightStartValue: action.payload.startValue,
    selectedWeightWeightEndValue: action.payload.endValue,
  }),
  [UPDATE_SELECTED_WEIGHTS]: (state, action) => ({
    ...state,
    selectedWeights: action.payload,
  }),
  [UPDATE_WEIGHT_SORTS]: (state, action) => ({
    ...state,
    weightSortBy: action.payload.sortBy,
    weightSortDirection: action.payload.sortDirection,
  }),
  [EOS_SUMMARY_REPORT_STATUS]: (state, action) => ({
    ...state,
    eosSummaryReportStatus: action.payload,
  }),

  [CLEAR_STATE]: (state, action) => ({ ...initialState }),
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function patientFileReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
