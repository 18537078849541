/* eslint-disable */
import React from 'react'
import { symbol, symbolTriangle, symbolSquare } from 'd3'
import { KARDIA_RX_EKG_CLASSNAMES } from '../../../../constants/recordingMaps'

import styles from './Legend.module.css'

export const Legend = props => {
  const {
    items,
    legendOrder,
    getLegendClassname,
    getLegendDisplayText,
    isBloodPressure,
    isKardiaRx,
  } = props

  const legendItems = () =>
    legendOrder.map(item => (
      <div key={item} className={styles.legendItem}>
        {isKardiaRx ?
          item === KARDIA_RX_EKG_CLASSNAMES.mdn ?
            <svg>
              <g transform={`translate(${6}, ${8})`}>
                <path
                  className={styles[getLegendClassname(item)]}
                  d={symbol()
                    .type(symbolSquare)
                    .size(8* 10.667)()}
                />
              </g>
            </svg>
            :
            <svg>
              <circle
                className={`${styles.circle} ${styles[getLegendClassname(item)]
                  }`}
                cy={6}
                cx={6}
                r={6}
              />
            </svg>
          :
          <React.Fragment>
            <svg>
              <circle
                className={`${styles.circle} ${styles[getLegendClassname(item)]
                  }`}
                cy={6}
                cx={6}
                r={6}
              />
            </svg>
            {isBloodPressure && (
              <svg>
                <g transform={`translate(${6}, ${8})`}>
                  <path
                    className={styles[getLegendClassname(item)]}
                    d={symbol()
                      .type(symbolTriangle)
                      .size(6 * 10.667)()}
                  />
                </g>
              </svg>
            )}
          </React.Fragment>
        }
        <span className={styles.countText}>
          {items[item]} {getLegendDisplayText(item)}
        </span>
      </div>
    ))

  return <div className={styles.legend}>{legendItems()}</div>
}

export default Legend
