import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Box, Typography } from '@material-ui/core';
import DashboardTable from './DashboardTable';
import CardContainer from './Card/CardTopContainer';
import CardBottomContainer from './Card/CardBottomContainer';
import { useDispatch } from 'react-redux';
import { getUserMemberStatuses } from '../../Store/actions/user';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: '30px',
    font: 'Work Sans !important',
  },
  paper: {
    height: 300,
    width: 'auto',
  },
  title: {
    color: '#142A39',
    fontSize: '20px',
    lineHeight: '23px',
    fontFamily: 'Work Sans',
    fontWeight: '600',
  },
}));
const Dashboard = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  useEffect(() => {
    dispatch(getUserMemberStatuses());
  }, []);
  return (
    <Box className={classes.root}>
      <Grid container spacing={8}>
        <Grid item xs={8}>
          {/* Todays session table  */}
          <Typography className={classes.title} gutterBottom>
            Today's Sessions
          </Typography>
          <DashboardTable />
        </Grid>
        <Grid item xs={4}>
          {/* Card display here */}
          <Box>
            <Typography className={classes.title} gutterBottom>
              Messages and Surveys
            </Typography>
            <CardContainer />
            <Typography className={classes.title} gutterBottom>
              Member Statuses
            </Typography>
            <CardBottomContainer />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
