import { Grid } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import DashboardCard from './Card';
import MailIcon from '@material-ui/icons/Mail';
import EventNoteIcon from '@material-ui/icons/EventNote';
import { ChatContext } from '@alivecor/strophe2.0';

const CardContainer = () => {

  const { unreadMsgCount } = useContext(ChatContext);
  const [unreadCount, setunreadCount] = useState(0);

  useEffect(() => {
    setunreadCount(
      Object.values(unreadMsgCount).reduce((acc, cur) => {
        return acc + cur;
      }, 0),
    );
  }, [unreadMsgCount]);
  return (  
    <Grid container spacing={4} style={{ marginBottom: '24px' }}>
      <Grid item xs={6}>
        <DashboardCard
          title="New messages"
          content={unreadCount}
          icon={true}
          iconFetch={() => <MailIcon />}
        />
      </Grid>
      <Grid item xs={6}>
        <DashboardCard
          title="New surveys"
          content="-"
          icon={true}
          iconFetch={() => <EventNoteIcon />}
        />
      </Grid>
    </Grid>
  );
};

export default CardContainer;
