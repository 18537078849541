import { getFullJid } from '@alivecor/strophe2.0';
import React, { useEffect, useState } from 'react';
import styles from './chat.module.css';

const ListItem = (props) => {
  const { item, selected, unreadMsgCount, onClick } = props;
  const { name, chatUserId } = item;
  const [count, setCount] = useState(0);
  useEffect(() => {
    if (unreadMsgCount[getFullJid(chatUserId)] > 0) {
      setCount(unreadMsgCount[getFullJid(chatUserId)]);
    }else{
      setCount(0);
    }
  }, [unreadMsgCount]);

  return (
    <div className={`${styles.listRow} ${selected ? styles.selectedRow : ''}`} onClick={onClick}>
      <div className={styles.listRowItem}>
        <div
          style={{
            fontWeight: count ? 'bold' : 'unset',
            fontSize: '14px',
            fontFamily: 'Work Sans',
          }}
        >
          {name || item.chatUserId}
        </div>
        {count > 0 && !selected && <div className={styles.count}>{count}</div>}
      </div>
    </div>
  );
};
export default ListItem;
