import React from 'react';
import queryString from 'query-string';
import ContentHeader from '../../Components/ContentHeader';
import EcgLoader from '../../Components/Shared/EcgLoader';
import {
  SETTINGS_PAGE_TABS,
  TABLE_HEADER_HEIGHT,
  HEADER_HEIGHT,
  getPermissionOptions,
  CUSTOM_TRIGGER_DEFAULT,
  ALL_RECORDINGS_TRIGGER_OPTION,
  TRIAGE_OPTION,
  PRIMARY_TRIGGER_TYPES,
  TRIGGER_FREQUENCY_TYPES,
  getPrimaryTriggerOption,
  CUSTOM_TRIGGER_OPTION,
  TRIGGER_TYPES,
  ROUTES,
} from '../../constants/app';
import { TeamInvitesTable, TeamMembersTable } from '../../Components/Tables';
import styles from './settings.module.css';
import { needToBustCache } from '../../Store/reducers/app';

import {
  NewMemberForm,
  UpdatePasswordForm,
  InboxSettingsForm,
  TeamSettingsForm,
} from '../../Components/Forms';
import { Paper, Tabs, Tab, Container } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { checkTKFeatureEnabled } from '../../configs';
import { withTranslation } from 'react-i18next';
// import { ROUTES } from '../../Components/chart/Constants';
import TeamAlert from '../../Components/Shared/Alert/TeamAlert';

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#FFFFFF',
    // marginTop: theme.spacing(2),
  },
  paperroot: {
    flexGrow: 1,
    // maxWidth: 1000,
    marginBottom: '2px',
  },
  customTabRoot: {
    color: '#142A39',
    '& .Mui-selected': {
      color: '#2D9F86',
    },
  },
  customTabIndicator: {
    backgroundColor: '#2d9f86 !important',
    height: '4px !important',
  },
  tabRoot: {
    fontSize: '14px !important',
    // textTransform: 'initial',
    minWidth: 166,
    fontWeight: '700 !important',
    fontFamily: 'Work Sans !important',
  },
});
function tabContentLoader(dataIsFetching, me, height, refreshClicked) {
  if (dataIsFetching || refreshClicked || me) {
    return (
      <div
        className={styles.contentWrapper}
        style={{
          height,
        }}
      >
        <EcgLoader />
      </div>
    );
  }
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <React.Fragment>{children}</React.Fragment>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const TEAM_INVITES_ROW_HEIGHT = 50;
class Settings extends React.Component {
  state = {
    innerHeight: 0,
    value: 0,
    // innerWidth: 0,
  };

  UNSAFE_componentWillMount() {
    const { dataLastUpdated, getSettingsData, getTeamMemberFileData } = this.props;
    getSettingsData(needToBustCache(dataLastUpdated));
    // getTeamMemberFileData(needToBustCache(dataLastUpdated));
    window.addEventListener('resize', this.handleResize);
    this.setState({
      innerHeight: window.innerHeight,
      // innerWidth: window.innerWidth,
    });
  }

  componentDidMount() {
    const { location } = this.props;
    let data = queryString.parse(location.search);
    if (data.tab) {
      this.setState({ value: parseInt(data.tab) });
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({
      innerHeight: window.innerHeight,
      // innerWidth: window.innerWidth,
    });
  };

  render() {
    const {
      dataIsFetching,
      dataLastUpdated,
      handleOnRefreshClick,
      refreshClicked,
      bulkActionComplete,
      bulkActionIsFetching,
      selectedIndex,
      selectedPageTab,
      selectedPopover,
      handleOnTabClick,
      handleOnInviteSubmit,
      handleOnFilterButtonClick,
      handleOnSelectionClick,
      teamInvitesIds,
      selectedTeamInvites,
      selectedTeamInvitesEmail,
      selectedTeamInvitesFirstName,
      selectedTeamInvitesLastName,
      teamInvitesSortBy,
      teamInvitesSortDirection,
      handleOnTeamInvitesFilterChange,
      handleOnTeamInvitesSortClick,
      handleOnTeamInvitesBulkAction,
      teamMembersIds,
      appointmentNotifications,
      teamMembersSortBy,
      teamMembersSortDirection,
      selectedTeamMembers,
      selectedTeamMembersId,
      selectedTeamMembersEmail,
      selectedTeamMembersCountry,
      selectedTeamMembersRegion,
      selectedTeamMembersAdminPermissionId,
      selectedTeamMembersPhysicianPermissionId,
      selectedTeamMembersCanManageAvailabilityId,
      updateSelectedTeamMembers,
      handleOnTeamMembersFilterChange,
      handleOnTeamMembersSortClick,
      handleOnTeamMemberRowClick,
      me,
      teamInvites: teamInvitesDataSource,
      physicians: physiciansDataSource,
      teamTriggers: teamTriggersDataSource,
      pwError,
      pwErrorMsg,
      pwIsFetching,
      validationTests,
      inputFieldValidation,
      handleUpdatePasswordChange,
      handleAppointmentNotificationsChange,
      handleOnEditTeamMemberSubmit,
      getAppointmentNotificationsSettings,
      submitUpdatePassword,
      handleInboxSettingsForm,
      inboxSettingsFormData,
      inboxSettingsFormPristine,
      handleOnEditTeamMember,
      getTeamMemberFileData,
      memberOnChangeInfo,
      memberInfo,
      inviteMemberFormValues,
      classes,
      t,
      handleUpdateInputChange,
      notificationFetching,
    } = this.props;
    const { innerHeight } = this.state;

    const height = innerHeight - HEADER_HEIGHT - 11;
    const width = 1180 - 20 * 2;

    let teamInvites = [];
    if (me.data && me.data.teams[0].invites && teamInvitesDataSource.data) {
      teamInvites = teamInvitesIds.map((id) => teamInvitesDataSource.data.byId[id]);
    }

    let teamMembers = [];
    if (physiciansDataSource.data && !refreshClicked) {
      teamMembers = teamMembersIds.map((id) => physiciansDataSource.data.byId[id]);
    }
    // THIS IS THE SAME LOGIC COPIED FROM THE PATIENT FILE
    let inboxSettingsInitialValues = {
      custom: {
        ...CUSTOM_TRIGGER_DEFAULT,
      },
      ekgReviewWhichRecordings: ALL_RECORDINGS_TRIGGER_OPTION,
      ekgReviewWhereRecordings: TRIAGE_OPTION,
    };

    const getFormInboxPresets = (triggersById, typeToIdLookup, presets) => {
      const newPresets = {
        ...presets,
      };
      const whichPrimaryTriggerType = Object.keys(PRIMARY_TRIGGER_TYPES).find((triggerType) => {
        const id = typeToIdLookup[triggerType];
        return triggersById[id].frequency === TRIGGER_FREQUENCY_TYPES.ALWAYS;
      });

      if (whichPrimaryTriggerType) {
        const id = typeToIdLookup[whichPrimaryTriggerType];
        const triggerData = triggersById[id];
        newPresets.ekgReviewWhichRecordings = getPrimaryTriggerOption(
          whichPrimaryTriggerType,
          triggerData,
        );
      } else {
        newPresets.ekgReviewWhichRecordings = CUSTOM_TRIGGER_OPTION;
        const possibleAFibResultId = typeToIdLookup[TRIGGER_TYPES.POSSIBLE_AFIB_DETECTION];
        if (triggersById[possibleAFibResultId].frequency === TRIGGER_FREQUENCY_TYPES.ALWAYS) {
          newPresets.custom.possibleAFibResultCheckmark = true;
        }
        const possibleAFibResultWithHrId = typeToIdLookup[TRIGGER_TYPES.HEART_RATE_OVER_X_AND_AFIB];
        if (triggersById[possibleAFibResultWithHrId].frequency === TRIGGER_FREQUENCY_TYPES.ALWAYS) {
          newPresets.custom.possibleAFibResultWithHrCheckmark = true;
          newPresets.custom.possibleAFibResultWithHr = parseInt(
            triggersById[possibleAFibResultWithHrId].value,
            10,
          );
        }
        const allUnclassifiedId = typeToIdLookup[TRIGGER_TYPES.ALL_UNCLASSIFIED];
        if (triggersById[allUnclassifiedId].frequency === TRIGGER_FREQUENCY_TYPES.ALWAYS) {
          newPresets.custom.unclassifiedResultCheckmark = true;
        }
        const heartRateUnderId = typeToIdLookup[TRIGGER_TYPES.HEART_RATE_UNDER_X];
        if (triggersById[heartRateUnderId].frequency === TRIGGER_FREQUENCY_TYPES.ALWAYS) {
          newPresets.custom.heartRateUnderCheckmark = true;
          newPresets.custom.heartRateUnder = parseInt(triggersById[heartRateUnderId].value, 10);
        }
        const heartRateOverId = typeToIdLookup[TRIGGER_TYPES.HEART_RATE_OVER_X];
        if (triggersById[heartRateOverId].frequency === TRIGGER_FREQUENCY_TYPES.ALWAYS) {
          newPresets.custom.heartRateOverCheckmark = true;
          newPresets.custom.heartRateOver = parseInt(triggersById[heartRateOverId].value, 10);
        }
        const bradycardiaId = typeToIdLookup[TRIGGER_TYPES.BRADYCARDIA];
        if (triggersById[bradycardiaId].frequency === TRIGGER_FREQUENCY_TYPES.ALWAYS) {
          newPresets.custom.bradycardia = true;
        }
        const tachycardiaId = typeToIdLookup[TRIGGER_TYPES.TACHYCARDIA];
        if (triggersById[tachycardiaId].frequency === TRIGGER_FREQUENCY_TYPES.ALWAYS) {
          newPresets.custom.tachycardia = true;
        }
        const sinusRhythmWithPVCsId = typeToIdLookup[TRIGGER_TYPES.SINUS_RHYTHM_PVC];
        if (triggersById[sinusRhythmWithPVCsId].frequency === TRIGGER_FREQUENCY_TYPES.ALWAYS) {
          newPresets.custom.sinusRhythmWithPVCs = true;
        }
        const sinusRhythmWithSVEsId = typeToIdLookup[TRIGGER_TYPES.SINUS_RHYTHM_SVE];
        if (triggersById[sinusRhythmWithSVEsId].frequency === TRIGGER_FREQUENCY_TYPES.ALWAYS) {
          newPresets.custom.sinusRhythmWithSVEs = true;
        }
        const sinusRhythmWithWideQRSId = typeToIdLookup[TRIGGER_TYPES.SINUS_RHYTHM_WIDE_QRS];
        if (triggersById[sinusRhythmWithWideQRSId].frequency === TRIGGER_FREQUENCY_TYPES.ALWAYS) {
          newPresets.custom.sinusRhythmWithWideQRS = true;
        }
      }
      return newPresets;
    };

    if (teamTriggersDataSource.data) {
      inboxSettingsInitialValues = {
        ...inboxSettingsInitialValues,
        ...getFormInboxPresets(
          teamTriggersDataSource.data.byId,
          teamTriggersDataSource.data.typeToIdLookup,
          inboxSettingsInitialValues,
        ),
      };
    }

    let teamInvitesTableHeight =
      teamInvites.length > 0
        ? TEAM_INVITES_ROW_HEIGHT * teamInvites.length
        : TEAM_INVITES_ROW_HEIGHT * 5;
    teamInvitesTableHeight += TABLE_HEADER_HEIGHT;

    let isAdmin = false;
    if (me.data) {
      const { isAdmin: adminPermission } = me.data.profile.permissions;
      isAdmin = adminPermission;
    }

    // For modifying the values of country & region acc. to requiremnt
    const cleanValues = (value) => {
      const region = value && value.regions && value.regions[0];
      const smallArray = value && value.regions && [...value.regions];
      let memberArray;

      if (smallArray && smallArray.length > 1) {
        // const arrayChange = smallArray.shift();
        memberArray = smallArray.map((item, i) => ({
          country: 'US',
          region: item,
        }));
      }

      const countryTem = {
        region,
        members: memberArray,
        // members: [ {
        //   Country: 'US',
        //   Region: 'NEVEDA1',
        // } ],
      };

      const tempValue = { ...countryTem, ...value };
      const newProfile = {
        ...tempValue,
      };
      return newProfile;
    };

    let physician;
    let initialValues;
    if (memberInfo.data) {
      physician = memberInfo.data;
      initialValues = cleanValues(physician);
    }

    const TKFeatureEnabled = checkTKFeatureEnabled(me);

    const handleChange = (event, newValue) => {
      this.props.history.push(`${ROUTES.SETTINGS}?tab=${newValue}`);
      this.setState({ value: newValue });
    };
    const isLoading = Boolean(dataIsFetching || notificationFetching || refreshClicked);

    return (
      <Container maxWidth="xl" style={{ margin: '20px auto' }}>
        <ContentHeader
          isLoading={dataIsFetching}
          lastUpdated={dataLastUpdated}
          handleOnRefreshClick={handleOnRefreshClick}
          hasTabs={isAdmin}
          showAddPatient={false}
          text="Settings"
          me={me}
        />
        <Paper className={classes.paperroot}>
          <Tabs
            classes={{
              root: classes.customTabRoot,
              indicator: classes.customTabIndicator,
            }}
            value={this.state.value}
            onChange={handleChange}
            scrollButtons="auto"
            centered
            variant="fullWidth"
          >
            {TKFeatureEnabled && me.data && me.data.profile.permissions.isAdmin ? (
              <Tab
                label={t('MY INFO')}
                classes={{ root: classes.tabRoot }}
                onClick={() => handleOnTabClick(SETTINGS_PAGE_TABS.UPDATE_PASSWORD)}
                {...a11yProps(0)}
              />
            ) : (
              ''
            )}
            {isAdmin && (
              <Tab
                label={t('ADD COACH')}
                classes={{ root: classes.tabRoot }}
                onClick={() => handleOnTabClick(SETTINGS_PAGE_TABS.INVITE_TEAM_MEMBER)}
                {...a11yProps(1)}
              />
            )}
            {isAdmin && (
              <Tab
                label={t('COACHES')}
                classes={{ root: classes.tabRoot }}
                onClick={() => handleOnTabClick(SETTINGS_PAGE_TABS.TEAM_MEMBERS)}
                {...a11yProps(2)}
              />
            )}

            {TKFeatureEnabled && isAdmin && (
              <Tab
                label={t('TEAM SETTINGS')}
                classes={{ root: classes.tabRoot }}
                onClick={() => handleOnTabClick(SETTINGS_PAGE_TABS.TEAM_SETTINGS)}
                {...a11yProps(3)}
              />
            )}
          </Tabs>
        </Paper>

        <TabPanel value={this.state.value} index={0}>
          {/* {tabContentLoader(dataIsFetching, notificationFetching, height, refreshClicked)} */}
          <div
            className={styles.formWrapper}
            style={{
              height,
            }}
          >
            <div className={styles.formTop}>
              {!isLoading && (
                <h2 className={styles.formTitle}>{!TKFeatureEnabled && `Update Password`}</h2>
              )}
              <UpdatePasswordForm
                handleInputChange={handleUpdatePasswordChange}
                handleNotificationChange={handleAppointmentNotificationsChange}
                onSubmit={submitUpdatePassword}
                isSubmitting={pwIsFetching}
                pwError={pwError}
                pwErrorMsg={pwErrorMsg}
                validationTests={validationTests}
                me={me}
                getAppointmentNotificationsSettings={getAppointmentNotificationsSettings}
                getMemberInfo={getTeamMemberFileData}
                onInfoSubmit={handleOnEditTeamMemberSubmit}
                initialValues={initialValues}
                memberOnChangeInfo={memberOnChangeInfo}
                handleUpdateInputChange={handleUpdateInputChange}
                inputFieldValidation={inputFieldValidation}
                isLoading={isLoading}
              />
            </div>
          </div>
        </TabPanel>

        <TabPanel value={this.state.value} index={1}>
          {tabContentLoader(dataIsFetching, false, height, refreshClicked)}
          {!(
            (dataIsFetching && (!me.data || (me.data && !me.data.teams[0].invites))) ||
            refreshClicked
          ) && (
            <div
              className={styles.formWrapper}
              style={{
                height,
              }}
            >
              <div className={styles.formTop}>
                <h2 className={styles.formTitle}>Add Coach</h2>
                <h3 className={styles.formSubtitle}>
                  When you add a coach to your practice, we will send an invite email to the email
                  address provided.
                </h3>
                <NewMemberForm
                  onSubmit={handleOnInviteSubmit}
                  isSubmitting={dataIsFetching}
                  showTKFeatureEnabled={TKFeatureEnabled}
                  inviteMemberFormValues={inviteMemberFormValues}
                />
              </div>
              <div className={styles.formTop}>
                <h2 className={styles.formTitle}>Pending Invites</h2>
                <div
                  className={styles.revokeSection}
                  style={{
                    height: teamInvitesTableHeight,
                  }}
                >
                  <TeamInvitesTable
                    data={teamInvites}
                    showRowSelection={true}
                    selected={selectedTeamInvites}
                    onSelectionClick={handleOnSelectionClick}
                    height={height}
                    width={width - 30 * 2}
                    sortBy={teamInvitesSortBy}
                    sortDirection={teamInvitesSortDirection}
                    rowHeight={TEAM_INVITES_ROW_HEIGHT}
                    filterEmail={selectedTeamInvitesEmail}
                    filterFirstName={selectedTeamInvitesFirstName}
                    filterLastName={selectedTeamInvitesLastName}
                    handleOnFilterButtonClick={handleOnFilterButtonClick}
                    handleOnFilterChange={handleOnTeamInvitesFilterChange}
                    handleOnSortClick={handleOnTeamInvitesSortClick}
                    handleOnOutsidePopoverClick={() => handleOnFilterButtonClick(null)}
                    handleOnBulkAction={handleOnTeamInvitesBulkAction}
                    handleOnBulkActionClearSelection={() => handleOnSelectionClick({})}
                    handleOnPopoverKeyUp={(e) => {
                      const enterKeyCode = 13;
                      if (
                        typeof handleOnFilterButtonClick === 'function' &&
                        e.keyCode === enterKeyCode
                      ) {
                        handleOnFilterButtonClick(null);
                      }
                    }}
                    bulkActionComplete={bulkActionComplete}
                    bulkActionIsFetching={bulkActionIsFetching}
                    selectedPopover={selectedPopover}
                    selectedEmail={selectedTeamInvitesEmail}
                    selectedFirstName={selectedTeamInvitesFirstName}
                    selectedLastName={selectedTeamInvitesLastName}
                  />
                </div>
              </div>
            </div>
          )}
        </TabPanel>

        <TabPanel value={this.state.value} index={2}>
          {tabContentLoader(dataIsFetching, false, height, refreshClicked)}
          {!dataIsFetching && (
            <div
              style={{
                height,
              }}
            >
              <TeamMembersTable
                enableLoader={teamMembersIds.length === 0 || refreshClicked}
                showLoader={dataIsFetching}
                data={teamMembers}
                showRowSelection={false}
                selected={selectedTeamMembers}
                onSelectionClick={updateSelectedTeamMembers}
                height={height}
                width={width}
                onRowClick={handleOnTeamMemberRowClick}
                selectedIndex={selectedIndex}
                sortBy={teamMembersSortBy}
                sortDirection={teamMembersSortDirection}
                filterName={selectedTeamMembersId}
                filterEmail={selectedTeamMembersEmail}
                filterCountry={selectedTeamMembersCountry}
                filterRegion={selectedTeamMembersRegion}
                filterAdminPermission={selectedTeamMembersAdminPermissionId}
                filterPhysicianPermission={selectedTeamMembersPhysicianPermissionId}
                handleOnFilterButtonClick={handleOnFilterButtonClick}
                handleOnFilterChange={handleOnTeamMembersFilterChange}
                handleOnSortClick={handleOnTeamMembersSortClick}
                handleOnOutsidePopoverClick={() => handleOnFilterButtonClick(null)}
                // handleOnBulkAction={handleOnTeamMembersBulkAction}
                handleOnPopoverKeyUp={(e) => {
                  const enterKeyCode = 13;
                  if (
                    typeof handleOnFilterButtonClick === 'function' &&
                    e.keyCode === enterKeyCode
                  ) {
                    handleOnFilterButtonClick(null);
                  }
                }}
                selectedPopover={selectedPopover}
                physicians={physiciansDataSource}
                selectedId={selectedTeamMembersId}
                selectedEmail={selectedTeamMembersEmail}
                selectedCountry={selectedTeamMembersCountry}
                selectedRegion={selectedTeamMembersRegion}
                permissionOptions={getPermissionOptions().slice(1)}
                selectedAdminPermissionId={selectedTeamMembersAdminPermissionId}
                selectedTeamMembersCanManageAvailabilityId={
                  selectedTeamMembersCanManageAvailabilityId
                }
                selectedPhysicianPermissionId={selectedTeamMembersPhysicianPermissionId}
                handleOnClickTeamMeamber={handleOnTabClick}
                handleOnEditTeamMember={handleOnEditTeamMember}
                showTKFeatureEnabled={TKFeatureEnabled}
                me={me}
              />
            </div>
          )}
        </TabPanel>
        {appointmentNotifications.isSuccess && (
          <TeamAlert title={'Changes successfully saved'} error={false} />
        )}
        <TabPanel value={this.state.value} index={3}>
          <div
            className={styles.formWrapper}
            style={{
              height,
            }}
          >
            <div className={styles.formTop}>
              <TeamSettingsForm />
              {/* Team Setting form comes here */}
            </div>
          </div>
        </TabPanel>
      </Container>
    );
  }
}

export default withStyles(useStyles)(withTranslation()(Settings));
