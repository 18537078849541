import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { Box, Typography } from '@material-ui/core';
import styles from '../../../memberProfile.module.css';
import { programStyles } from '../styles';
import { commonStyles } from '../../../styles';
import { useParams } from 'react-router';
import {
  getMemberProfilePFDetails,
  getMemberProfilePPDetails,
} from '../../../../../Store/actions/memberProfile';
import { useDispatch, useSelector } from 'react-redux';

import classnames from 'classnames';
import { fontSizes, spacing } from '../../../../../style/styleSettings';
import ChipComponent from '../../../../../Components/Dashboard/SessionComponents/ChipComponent';
import EcgLoader from '../../../../../Components/Shared/EcgLoaderSmall';
import moment from 'moment';
import { GoAPI } from '../../../../../Axios/axios';
import { getToken } from '../../../../../Auth/AuthUtilities';

const ProgramFocus = () => {
  const classes = programStyles();
  const commonClasses = commonStyles();
  const dispatch = useDispatch();
  const { memberId } = useParams();
  const coachId = useSelector((state) => state.user.profile.id);
  const teamId = useSelector((state) => state.user.team.id);
  const offsetInMinutes = new Date().getTimezoneOffset();
  const { loading, data, error } = useSelector((state) => state.memberProfile.programFocus);
  const [viewHHData, setViewHHData] = useState([])
  useEffect(() => {
    dispatch(getMemberProfilePFDetails(teamId, coachId, memberId, offsetInMinutes));
  }, []);
  useEffect(() => {
    const appointmentData = data?.note?.appointmentId
    GoAPI.get(`/i/v1/kcc/heartHabit/${memberId}/${appointmentData}`, {
      headers: { Authorization: ` Bearer ${getToken()}` },
    })
      .then((res) => {
        const data = res.data ? res.data : [];
        setViewHHData(data);
      })
      .catch((err) => console.log(err));
  },[data])
  return (
    <Box className={classnames(commonClasses.divWrapper, classes.divWrapperOverride)}>
      <Typography variant="h5">Program Focus</Typography>
      <Box className={commonClasses.divContentWrapper}>
        {loading && (
          <Box>
            <EcgLoader />
          </Box>
        )}
        <Grid container className={styles.tableRowHeading}>
          {!loading && data && data.programFocus.length === 0 && (
            <Typography
              variant="body1"
              style={{ textAlign: 'center', width: '100%', color: 'grey', marginTop: '10px' }}
            >
              No Program Focus data to display
            </Typography>
          )}
          {!loading &&
            data &&
            data.programFocus.map((d, i) => (
              <Grid item lg={4} md={4} style={{ marginBottom: 0 }} key={i}>
                <Typography variant="body1">{d.focus}</Typography>
                <Box className={classes.chipHolder}>
                  {d.stageOfChange && (
                    <ChipComponent
                      variant="outlined"
                      size="small"
                      label={d.stageOfChange}
                      color="primary"
                      style={{
                        fontSize: `${fontSizes.SMALL}`,
                      }}
                    />
                  )}
                  {d.achievement && (
                    <ChipComponent
                      variant="outlined"
                      size="small"
                      label={d.achievement}
                      color="primary"
                      style={{
                        marginLeft: `${spacing.SESSION_MARGIN_LARGE}`,
                        fontSize: `${fontSizes.SMALL}`,
                      }}
                    />
                  )}
                </Box>
                <Box className={classes.holder}>
                  <Typography variant="body2">Current: {d.current || '--'}</Typography>
                  <Typography variant="body2">Target: {d.target || '--'}</Typography>
                </Box>
              </Grid>
            ))}
        </Grid>
        <Box className={classes.holder}>
          <Grid container>
            {!loading && viewHHData && viewHHData.length === 0 && (
              <Typography
                variant="body1"
                style={{ textAlign: 'center', width: '100%', color: 'grey', marginTop: '10px' }}
              >
                No Heart Habit data to display
              </Typography>
            )}
            {!loading &&
              viewHHData &&
              viewHHData.map((d, i) => (
                <Grid item md={4} lg={4} key={i}>
                  <Grid item xs={12} className={classes.holder_single}>
                    <Typography variant="body2">Habit:</Typography>
                    <Typography variant="body2">{d.focusNote}</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.holder_single}>
                    <Typography variant="body2">Frequency:</Typography>
                    <Typography variant="body2">
                      {d.recurrencePattern.split('=')[1].split(';')[0]}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.holder_single}>
                    <Typography variant="body2">Duration:</Typography>
                    <Typography variant="body2">
                      {moment(d.startDate).format('MM/DD/YYYY')} -{' '}
                      {moment(d.endDate).format('MM/DD/YYYY')}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default ProgramFocus;
