import AdminDashBoard from '../Components/AdminDashBoard/AdminDashBoard';
import AdminCalendar from '../Components/AdminCalendar/AdminCalendar';
import TeamMemberFile from '../Components/TeamMemberFile';
import * as Screens from '../CoachScreens';
import { ROUTES } from '../constants/app';
import AdminConsultations from '../Components/AdminConsultations/AdminConsultations';
import Feedbacks from '../Components/Feedbacks/Feedbacks';
import TeamMemberInvite from '../Components/TeamMemberInvite';
import RequestNewPassword from '../CoachScreens/RequestNewPassword';
import ResetPassword from '../CoachScreens/ResetPassword';
import ResetPasswordEmailLink from '../CoachScreens/ResetPassword/ResetPasswordEmailLink';
export const OpenRoutes = [
  {
    exact: true,
    path: ROUTES.LOGIN,
    component: Screens.Login,
  },
  {
    path: `${ROUTES.TEAM_MEMBER_INVITE}/:inviteCode`,
    component: TeamMemberInvite,
    
  },
  {
    path: `${ROUTES.REQUEST_NEW_PASSWORD}`,
    component: RequestNewPassword,
  },
  {
    path: `${ROUTES.RESET_PASSWORD}/:token`,
    component: ResetPassword,
  },
  {
    path: `${ROUTES.RESET_PASSWORD_EMAIL_LINK}/:token`,
    component: ResetPasswordEmailLink,
  },
];
export const AdminRoutes = [
  {
    exact: true,
    path: ROUTES.ROOT,
    component: AdminDashBoard,
  },
  {
    path: ROUTES.TELEKARDIA_DASHBOARD2,
    component: AdminDashBoard,
  },
  {
    path: ROUTES.ADMIN_CALENDAR,
    component: AdminCalendar,
  },
  {
    exact: true,
    path: ROUTES.ADMIN_MEMBER_LIST,
    component: Screens.MemberList,
  },
  {
    path: ROUTES.ADMIN_CONSULT,
    component: AdminConsultations,
  },
  {
    path: ROUTES.ADMIN_FEEDBACK,
    component: Feedbacks,
  },
  {
    exact: true,
    path: ROUTES.SETTINGS,
    component: Screens.Settings,
  },
  {
    path: `${ROUTES.SETTINGS}/team-members/:teamMemberId`,
    component: TeamMemberFile,
  },
  {
    exact: true,
    path: `${ROUTES.MEMBER_PROFILE}/:memberId`,
    component: Screens.MemberProfile,
  },
  {
    exact: true,
    path: `${ROUTES.ADMIN_SESSIONS}`,
    component: Screens.Sessions,
  },
];

export const PhysicianRoutes = [
  {
    exact: true,
    path: ROUTES.ROOT,
    component: Screens.DashboardNew,
  },
  // {
  //   path: ROUTES.COACH_PORTAL,
  //   component: Screens.CoachPortal,
  // },
  {
    exact: true,
    path: ROUTES.COACH_MEMBER_LIST,
    component: Screens.CoachPortal,
  },
  {
    exact: true,
    path: ROUTES.COACH_SCHEDULE,
    component: Screens.CoachPortal,
  },
  {
    path: ROUTES.TELEKARDIA_DASHBOARD,
    component: Screens.DashboardNew,
  },
  {
    exact: true,
    path: ROUTES.CHAT_INBOX,
    component: Screens.ChatInbox,
  },
  {
    path: ROUTES.CHAT_INBOX_DETAILS,
    component: Screens.ChatInboxDetails,
  },
  {
    exact: true,
    path: ROUTES.SETTINGS,
    component: Screens.Settings,
  },
  {
    exact: true,
    path: `${ROUTES.MEMBER_PROFILE}/:memberId`,
    component: Screens.MemberProfile,
  },
  {
    exact: true,
    path: ROUTES.COACH_RESOURCES,
    component: Screens.ComingSoonPage,
  },
];

export const AdminPhysicianRoutes = [
  ...AdminRoutes,
  {
    path: ROUTES.COACH_PORTAL,
    component: Screens.CoachPortal,
  },
  {
    exact: true,
    path: ROUTES.CHAT_INBOX,
    component: Screens.ChatInbox,
  },
  {
    path: ROUTES.CHAT_INBOX_DETAILS,
    component: Screens.ChatInboxDetails,
  },
  {
    path: ROUTES.TELEKARDIA_DASHBOARD,
    component: Screens.DashboardNew,
  },
];
