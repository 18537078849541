import React from 'react';
import queryString from 'query-string';
import styles from '../../CoachScreens/Login/login.module.css';
import Loading from '../Loading';
import KardiaProLogo from '../../CoachScreens/Login/Logo.js';
import ValidationRules from '../ValidationRules';

class TeamMemberInvite extends React.Component {
  componentDidMount() {
    const { match, location, setInitialTeamMemberInviteData, initialUpdate } = this.props;
    const queryParams = queryString.parse(location.search);
    const { firstName, lastName } = queryParams;
    initialUpdate({ firstName });
    initialUpdate({ lastName });
    initialUpdate({ password:'' });
    setInitialTeamMemberInviteData(match, location);
  }

  render() {
    const {
      submitForm,
      handleChange,
      handleKeyDown,
      teamMemberInvite: { error, errorMsg, dataIsFetching, validationTests,form },
    } = this.props;

    return (
      <div data-page="team-member-invite" className={styles.teamMemberInvite}>
        <div className={styles.loginOuterCont}>
          <div className={styles.loginBox}>
            <section className={styles.header}>
              <div className={styles.logo}>
                <KardiaProLogo />
              </div>
            </section>
            <section className={styles.content}>
              <div className={styles.contentInner}>
                <div className={styles.preamble}>
                  <p>Complete your account</p>
                </div>
                <div className={styles.inputField}>
                  <input
                    className={styles.defaultTextInput}
                    name="firstName"
                    type="text"
                    onKeyDown={handleKeyDown}
                    onChange={handleChange}
                    placeholder="First name"
                    value={form.firstName}
                  />
                </div>
                <div className={styles.inputField}>
                  <input
                    className={styles.defaultTextInput}
                    name="lastName"
                    type="text"
                    onKeyDown={handleKeyDown}
                    onChange={handleChange}
                    placeholder="Last name"
                    value={form.lastName}
                  />
                </div>
                <div className={styles.inputField}>
                  <input
                    className={styles.defaultTextInput}
                    name="password"
                    type="password"
                    onKeyDown={handleKeyDown}
                    onChange={handleChange}
                    placeholder="New password"
                    value={form.password}
                  />
                </div>
                {error && <h2 className={styles.errorMsg}>{errorMsg}</h2>}

                <div className={styles.validationWrapper}>
                  <ValidationRules validationTests={validationTests} />
                </div>
                <div>
                  {dataIsFetching ? (
                    <button className={styles.disabledButton}>
                      <div className={styles.loginLoading}>
                        <Loading />
                      </div>
                    </button>
                  ) : (
                    <button className={styles.signUpButton} onClick={submitForm}>
                      Complete sign up
                    </button>
                  )}
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default TeamMemberInvite;
