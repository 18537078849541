import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import styles from './ContentHeader.module.css';
import { ROUTES } from '../../constants/app';
import PatientIcon from '../../Assets/images/icons/add-plus.svg';
import BackIcon from '../../Assets/images/icons/chevron-left.svg';
import PageUpdated from '../PageUpdated/PageUpdated';

const ContentHeader = (props) => {
  const {
    showAddPatient = true,
    showCTA,
    ctaComponent: CTAComponent,
    text,
    getText,
    hasTabs,
    showLastRefresh = true,
    isLoading,
    lastUpdated,
    handleOnRefreshClick,
    enableBackButton = false,
    className,
    isFullWidth = false,
    me,
    goBackRoute = '',
    appointments,
  } = props;

  const headerClassNames = classNames({
    [styles.header]: !hasTabs,
    [styles.headerWithTabs]: hasTabs,
    [className]: className !== undefined,
  });
  const headerTextcn = enableBackButton ? styles.headerTextWithBack : styles.headerText;
  const widthController = isFullWidth
    ? styles.contentHeaderFullWidth
    : styles.contentHeaderLeftGroup;

  return (
    <header className={headerClassNames} style={{ marginBottom: '20px' }}>
      <div className={widthController}>
        {enableBackButton && (
          <Link to={goBackRoute} className={styles.back} data-action="content-header-back">
            <BackIcon />
          </Link>
        )}
        {text && !getText && (
          <h1 style={{ fontSize: '34px', fontFamily: 'Work Sans' }} className={headerTextcn}>
            {text}
            <span className={styles.headerLine} />
          </h1>
        )}
        {!text && getText && getText()}
      </div>
      <div className={styles.contentHeaderRightGroup}>
        {showCTA && CTAComponent}
        {showAddPatient && (
          <div data-action="add-patient">
            <Link to={ROUTES.PATIENTS_ADD_NEW_PATIENT} className={styles.addPatientBtn}>
              <PatientIcon />
              Add Patient
            </Link>
          </div>
        )}
        {showLastRefresh && (
          <PageUpdated
            isLoading={isLoading}
            lastUpdated={lastUpdated}
            handleOnClick={handleOnRefreshClick}
            hasTabs={hasTabs}
            me={me}
          />
        )}
      </div>
    </header>
  );
};

export default ContentHeader;
