import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import moment from 'moment';
const useStyle = makeStyles({
  headerLine: {
    width: '22px',
    height: '6px',
    borderRadius: '8px',
    backgroundColor: '#2D9F86',
    display: 'block',
    marginTop: '8px',
  },
});
function Header() {
  const history = useHistory();
  const backClick = () => {
    history.goBack();
  };
  const classes = useStyle();
  const { programData: data, loading, error } = useSelector((state) => state.memberProfile);
  return (
    <Box style={{ marginBottom: '20px' }}>
      {data && (
        <>
          <h1 style={{ fontSize: '20px', fontFamily: 'Work Sans' }}>
            <b>
              {data.participantFirstName} {data.participantLastName} |{' '}
              {`${moment().diff(data.participantDateOfBirth, 'years')} yrs`},{' '}
              {data.gender && data.gender[0].toUpperCase() + data.gender.substr(1)}{' '}
            </b>
            {/* todo- add gender here in the header */}
            <span className={classes.headerLine} />
          </h1>
          <p onClick={backClick} style={{ cursor: 'pointer' }}>
            <u>Back</u>
          </p>
        </>
      )}
    </Box>
  );
}

export default Header;
