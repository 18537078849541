import React from 'react';
import { Column } from 'react-virtualized';
import moment from 'moment';

import Table from '../components/Table';
import TableStyles from '../components/Table/Table.module.css';
import TableHeader from '../components/TableHeader';
import { DatePopover, SliderPopover, MultiSelectListPopover } from '../../Popovers';
import {
  getDateFormat,
  getTimeFormat,
  BLOOD_PRESSURE_TABLE_COLUMN_HEADERS,
  getBloodPressureSeverityDisplayText,
  getBloodPressureSeverityOptions,
  getBloodPressureSourceOptions,
  getBloodPressureSourceDisplayText,
  TABLE_CHECKBOX_WIDTH,
} from '../../../constants/metrics';
import { DATE_WIDTH } from '../Utilities';
import styles from './BloodPressureTable.module.css';

class BloodPressureTable extends React.Component {
  state = {
    selected: {},
  };

  static getDerivedStateFromProps(props, s) {
    const state = s;

    if (state.selected !== props.selected) {
      state.selected = props.selected;
    }

    return state;
  }

  dateFormat = getDateFormat(this.props.me);
  timeFormat = getTimeFormat(this.props.me);

  handleOnSelectionClick = (selected) => {
    const { onSelectionClick } = this.props;

    this.setState({
      selected,
    });

    if (typeof onSelectionClick === 'function') {
      onSelectionClick(selected);
    }
  };

  render() {
    const {
      noDataText = 'No recordings found.',
      enableLoader,
      showLoader,
      showRowSelection = false,
      data,
      width,
      onScroll,
      showPatientColumns,
      selectedIndex,
      sortBy,
      sortDirection,
      filterDate,
      filterPatient,
      filterSource,
      filterCategory,
      filterBpm,
      filterSystolic,
      filterDiastolic,
      handleOnSortClick,
      handleOnFilterButtonClick,
      handleOnFilterChange,
      handleOnOutsidePopoverClick,
      selectedPopover,

      selectedStartDate,
      selectedEndDate,
      dateRange,

      selectedSource,

      selectedSeverity,

      selectedBpmStartValue,
      selectedBpmEndValue,
      bpmRange,

      selectedSystolicStartValue,
      selectedSystolicEndValue,
      systolicRange,

      selectedDiastolicStartValue,
      selectedDiastolicEndValue,
      diastolicRange,
    } = this.props;
    const { selected } = this.state;

    const isAnySelected = Object.keys(selected).some((key) => selected[key] === true);

    const ROW_HEIGHT = 50;
    const PATIENT_WIDTH = 190;
    const BPM_WIDTH = 190;
    const SYSTOLIC_WIDTH = 190;
    const DIASTOLIC_WIDTH = 190;
    let remainingWidth = 1440 - DATE_WIDTH - BPM_WIDTH - SYSTOLIC_WIDTH - DIASTOLIC_WIDTH;
    remainingWidth = showPatientColumns ? remainingWidth - PATIENT_WIDTH : remainingWidth;

    if (showRowSelection) {
      remainingWidth -= TABLE_CHECKBOX_WIDTH;
    }

    const sourceWidth = remainingWidth / 2;
    const categoryWidth = remainingWidth / 2;

    return (
      <div className={styles.bloodPressureTable}>
        <Table
          noDataText={noDataText}
          enableLoader={enableLoader}
          showLoader={showLoader}
          showRowSelection={showRowSelection}
          // disableRowSelection={bulkActionComplete || bulkActionIsFetching}
          selected={selected}
          rowHeight={ROW_HEIGHT}
          uniqueKey="id"
          hasTableActions={isAnySelected}
          onSelectionClick={this.handleOnSelectionClick}
          onScroll={onScroll}
          scrollToIndex={selectedIndex}
          data={data}
          sortBy={sortBy}
          sortDirection={sortDirection}
          colorField="severityClass"
        >
          <Column
            className={TableStyles.column}
            label="Date"
            dataKey={BLOOD_PRESSURE_TABLE_COLUMN_HEADERS.RECORDED_AT}
            width={DATE_WIDTH}
            headerRenderer={(headerProps) => {
              const { label } = headerProps;

              return (
                <TableHeader
                  label={label}
                  columnHeader={BLOOD_PRESSURE_TABLE_COLUMN_HEADERS.RECORDED_AT}
                  handleOnSortClick={handleOnSortClick}
                  handleOnFilterButtonClick={handleOnFilterButtonClick}
                  filterActive={filterDate}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  selectedPopover={selectedPopover}
                  Popover={
                    <div
                      className={
                        showRowSelection ? styles.datePopoverWithSelection : styles.datePopover
                      }
                    >
                      <DatePopover
                        handleOnChange={(dates) =>
                          handleOnFilterChange(
                            BLOOD_PRESSURE_TABLE_COLUMN_HEADERS.RECORDED_AT,
                            dates,
                          )
                        }
                        handleOnOutsidePopoverClick={handleOnOutsidePopoverClick}
                        min={dateRange[0]}
                        max={dateRange[1]}
                        startDate={selectedStartDate}
                        endDate={selectedEndDate}
                      />
                    </div>
                  }
                />
              );
            }}
            cellRenderer={({ dataKey, rowData }) => {
              const date = moment(rowData[dataKey]).format(this.dateFormat);
              const time = moment(rowData[dataKey]).format(this.timeFormat);
              return (
                <div className={TableStyles.centeredColumnContent}>
                  <p className={`${TableStyles.primaryText} ${TableStyles.centeredText}`}>{date}</p>
                  <p className={`${TableStyles.secondaryText} ${TableStyles.centeredText}`}>
                    {time}
                  </p>
                </div>
              );
            }}
          />
          <Column
            className={TableStyles.column}
            label="Source"
            dataKey={BLOOD_PRESSURE_TABLE_COLUMN_HEADERS.SOURCE}
            width={sourceWidth}
            headerRenderer={(headerProps) => {
              const { label } = headerProps;

              return (
                <TableHeader
                  label={label}
                  columnHeader={BLOOD_PRESSURE_TABLE_COLUMN_HEADERS.SOURCE}
                  handleOnSortClick={handleOnSortClick}
                  handleOnFilterButtonClick={handleOnFilterButtonClick}
                  filterActive={filterSource}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  selectedPopover={selectedPopover}
                  Popover={
                    <div
                      className={
                        showRowSelection ? styles.sourcePopoverWithSelection : styles.sourcePopover
                      }
                    >
                      <MultiSelectListPopover
                        list={getBloodPressureSourceOptions()}
                        selected={selectedSource}
                        getOptionLabel={(d) => d.label}
                        getOptionValue={(d) => d.id}
                        enableSearch={false}
                        handleOnClick={(id) =>
                          handleOnFilterChange(BLOOD_PRESSURE_TABLE_COLUMN_HEADERS.SOURCE, id)
                        }
                        handleOnOutsidePopoverClick={handleOnOutsidePopoverClick}
                      />
                    </div>
                  }
                />
              );
            }}
            cellRenderer={({ dataKey, rowData }) => {
              const primaryText = getBloodPressureSourceDisplayText(rowData[dataKey]);

              return (
                <div className={TableStyles.columnContent}>
                  <p className={`${TableStyles.primaryText} ${TableStyles.centeredText}`}>
                    {primaryText}
                  </p>
                </div>
              );
            }}
          />
          <Column
            className={TableStyles.column}
            label="Category"
            dataKey={BLOOD_PRESSURE_TABLE_COLUMN_HEADERS.CATEGORY}
            width={categoryWidth}
            headerRenderer={(headerProps) => {
              const { label } = headerProps;

              return (
                <TableHeader
                  label={label}
                  columnHeader={BLOOD_PRESSURE_TABLE_COLUMN_HEADERS.CATEGORY}
                  handleOnSortClick={handleOnSortClick}
                  handleOnFilterButtonClick={handleOnFilterButtonClick}
                  filterActive={filterCategory}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  selectedPopover={selectedPopover}
                  Popover={
                    <div
                      className={
                        showRowSelection
                          ? styles.categoryPopoverWithSelection
                          : styles.categoryPopover
                      }
                    >
                      <MultiSelectListPopover
                        list={getBloodPressureSeverityOptions().slice(1)}
                        selected={selectedSeverity}
                        getOptionLabel={(d) => d.label}
                        getOptionValue={(d) => d.id}
                        enableSearch={false}
                        handleOnClick={(id) =>
                          handleOnFilterChange(BLOOD_PRESSURE_TABLE_COLUMN_HEADERS.CATEGORY, id)
                        }
                        handleOnOutsidePopoverClick={handleOnOutsidePopoverClick}
                      />
                    </div>
                  }
                />
              );
            }}
            cellRenderer={({ dataKey, rowData }) => {
              const primaryText = getBloodPressureSeverityDisplayText(rowData[dataKey]);
              return (
                <div className={TableStyles.columnContent}>
                  <p className={`${TableStyles.primaryText} ${TableStyles.centeredText}`}>
                    {primaryText}
                  </p>
                </div>
              );
            }}
          />
          <Column
            className={TableStyles.column}
            label="BPM"
            dataKey={BLOOD_PRESSURE_TABLE_COLUMN_HEADERS.BPM}
            width={BPM_WIDTH}
            headerRenderer={(headerProps) => {
              const { label } = headerProps;

              return (
                <TableHeader
                  label={label}
                  columnHeader={BLOOD_PRESSURE_TABLE_COLUMN_HEADERS.BPM}
                  handleOnSortClick={handleOnSortClick}
                  handleOnFilterButtonClick={handleOnFilterButtonClick}
                  filterActive={filterBpm}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  selectedPopover={selectedPopover}
                  Popover={
                    <div className={styles.bpmPopover}>
                      <SliderPopover
                        label="Select Range"
                        min={bpmRange[0]}
                        max={bpmRange[1]}
                        minValue={selectedBpmStartValue}
                        maxValue={selectedBpmEndValue}
                        handleOnChange={(selectedBpms) =>
                          handleOnFilterChange(
                            BLOOD_PRESSURE_TABLE_COLUMN_HEADERS.BPM,
                            selectedBpms,
                          )
                        }
                        handleOnOutsidePopoverClick={handleOnOutsidePopoverClick}
                      />
                    </div>
                  }
                />
              );
            }}
            cellRenderer={({ dataKey, rowData }) => {
              const primaryText = rowData[dataKey];
              return (
                <div className={TableStyles.columnContent}>
                  <p className={`${TableStyles.primaryText} ${TableStyles.centeredText}`}>
                    {primaryText}
                  </p>
                </div>
              );
            }}
          />
          <Column
            className={TableStyles.column}
            label="Systolic"
            dataKey={BLOOD_PRESSURE_TABLE_COLUMN_HEADERS.SYSTOLIC}
            width={SYSTOLIC_WIDTH}
            headerRenderer={(headerProps) => {
              const { label } = headerProps;

              return (
                <TableHeader
                  label={label}
                  columnHeader={BLOOD_PRESSURE_TABLE_COLUMN_HEADERS.SYSTOLIC}
                  handleOnSortClick={handleOnSortClick}
                  handleOnFilterButtonClick={handleOnFilterButtonClick}
                  filterActive={filterSystolic}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  selectedPopover={selectedPopover}
                  Popover={
                    <div className={styles.systolicPopover}>
                      <SliderPopover
                        label="Select Range"
                        min={systolicRange[0]}
                        max={systolicRange[1]}
                        minValue={selectedSystolicStartValue}
                        maxValue={selectedSystolicEndValue}
                        handleOnChange={(selectedSystolics) =>
                          handleOnFilterChange(
                            BLOOD_PRESSURE_TABLE_COLUMN_HEADERS.SYSTOLIC,
                            selectedSystolics,
                          )
                        }
                        handleOnOutsidePopoverClick={handleOnOutsidePopoverClick}
                      />
                    </div>
                  }
                />
              );
            }}
            cellRenderer={({ dataKey, rowData }) => {
              const primaryText = rowData[dataKey];
              return (
                <div className={TableStyles.columnContent}>
                  <p className={`${TableStyles.primaryText} ${TableStyles.centeredText}`}>
                    {primaryText}
                  </p>
                </div>
              );
            }}
          />
          <Column
            className={TableStyles.column}
            label="Diastolic"
            dataKey={BLOOD_PRESSURE_TABLE_COLUMN_HEADERS.DIASTOLIC}
            width={DIASTOLIC_WIDTH}
            headerRenderer={(headerProps) => {
              const { label } = headerProps;

              return (
                <TableHeader
                  label={label}
                  columnHeader={BLOOD_PRESSURE_TABLE_COLUMN_HEADERS.DIASTOLIC}
                  handleOnSortClick={handleOnSortClick}
                  handleOnFilterButtonClick={handleOnFilterButtonClick}
                  filterActive={filterDiastolic}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  selectedPopover={selectedPopover}
                  Popover={
                    <div className={styles.diastolicPopover}>
                      <SliderPopover
                        label="Select Range"
                        min={diastolicRange[0]}
                        max={diastolicRange[1]}
                        minValue={selectedDiastolicStartValue}
                        maxValue={selectedDiastolicEndValue}
                        handleOnChange={(selectedDiastolics) =>
                          handleOnFilterChange(
                            BLOOD_PRESSURE_TABLE_COLUMN_HEADERS.DIASTOLIC,
                            selectedDiastolics,
                          )
                        }
                        handleOnOutsidePopoverClick={handleOnOutsidePopoverClick}
                      />
                    </div>
                  }
                  lastElement
                />
              );
            }}
            cellRenderer={({ dataKey, rowData }) => {
              const primaryText = rowData[dataKey];
              return (
                <div className={TableStyles.columnContent}>
                  <p className={`${TableStyles.primaryText} ${TableStyles.centeredText}`}>
                    {primaryText}
                  </p>
                </div>
              );
            }}
          />
        </Table>
      </div>
    );
  }
}

export default BloodPressureTable;
