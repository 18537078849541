import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserMemberStatuses } from '../../../Store/actions/user';
import DashboardCard from './Card';

const CardBottomContainer = () => {
  const [localData, setLocalData] = useState(null);
  const { loading, data, error } = useSelector((state) => state.user.statusCount);

  useEffect(() => {
    if (!loading && !error) {
      setLocalData(data);
    }
  }, [data]);
  return (
    <>
      <Grid container spacing={4} style={{ marginBottom: '16px' }}>
        <Grid item xs={6}>
          <DashboardCard
            title="New Members"
            content={(loading || error) ? '-' : localData?.new}
            color={'#6BC077'}
          />
        </Grid>
        <Grid item xs={6}>
          <DashboardCard
            title="Noncompliant"
            content={(loading || error) ? '-' : localData?.noncompliant}
            color={'#FDC246'}
          />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <DashboardCard
            title="Unscheduled"
            content={(loading || error) ? '-' : localData?.unscheduled}
            color={'#FDC246'}
          />
        </Grid>
        <Grid item xs={6}>
          <DashboardCard
            title="Inactive"
            content={(loading || error) ? '-' : localData?.inactive}
            color={'#CC3D3F'}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CardBottomContainer;
