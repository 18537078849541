
export const tableHead = [
    {
      id: 'scheduledStartTime',
      label: 'Date',
    },
    {
      id: 'sessionLogTime',
      label: 'Time',
    },
    {
      id: 'sessionLogStatus',
      label: 'Status',
    },
    {
      id: 'sessionLogDuration',
      label: 'Duration',
    },
    {
      id: 'sessionLogType',
      label: 'Type of Session',
    },
    {
      id: 'sessionLogMethods',
      label: 'Methods',
    },
    {
      id: 'sessionLogNotes',
      label: 'Notes',
    },
  ];

  export const adminTableHead = [
    {
      id: 'startdatetime',
      label: 'Date',
    },
    {
      id: 'sessionLogTime',
      label: 'Time',
    },
    {
      id: 'sessionLogStatus',
      label: 'Status',
    },
    {
      id: 'sessionLogDuration',
      label: 'Duration',
    },
    {
      id: 'sessionLogType',
      label: 'Type of Session',
    },
    {
      id: 'sessionLogMethods',
      label: 'Methods',
    },
    {
      id: 'sessionLogNotes',
      label: 'Notes',
    },
  ];