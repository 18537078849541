import MemberProfile from './MemberProfile';
import { connect } from 'react-redux';
import { tableHead, adminTableHead} from './components/SessionLog/data';
import { getMemberProfileSessionLogData, upLoadFiles, uploadFileSuccess } from '../../Store/actions'

const mapActionCreators = {
    getMemberProfileSessionLogData,
    upLoadFiles,
    uploadFileSuccess
};

const mapStateToProps = (state) => ({
    tableHead,
    adminTableHead,
    getMemberProfileSessionLogData: getMemberProfileSessionLogData
});

export default connect(mapStateToProps, mapActionCreators)(MemberProfile);
