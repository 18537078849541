import React from 'react'

import Popover from '../components/Popover'
import MultiSelectSearchDropdown from '../../KardiaProComponents/MultiSelectSearchDropdown'
import styles from './MultiSelectListPopover.module.css'

const ListPopover = props => {
  const {
    list,
    selected,
    enableSearch,
    handleOnClick,
    handleOnOutsidePopoverClick,
    getOptionLabel,
    getOptionValue,
    className = styles.popoverSelect,
    controlShouldRenderValue,
    backspaceRemovesValue,
    onKeyDown,
  } = props

  return (
    <Popover
      className={styles.multiSelectListPopover}
      onOutsidePopoverClick={handleOnOutsidePopoverClick}
    >
      <MultiSelectSearchDropdown
        value={selected}
        options={list}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        handleOnSelectionClick={handleOnClick}
        enableSearch={enableSearch}
        className={`${className} interpretationSelect`}
        controlShouldRenderValue={controlShouldRenderValue}
        backspaceRemovesValue={backspaceRemovesValue}
        onKeyDown={onKeyDown}
      />
    </Popover>
  )
}

export default ListPopover
