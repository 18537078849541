/* eslint-disable */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
// Rheostat specific css
import 'rheostat/initialize';
import '!style-loader!css-loader!rheostat/css/rheostat.css';
import '!style-loader!css-loader!./thirdPartyCssOverrides.css';
import '!style-loader!css-loader!react-date-range/dist/styles.css';
import '!style-loader!css-loader!react-date-range/dist/theme/default.css';
import * as serviceWorker from './serviceWorker';
import rootReducer from './Store/reducers';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import './i18n';

import AppCoach from './AppCoach/index';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  checkAuthentication,
  clearIntervalAuthenticationChecker,
  idleTimeUpdater,
  intervalAuthenticationChecker,
} from './Auth/AuthUtilities';
import history from './Components/History';

const composeEnhancers = process.env.NODE_ENV === 'development' ? composeWithDevTools : compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(idleTimeUpdater, reduxThunk)),
);

if (store.dispatch(checkAuthentication())) {
  store.dispatch(intervalAuthenticationChecker());
}

if (module.hot) {
  module.hot.accept();
}

  ReactDOM.render(
    <div>
      <Provider store={store}>
        <AppCoach history={history} userstate={{ data: { profile: null, teams: null } }} />
      </Provider>
    </div>,
    document.getElementById('root'),
  );
  serviceWorker.unregister();


export { store };
