/* eslint-disable */

import { style } from 'd3'
import React from 'react'

import Popover from '../../Popovers/components/Popover'
import CloseIcon from '../../../Assets/images/icons/cancel.svg'
import styles from './DescriptiveToolTip.css'

export const DescriptiveToolTip = props => {
  const {
    infoHeaderContent,
    handleClose,
    cssForEnroll,
  } = props
  const Body = props.bodyContent
  return (
    <Popover className={ !cssForEnroll ? styles.popoverToolTipWraper :styles.popoverToolTipWraperForEnrolltable}>
      <div className={styles.headerInfo}>{infoHeaderContent}</div>
      <a
        className={ !cssForEnroll ? styles.popoverClose : styles.popoverCloseEnroll}
        href="#"
        onClick={e => {
          handleClose(e)
        }
        }
      >
        <CloseIcon />
      </a>
      <div className={ !cssForEnroll ? styles.popoverToolTipContent : styles.popoverToolTipContentEnroll}>
        <Body />
      </div>
    </Popover>
  )
}

export default DescriptiveToolTip
