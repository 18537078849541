import React from 'react'
import classes from './YGrid.module.css'

export const YGrid = props => {
  const {
    yTicks,
    returnY,
    chartWidth,
    paddingLeft,
    paddingTop,
  } = props

  const renderYGrid = () => yTicks.map((yTick, i) => {
    const yCoord = returnY(yTick)
    return (
      <line
        key={i}
        className={classes.yGrid}
        x1={0}
        y1={returnY(yTicks[i])}
        x2={chartWidth}
        y2={returnY(yTicks[i])}
      />
    )
  })

  return (
    <g
      className={classes.yGrid}
      transform={`translate(${paddingLeft}, ${paddingTop})`}
    >
      {renderYGrid()}
    </g>
  )
}

export default YGrid
