import {
  SORT_TYPES,
  ENROLLMENT_CONNECTION_STATUSES,
  KARDIA_DETERMINATIONS,
} from '../../constants/app';

export const getLocaleCompareOptions = () => ({
  numeric: false,
  sensitivity: 'variant',
  ignorePunctuation: true,
});

export const getMrnFromRecordingParticipantProfile = (rec) => {
  let mrn = '';

  if (rec.participantProfile && rec.participantProfile.customParticipantID) {
    mrn = rec.participantProfile.customParticipantID;
  }

  return mrn;
};

export const getPatientNameFromRecordingParticipantProfile = (rec) => {
  let name = '';

  if (rec.participantProfile) {
    const p = rec.participantProfile;
    name = getPatientName(p.firstName, p.lastName, '');
  }

  return name;
};

export const getAnalysisBy = (rec) => {
  let analysisBy =
    rec.algorithmPackage === null
      ? KARDIA_DETERMINATIONS.KAIv1
      : KARDIA_DETERMINATIONS[rec.algorithmPackage];

  if (rec.newestInterpretation && rec.newestInterpretation.confirmation) {
    const { firstName, lastName } = rec.newestInterpretation.confirmation;
    analysisBy = getPhysicianName(firstName, lastName);
  } else if (rec.newestInterpretation && !rec.newestInterpretation.confirmation) {
    const { firstName, lastName } = rec.newestInterpretation.member;
    analysisBy = getPhysicianName(firstName, lastName);
  }

  return analysisBy;
};

export const isConfirmedAnalysisBy = (rec) =>
  rec.newestInterpretation && rec.newestInterpretation.confirmation;

export const StandardCompare = {
  strings: (a = '', b = '', direction = SORT_TYPES.ASC) => {
    let first = a;
    let second = b;

    if (first === null) {
      return direction === SORT_TYPES.ASC ? 1 : -1;
    }

    if (second === null) {
      return direction === SORT_TYPES.ASC ? -1 : 1;
    }

    first = first.trim();
    second = second.trim();

    if (first.length === 0) {
      return direction === SORT_TYPES.ASC ? 1 : -1;
    }

    if (second.length === 0) {
      return direction === SORT_TYPES.ASC ? -1 : 1;
    }

    if (direction === SORT_TYPES.DESC) {
      return second.localeCompare(first, 'en', getLocaleCompareOptions());
    }

    // default ASC
    return first.localeCompare(second, 'en', getLocaleCompareOptions());
  },
  dates: (a = null, b = null, direction = SORT_TYPES.ASC) => {
    if (a === null) {
      return direction === SORT_TYPES.ASC ? 1 : -1;
    }
    if (b === null) {
      return direction === SORT_TYPES.ASC ? -1 : 1;
    }

    const first = new Date(a);
    const second = new Date(b);

    if (direction === SORT_TYPES.DESC) {
      return second - first;
    }

    // default ASC
    return first - second;
  },
  tableStringFilter: (a = null, b = null) => {
    if (a === null || b === null) {
      return false;
    }

    return a.toLowerCase() === b.toLowerCase();
  },
  tableNumberRangeFilter: (
    nums = {
      values: {
        min: null,
        current: null,
        max: null,
      },
      limits: {
        min: Number.NEGATIVE_INFINITY,
        max: Number.POSITIVE_INFINITY,
      },
    },
  ) => {
    if (nums.values.min === nums.limits.min && nums.values.max !== nums.limits.max) {
      return nums.values.current <= nums.values.max;
    } else if (nums.values.min !== nums.limits.min && nums.values.max === nums.limits.max) {
      return nums.values.current >= nums.values.min;
    }

    return nums.values.current >= nums.values.min && nums.values.current <= nums.values.max;
  },
};

export const getPendingOrConnectedEnrollmentsByEndDate = (enrollments = [], enrollmentByIds = {}) =>
  enrollments
    .sort((c, d) =>
      StandardCompare.dates(
        enrollmentByIds[c].endDate,
        enrollmentByIds[d].endDate,
        SORT_TYPES.DESC,
      ),
    )
    .map((c) => enrollmentByIds[c])
    .filter(
      (c) =>
        c.connectionStatus === ENROLLMENT_CONNECTION_STATUSES.CONNECTED ||
        c.connectionStatus === ENROLLMENT_CONNECTION_STATUSES.PENDING,
    );

export const getWelcomeName = (firstName, lastName) => {
  const firstLetter = firstName ? firstName.charAt(0).toUpperCase() : '';
  return `${firstLetter}. ${lastName}`;
};

export const getPhysicianName = (firstName, lastName, fallbackField) => {
  const firstLetter = firstName ? firstName.charAt(0).toUpperCase() : '';
  return getName(firstLetter, lastName, fallbackField);
};

export const getPatientName = (firstName, lastName, fallbackField) =>
  getName(firstName, lastName, fallbackField);

export const getNameIdtf = (firstName, lastName, middleName, fallbackField) => {
  let result = '';

  if (firstName && lastName && middleName) {
    result = `${lastName}, ${middleName}, ${firstName}`;
  } else if (firstName && lastName && !middleName) {
    result = `${lastName}, ${firstName}`;
  } else if (firstName && !lastName && !middleName) {
    result = firstName;
  } else if (!firstName && !middleName && lastName) {
    result = lastName;
  } else if (!firstName && middleName && !lastName) {
    result = middleName;
  } else {
    result = fallbackField;
  }

  return result;
};

export const getName = (firstName, lastName, fallbackField) => {
  let result = '';

  if (firstName && lastName) {
    result = `${lastName}, ${firstName}`;
  } else if (firstName && !lastName) {
    result = firstName;
  } else if (!firstName && lastName) {
    result = lastName;
  } else {
    result = fallbackField;
  }

  return result;
};

export const getOrderingProviderName = (firstName, lastName, fallbackField) => {
  let result = '';

  if (firstName && lastName) {
    result = `Dr. ${firstName} ${lastName}`;
  } else if (firstName && !lastName) {
    result = `Dr. ${firstName}`;
  } else if (!firstName && lastName) {
    result = `Dr. ${lastName}`;
  } else {
    result = fallbackField;
  }

  return result;
};

export const getPrescriptionOptionLabel = (p) => {
  let result = '';

  if (p.name) {
    result = p.name;
  } else {
    // No good fallback, using last 7 character of id
    // TODO: Figure out what is the best fallback
    if (p.id) {
      result = `Prescription: ${p.id.substr(p.id.length - 7)}`;
    }
  }

  return result;
};

export const generateQueryParam = (key, value) =>
  `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;

export const makeSimilarReactRouterLocationObject = (location) => ({
  // key: 'ac3df4', // not with HashHistory!
  pathname: location.pathname,
  search: location.search,
  hash: location.hash,
  // state: {
  //   [userDefined]: true
  // }
});

// https://github.com/alivecor/AliveECG-iOS/blob/19c91e234e115089364230a9bb93502fa56b13cd/AliveECG/Classes/Utils.m#L482
const POUNDS_KG_CONVERSION_RATE = 2.2046226218;
export const poundsFromKg = (kgs = 0) => kgs * POUNDS_KG_CONVERSION_RATE;
export const kgFromPounds = (pounds = 0) => pounds / POUNDS_KG_CONVERSION_RATE;

export const getWeightFormat = (weight = 0, me) =>
  me.data.teams[0].useImperialSystem ? poundsFromKg(weight) : weight;

export const BMIForWeight = (weightKg = 0, heightCm = 0) =>
  heightCm > 0 ? weightKg / (heightCm / 100) ** 2 : 0;

export const BMI_SEVERITY_TYPES = {
  ALL_BMI: null,
  NORMAL: 'NORMAL',
  UNDERWEIGHT: 'UNDERWEIGHT',
  OVERWEIGHT: 'OVERWEIGHT',
  OBESE: 'OBESE',
};

export const BMI_SEVERITY_CLASSNAMES = {
  NORMAL: 'bmiNormal',
  UNDERWEIGHT: 'bmiUnderweight',
  OVERWEIGHT: 'bmiOverweight',
  OBESE: 'bmiObese',
};

export const getBmiSeverityOrder = () => [
  BMI_SEVERITY_TYPES.ALL_BMI,
  BMI_SEVERITY_TYPES.NORMAL,
  BMI_SEVERITY_TYPES.UNDERWEIGHT,
  BMI_SEVERITY_TYPES.OVERWEIGHT,
  BMI_SEVERITY_TYPES.OBESE,
];

// https://github.com/alivecor/AliveECG-iOS/blob/d907415f544d13b99eb360c66da038c822340930/AliveECG/Classes/KDMWeight.m#L62
export const classifyBmiSeverity = (bmi = 0) => {
  let severity;

  if (bmi >= 30) {
    severity = BMI_SEVERITY_TYPES.OBESE;
  } else if (bmi >= 25) {
    severity = BMI_SEVERITY_TYPES.OVERWEIGHT;
  } else if (bmi >= 18.5) {
    severity = BMI_SEVERITY_TYPES.NORMAL;
  } else {
    severity = BMI_SEVERITY_TYPES.UNDERWEIGHT;
  }

  return severity;
};

export const getBmiSeverityClassName = (bmiSeverity) => {
  let colorClassName = '';

  switch (bmiSeverity) {
    case BMI_SEVERITY_TYPES.NORMAL:
      colorClassName = BMI_SEVERITY_CLASSNAMES.NORMAL;
      break;
    case BMI_SEVERITY_TYPES.UNDERWEIGHT:
      colorClassName = BMI_SEVERITY_CLASSNAMES.UNDERWEIGHT;
      break;
    case BMI_SEVERITY_TYPES.OVERWEIGHT:
      colorClassName = BMI_SEVERITY_CLASSNAMES.OVERWEIGHT;
      break;
    case BMI_SEVERITY_TYPES.OBESE:
      colorClassName = BMI_SEVERITY_CLASSNAMES.OBESE;
      break;
    default:
      break;
  }

  return colorClassName;
};

export const getBmiSeverityDisplayText = (bmiSeverity) => {
  let displayText = '';
  switch (bmiSeverity) {
    case BMI_SEVERITY_TYPES.ALL_BMI:
      displayText = 'All BMI Severities';
      break;
    case BMI_SEVERITY_TYPES.NORMAL:
      displayText = 'Normal';
      break;
    case BMI_SEVERITY_TYPES.UNDERWEIGHT:
      displayText = 'Underweight';
      break;
    case BMI_SEVERITY_TYPES.OVERWEIGHT:
      displayText = 'Overweight';
      break;
    case BMI_SEVERITY_TYPES.OBESE:
      displayText = 'Obese';
      break;
    default:
      break;
  }
  return displayText;
};

export const getBmiCounts = (weightRecordings = []) => {
  const lookupTable = getBmiSeverityOrder()
    .slice(1)
    .reduce((acc, severityType) => {
      acc[severityType] = 0;
      return acc;
    }, {});

  weightRecordings.forEach((rec) => {
    lookupTable[rec.severity] += 1;
  });

  return lookupTable;
};

export const getWeightSeverityOptions = () =>
  getBmiSeverityOrder().map((id) => ({
    id,
    label: getBmiSeverityDisplayText(id),
  }));

export const WEIGHT_SOURCES = {
  // iOS manual
  MANUAL: 'manual',
  // Android manual
  KARDIA_MOBILE: 'kardia mobile',

  HEALTH_KIT: 'healthkit',
};

export const getWeightSourcesOrder = () => [WEIGHT_SOURCES.MANUAL, WEIGHT_SOURCES.HEALTH_KIT];

export const getWeightSourceDisplayText = (weightSource) => {
  let displayText = '';
  switch (weightSource.toLowerCase()) {
    case WEIGHT_SOURCES.KARDIA_MOBILE:
    case WEIGHT_SOURCES.MANUAL:
      displayText = 'Manual Entry';
      break;
    case WEIGHT_SOURCES.HEALTH_KIT:
      displayText = 'HealthKit';
      break;
    default:
      break;
  }
  return displayText;
};

export const getWeightSourceDisplayTextFromRecording = (recording) => {
  let displayText = '';
  switch (recording.source.toLowerCase()) {
    case WEIGHT_SOURCES.KARDIA_MOBILE:
    case WEIGHT_SOURCES.MANUAL:
      displayText = 'Manual Entry';
      break;
    default:
      if (recording.isHealthkit) {
        displayText = 'HealthKit';
      }
      break;
  }
  return displayText;
};

export const getWeightSourceOptions = () =>
  getWeightSourcesOrder().map((id) => ({
    id,
    label: getWeightSourceDisplayText(id),
  }));

export const isEscKey = (e) => {
  const key = e.key || e.keyCode;
  // console.log(e.keyCode)
  return key === 'Escape' || key === 'Esc' || key === 27;
};
