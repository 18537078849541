import React, { useCallback, useEffect, useState } from 'react';
import {
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Popover,
  mekeStyles,
  makeStyles,
  Button,
  Select,
  FormGroup,
  FormControlLabel,
  Checkbox,
  withStyles,
  Typography,
} from '@material-ui/core';
import MuiTypography from '../../../Components/Shared/Typography/MuiTypography';
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { green } from '@material-ui/core/colors';
import { useDispatch, useSelector } from 'react-redux';
import { filterAction } from '../../../Store/actions';
import { deProgramMap } from '../../../Utilities/Constants';

const useStyles = makeStyles({
  paddingTopmainmenu: { marginTop: '5px', background: 'white' },
  chipfont: {
    fontFamily: 'Work Sans !important',
    color: '#000000 !important',
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '-0.2px',
    fontWeight: 400,
  },
  popper: { display: 'flex', justifyContent: 'space-evenly', alignContent: 'center' },
  popovergrid2: { padding: '5px', height: 'auto', background: 'white', width: 'auto' },
  formControlchip2: {
    background: '#FFFFFF',
    marginLeft: '5px',
    marginRight: '5px',
    marginBottom: '5px',
    // minHeight: 280,
    width: 'auto',
    height: 'auto',
  },
  formControllabelstyle2: {
    marginLeft: '5px',
    marginRight: '5px',
    fontFamily: 'Work Sans !important',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: 'rgba(20, 42, 57, 0.72)',
  },
  buttonstyle: { marginBottom: '10px', color: '#F25F5F' },
  filterTitle: {
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#142A39',
  },
});
const GreenCheckbox = withStyles({
  root: {
    color: '#DADADA',
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function Filter(props) {
  const { memberFilters, isAdmin, coachId, teamId, page, pageLimit, setfilterString, sortColumn } =
    props;
  const classes = useStyles();
  const { t } = useTranslation();
  const savedFilterData = useSelector((state) => state.memberList.memberListFilter);
  // console.log('SAVED FILTER DATA ', savedFilterData);
  const [anchorE2, setAnchorE2] = useState(null);
  const [chipArray, setchipArray] = useState(null);

  const makeInitData = () => {
    let initData = {};
    initData.status = {
      name: 'all-status',
      checked: false,
      type: 'status',
    };
    initData.bloodPressure = {
      name: 'all-bloodPressure',
      checked: false,
      type: 'bloodPressure',
    };
    initData.program = {
      name: 'all-program',
      checked: true,
      type: 'program',
    };
    initData.sponsor = {
      name: 'all-sponsor',
      checked: true,
      type: 'sponsor',
    };
    initData.coach = {
      name: 'all-coach',
      checked: false,
      coachId: '',
      type: 'coach',
    };
    memberFilters.coach.map((m) => {
      initData[m.id] = {
        name: m.name,
        checked: false,
        type: 'coach',
        coachId: m.coachId,
      };
    });
    memberFilters.status.map((m) => {
      initData[m.id] = {
        name: m.name,
        checked: false,
        type: 'status',
      };
    });
    memberFilters.bloodPressure.map((m) => {
      initData[m.id] = {
        name: m.name,
        checked: false,
        type: 'bloodPressure',
      };
    });
    memberFilters.program.map((m) => {
      initData[m.id] = {
        name: m.name,
        checked: true,
        type: 'program',
      };
    });
    memberFilters.sponsor.map((m) => {
      initData[m.id] = {
        name: m.name,
        checked: true,
        type: 'sponsor',
      };
    });
    //this is for all check

    return initData;
  };
  //initialising data
  useEffect(() => {
    if (Object.keys(memberFilters) && Object.keys(memberFilters).length > 0) {
      let data = makeInitData();
      setchipArray(data);
    }
  }, [memberFilters]);

  // loading from redux
  useEffect(() => {
    if (savedFilterData) {
      setchipArray(savedFilterData);
    }
  }, [savedFilterData]);

  const popoveropen2 = Boolean(anchorE2);
  const popoverid2 = popoveropen2 ? 'simple-popover' : undefined;

  const handlepopover2openClick = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handlepopovercloseclick = () => {
    setAnchorE2(null);
  };

  const handlepopoverresetclick2 = useCallback(() => {
    setchipArray(makeInitData());
  }, [chipArray]);

  const dispatch = useDispatch();
  const onsubmitfilter = useCallback(() => {
    props.setPage(0)
    setAnchorE2(false);
    const filteredArray = Object.values(chipArray).filter((item) => {
      //checking which all filters are checked.
      return item.checked === true;
    });
    const filterList = filteredArray.reduce((acc, cur) => {
      //fomrmating the filter data which backend will understand.
      let { type, name } = cur;
      if (type === 'coach') {
        return { ...acc, [type]: [...(acc[type] || []), cur.coachId] };
      } else if (type == 'program') {
        return { ...acc, [type]: [...(acc[type] || []), deProgramMap[name]] };
      }
      {
        return { ...acc, [type]: [...(acc[type] || []), name] };
      }
    }, {});
    setfilterString(filterList);
    let pageToStartFrom = 0;
    dispatch(
      filterAction(
        chipArray,
        isAdmin,
        coachId,
        teamId,
        pageToStartFrom,
        pageLimit,
        sortColumn,
        filterList,
      ),
    );
  }, [chipArray]);

  const data = ['program', 'sponsor', 'status', 'bloodPressure', 'coach'];
  const removetag = (value) => {
    props.setPage(0)
    let temp = { ...chipArray };
    temp[value].checked = false;
    if (data.includes(value)) {
      let typeOfFilter = temp[value].type; //blood
      Object.values(temp).map((f) => {
        if (f.type === typeOfFilter) {
          f.checked = false;
        }
      });
    }
    setchipArray(temp);

    //======= execute filter if pills are removerd =======//
    const filteredArray = Object.values(temp).filter((item) => {
      return item.checked === true;
    });
    const filterList = filteredArray.reduce((acc, cur) => {
      let { type, name } = cur;
      if (type === 'coach') {
        return { ...acc, [type]: [...(acc[type] || []), cur.coachId] };
      } else if (type == 'program') {
        return { ...acc, [type]: [...(acc[type] || []), deProgramMap[name]] };
      }
      {
        return { ...acc, [type]: [...(acc[type] || []), name] };
      }
    }, {});
    setfilterString(filterList);
    dispatch(filterAction(temp, isAdmin, coachId, teamId, page, pageLimit, sortColumn, filterList));
    ///================================//
  };

  const handleChange = (value, event) => {
    let temp = { ...chipArray };
    temp[value.id] = {
      name: value.name,
      checked: event.target.checked,
      type: value.type,
      coachId: value.coachId,
    };
    let typeOfFilter = value.type; //blood
    let temp2 = Object.values(temp).filter((f) => f.type === typeOfFilter);
    let flag = true;
    temp2.map((t) => {
      if (!t.checked && !t.name.includes('all-')) {
        flag = false;
      }
    });
    if (flag) {
      if (typeOfFilter === 'coach') {
        handleOncheckedHandle(temp, typeOfFilter, value, true);
      } else {
        handleOncheckedHandle(temp, typeOfFilter, value, true);
      }
    } else {
      if (typeOfFilter === 'coach') {
        handleOncheckedHandle(temp, typeOfFilter, value, false);
      } else {
        handleOncheckedHandle(temp, typeOfFilter, value, false);
      }
    }

    setchipArray(temp);
  };

  const handleOncheckedHandle = (temp, typeOfFilter, value, checked) => {
    temp[typeOfFilter] = {
      name: `all-${typeOfFilter}`,
      checked: checked,
      type: value.type,
      coachId: value.coachId,
    };
    return temp;
  };

  const showAllIfAny = () => {
    let temp = { ...chipArray };

    return data.map((d) => {
      if (temp[d].checked) {
        return (
          <>
            <Chip
              variant="outlined"
              className={classes.chipfont}
              key={temp[d].id}
              label={
                temp[d].name === 'all-status'
                  ? `Status - All`
                  : temp[d].name === 'all-bloodPressure'
                  ? `Blood Pressure - All`
                  : temp[d].name === 'all-sponsor'
                  ? `Sponsor - All`
                  : temp[d].name === 'all-program'
                  ? `Program - All`
                  : `Coach - All`
              }
              onDelete={() => removetag(d)}
            />
            &nbsp;
          </>
        );
      }
    });
  };
  const showChip = (value) => {
    let typeOfFilter = value[1].type; //blood
    let temp = { ...chipArray };

    let allTrue = temp[typeOfFilter].checked;
    if (value[1].checked === true && !allTrue) {
      return (
        <>
          <Chip
            variant="outlined"
            className={classes.chipfont}
            key={value[0]}
            label={
              value[0].includes('status')
                ? `Status - ${value[1].name}`
                : value[0].includes('blood')
                ? `Blood Pressure - ${value[1].name}`
                : value[0].includes('sponsor')
                ? `Sponsor - ${value[1].name}`
                : `Program - ${value[1].name}`
            }
            onDelete={() => removetag(value[0])}
          />
          &nbsp;
        </>
      );
    }
  };
  return (
    <>
      <Grid
        alignItems="flex-start"
        spacing={0}
        className={classes.paddingTopmainmenu}
        container
        justify="space-between"
        style={{ display: '-ms-flexbox', background: '#fff' }}
      >
        <Grid item>
          <Grid
            alignItems="center"
            spacing={2}
            container
            justify="flex-start"
            style={{ margin: '5px' }}
          >
            <Grid item>
              <MuiTypography
                fontFamily="Work Sans"
                fontSize="16px"
                fonStyle="normal"
                fontWeight={600}
                lineHeight="20px"
                color="#142A39"
              >
                Filter
              </MuiTypography>
            </Grid>
            <Grid item>
              <IconButton onClick={handlepopover2openClick} aria-label="Search">
                <FilterListIcon />
              </IconButton>
            </Grid>
            <Grid item>
              {chipArray &&
                Object.entries(chipArray).map((value) => {
                  return showChip(value);
                })}
              {chipArray && showAllIfAny()}
            </Grid>
          </Grid>
          <Popover
            id={popoverid2}
            open={popoveropen2}
            anchorEl={anchorE2}
            className={classes.popper}
            modifiers={{
              preventOverflow: {
                enabled: false,
                boundariesElement: 'window',
              },
              arrow: {
                enabled: true,
                //element: arrowRef,
              },
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {/* <Paper> */}
            {/* <Paper  className={classes.popoverRoot}>
                   <span className={classes.arrow} ref={setArrowRef} />
                   </Paper> */}
            {/* <Box mt={5} /> */}
            <Grid
              item
              container
              justify="flex-end"
              alignItems="flex-end"
              direction="row"
              spacing={2}
            >
              <Grid item>
                <IconButton aria-label="close" onClick={handlepopovercloseclick}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            {Object.keys(memberFilters) && Object.keys(memberFilters).length > 0 && (
              <Grid
                container
                justify="space-between"
                direction="row"
                className={classes.popovergrid2}
              >
                {isAdmin && (
                  <Grid item>
                    <InputLabel className={classes.formControllabelstyle2}>
                      <Typography variant="body1" className={classes.filterTitle}>
                        Coach
                      </Typography>
                    </InputLabel>
                    <FormControl variant="outlined" className={classes.formControlchip2}>
                      {chipArray &&
                        memberFilters.coach.map((item) => {
                          return (
                            <FormGroup column key={item.id}>
                              <FormControlLabel
                                control={
                                  <GreenCheckbox
                                    onChange={(e) => handleChange(item, e)}
                                    name={item.name}
                                    checked={chipArray[item.id].checked}
                                  />
                                }
                                label={item.name}
                              />
                            </FormGroup>
                          );
                        })}
                    </FormControl>
                  </Grid>
                )}
                <Grid item>
                  <InputLabel className={classes.formControllabelstyle2}>
                    <Typography variant="body1" className={classes.filterTitle}>
                      Status
                    </Typography>
                  </InputLabel>
                  <FormControl variant="outlined" className={classes.formControlchip2}>
                    {chipArray &&
                      memberFilters.status.map((item) => {
                        return (
                          <FormGroup column key={item.id}>
                            <FormControlLabel
                              control={
                                <GreenCheckbox
                                  onChange={(e) => handleChange(item, e)}
                                  name={item.name}
                                  checked={chipArray[item.id].checked}
                                />
                              }
                              label={item.name}
                            />
                          </FormGroup>
                        );
                      })}
                  </FormControl>
                </Grid>

                <Grid item>
                  <InputLabel className={classes.formControllabelstyle2}>
                    <Typography variant="body1" className={classes.filterTitle}>
                      Blood Pressure
                    </Typography>
                  </InputLabel>
                  <FormControl variant="outlined" className={classes.formControlchip2}>
                    {chipArray &&
                      memberFilters.bloodPressure.map((item) => {
                        return (
                          <FormGroup column key={item.id}>
                            <FormControlLabel
                              control={
                                <GreenCheckbox
                                  onChange={(e) => handleChange(item, e)}
                                  name={item.name}
                                  checked={chipArray[item.id].checked}
                                />
                              }
                              label={item.name}
                            />
                          </FormGroup>
                        );
                      })}
                  </FormControl>
                </Grid>
                <Grid item>
                  <InputLabel className={classes.formControllabelstyle2}>
                    <Typography variant="body1" className={classes.filterTitle}>
                      {' '}
                      Program Type
                    </Typography>
                  </InputLabel>
                  <FormControl variant="outlined" className={classes.formControlchip2}>
                    {chipArray &&
                      memberFilters.program.map((item) => {
                        return (
                          <FormGroup column key={item.id}>
                            <FormControlLabel
                              control={
                                <GreenCheckbox
                                  onChange={(e) => handleChange(item, e)}
                                  name={item.name}
                                  checked={chipArray[item.id].checked}
                                />
                              }
                              label={item.name}
                            />
                          </FormGroup>
                        );
                      })}
                  </FormControl>
                </Grid>

                <Grid item>
                  <InputLabel className={classes.formControllabelstyle2}>
                    <Typography variant="body1" className={classes.filterTitle}>
                      Sponsor
                    </Typography>
                  </InputLabel>
                  <FormControl variant="outlined" className={classes.formControlchip2}>
                    {chipArray &&
                      memberFilters.sponsor.map((item) => {
                        return (
                          <FormGroup column key={item.id}>
                            <FormControlLabel
                              control={
                                <GreenCheckbox
                                  onChange={(e) => handleChange(item, e)}
                                  name={item.name}
                                  checked={chipArray[item.id].checked}
                                />
                              }
                              label={item.name}
                            />
                          </FormGroup>
                        );
                      })}
                  </FormControl>
                </Grid>
                <Grid
                  item
                  container
                  justify="flex-end"
                  alignItems="flex-end"
                  direction="row"
                  spacing={2}
                >
                  <Grid item>
                    <Button className={classes.buttonstyle} onClick={handlepopoverresetclick2}>
                      <MuiTypography
                        fontSize="14px"
                        lineHeight="16.42px"
                        fontFamily="Work Sans"
                        letterSpacing="0.75px"
                        fontWeight={700}
                        color="#2D9F86"
                      >
                        {t('CLEAR ALL')}
                      </MuiTypography>
                    </Button>
                  </Grid>
                  <Grid item>
                    <div className="telekardia">
                      <button
                        type="button"
                        className="btn m-1 btn_teal py-2 px-4"
                        onClick={onsubmitfilter}
                      >
                        {t('APPLY')}
                      </button>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {/* </Paper> */}
          </Popover>
        </Grid>
      </Grid>
    </>
  );
}

export default Filter;
