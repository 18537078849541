/* eslint-disable */
import * as actionTypes from './actionTypes';
import * as API from '../../Services/API/actions';
import { GoAPI, TeleConnectAPI, TeleKardiaAPI } from '../../Axios/axios';
import {
  clearIntervalAuthenticationChecker,
  clearToken,
  getToken,
  intervalAuthenticationChecker,
  setToken,
} from '../../Auth/AuthUtilities';
import * as Constants from '../../constants/app';
// import { getMe } from '../reducers/app'
import { DATASOURCES, getDateTimeFormat } from '../../constants/app';
import moment from 'moment';
import history from '../../Components/History';
import { dataSourceSuccess } from '../reducers/app';
import { ProviderStatus } from '../../Utilities/Constants';
import { getTimezoneName } from '../../Utilities/Utilities';

export const user_logout = () => {
  return (dispatch) => {
    window.replace = '/login';
    dispatch(setAuthentication(false));
    clearIntervalAuthenticationChecker();
    dispatch(getUserSuccess('logOut'));
  };
};

export const getUserStart = (data) => {
  return { type: actionTypes.GET_USER_START };
};
export const getUserSuccess = (data) => {
  return { type: actionTypes.GET_USER_SUCCESS, payload: data };
};
export const getUserChatCredsSuccess = (data) => {
  return { type: actionTypes.GET_USER_CHAT_CREDS, payload: data };
};

export const getUserFail = (error) => {
  return { type: actionTypes.GET_USER_FAIL, error: error };
};

export const addUser = (data) => {
  return (dispatch) => {
    dispatch(getUserSuccess(data));
  };
};
export const setAuthentication = (token) => {
  if (token) {
    setToken(token);
  } else {
    clearToken();
  }
  return { type: actionTypes.SET_AUTHENTICATION, payload: !!token };
};
const setLogin = () => {
  return { type: actionTypes.SET_LOGIN, payload: true };
};
export const fetchRenewJWT = () => (dispatch, getState) => {
  return GoAPI.get('/i/v1/jwt', { headers: { Authorization: `Bearer ${getToken()}` } })
    .then((res) => res.data)
    .then((res) => res.jwt)
    .catch((err) => {
      throw err;
    });
};

export const getjwt = (data) => (dispatch) => {
  const { email, password } = data;
  const basicToken = btoa(unescape(encodeURIComponent(`${email}:${password}`)));
  const getConfig = {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: `Basic ${basicToken}`,
    },
  };
  return GoAPI.get('/i/v1/jwt', getConfig)
    .then((res) => res.data)
    .then((res) => res.jwt)
    .catch((err) => {
      throw err;
    });
};

export const authLogin = (data) => (dispatch, getState) => {
  dispatch(getUserStart());
  dispatch(getjwt(data))
    .then((jwt) => dispatch(setAuthentication(jwt)))
    .then(() => dispatch(setLogin()))
    .then(() => dispatch(intervalAuthenticationChecker()))
    .then(() => dispatch(getUser()))
    .then(() => {
      const { previousLocation } = getState().app;
      if (!previousLocation) {
        history.push('/');
      } else {
        history.push(previousLocation);
      }
      return;
    })
    .catch((err) => {
      console.log(err);
      dispatch(setAuthentication(false));
      if (err.error && err.status === 403) {
        const { lockoutEndAt } = err.error;
        if (lockoutEndAt) {
          const endDate = moment(lockoutEndAt).format(getDateTimeFormat());
          dispatch(
            getUserFail(
              Constants.LOGIN_ERROR_MESSAGES.LOCKED_WITH_TIME.replace('%datetime', endDate),
            ),
          );
        } else {
          dispatch(getUserFail(`${Constants.LOGIN_ERROR_MESSAGES.LOCKED_OUT}`));
        }
      } else if (err.error && err.status === 503) {
        dispatch(getUserFail(Constants.LOGIN_ERROR_MESSAGES.BACKEND_DOWN));
      } else {
        dispatch(getUserFail(Constants.LOGIN_ERROR_MESSAGES.BAD_CREDENTIALS));
      }
    });
};

const registerChatUser = (data) => (dispatch, getState) => {
  const { firstName, lastName, email } = data;
  return GoAPI.post(
    '/i/v1/kcc/chatUser?userType=Coach',
    { firstName: firstName, lastName: lastName, email: email },
    { headers: { Authorization: `Bearer ${getToken()}` } },
  ).catch((err) => {
    throw err;
  });
};
export const authRegisterFromInviteScreen = (data) => (dispatch, getState) => {
  dispatch(getUserStart());
  const credentials = {
    email: data.email,
    password: data.password,
  };
  dispatch(getjwt(credentials))
    .then((jwt) => dispatch(setAuthentication(jwt)))
    .then(() => dispatch(setLogin()))
    .then(() => dispatch(registerChatUser(data)))
    .then(() => dispatch(intervalAuthenticationChecker()))
    .then(() => dispatch(getUser()))
    .then(() => {
      const { previousLocation } = getState().app;
      if (!previousLocation) {
        history.push('/');
      } else {
        history.push(previousLocation);
      }
      return;
    })
    .catch((err) => {
      console.log(err);
      dispatch(setAuthentication(false));
      if (err.error && err.status === 403) {
        const { lockoutEndAt } = err.error;
        if (lockoutEndAt) {
          const endDate = moment(lockoutEndAt).format(getDateTimeFormat());
          dispatch(
            getUserFail(
              Constants.LOGIN_ERROR_MESSAGES.LOCKED_WITH_TIME.replace('%datetime', endDate),
            ),
          );
        } else {
          dispatch(getUserFail(`${Constants.LOGIN_ERROR_MESSAGES.LOCKED_OUT}`));
        }
      } else if (err.error && err.status === 503) {
        dispatch(getUserFail(Constants.LOGIN_ERROR_MESSAGES.BACKEND_DOWN));
      } else {
        dispatch(getUserFail(Constants.LOGIN_ERROR_MESSAGES.BAD_CREDENTIALS));
      }
    });
};

export const getUser = () => async (dispatch, getState) => {
  const token = localStorage.getItem(Constants.LOCAL_STORAGE_AUTH_TOKEN_KEY);
  GoAPI.get('/i/v1/kcc/connection_info', {
    headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
  })
    .then((res) => {
      dispatch(getUserChatCredsSuccess(res.data));
    })
    .catch((error) => {
      console.log(error);
    });
  GoAPI.get('/i/v1/me', { headers: { Authorization: `Bearer ${token}` } })
    .then((res) => {
      dispatch(dataSourceSuccess(DATASOURCES.ME, res.data));
      return res;
    })
    .then((res) => {
      dispatch(getUserSuccess(res.data));
      return res;
    })
    .catch((error) => {
      dispatch(user_logout());
      dispatch(getUserFail('Something went wrong!'));
    });
};

//sessions
export const getAdminSessionsStart = () => {
  return { type: actionTypes.GET_ADMIN_SESSIONS_LOADING };
};
export const getAdminSessionsSuccess = (data, pages) => {
  return { type: actionTypes.GET_ADMIN_SESSIONS_SUCCESS, payload: { data, pages } };
};
export const getAdminSessionsFail = (data) => {
  return { type: actionTypes.GET_ADMIN_SESSIONS_FAIL, payload: data };
};

//status count
export const getMemberStatusCountLoading = () => {
  return { type: actionTypes.GET_MEMBER_STATUS_COUNT_LOADING };
};
export const getMemberStatusCountSuccess = (data) => {
  return { type: actionTypes.GET_MEMBER_STATUS_COUNT_SUCCESS, payload:data };
};
export const getMemberStatusCountFail = (data) => {
  return { type: actionTypes.GET_MEMBER_STATUS_COUNT_FAIL, payload: data };
};
export const getAdminSessions = (teamId, adminId, page, rowsPerPage, status='',filterMemberIds='',sortOrder=undefined,sortParam=undefined) => (dispatch, getState) => {
  let offsetInMinutes = new Date().getTimezoneOffset();
  let timeZone = getTimezoneName()
  dispatch(getAdminSessionsStart());
  TeleKardiaAPI.get(
    `/api/v2/teams/${teamId}/admin/${adminId}/appointments?offsetInMinutes=${offsetInMinutes}&pageNumber=${page}&pageSize=${rowsPerPage}&filterMemberIds=${filterMemberIds}&appointmentStatus=${status}&searchString=&sortOrder=${sortOrder}&sortParam=${sortParam}&timeZone=${timeZone}&countryIds=&regionIds=  `,
    {
      headers: { Authorization: ` Bearer ${getToken()}` },
    },
  )
    .then((res) => {
      let resultData = res.data;
      if (res.status === 200 && res.data.success == true) {
        dispatch(
          getAdminSessionsSuccess(resultData.result.appointments, resultData.result.totalElements),
        );
      }
      console.log(resultData);
    })
    .catch((err) => {
      console.log(err);
      dispatch(getAdminSessionsFail(err));
    });
};

export const getUserMemberStatuses = () => (dispatch, getState) => {
  dispatch(getMemberStatusCountLoading())
  GoAPI.get('/i/v1/kcc/status/count', { headers: { Authorization: `Bearer ${getToken()}` } })
  .then((res) => {
    const data =res.data
   dispatch(getMemberStatusCountSuccess(data))
  })
  .catch((err) => {
    dispatch(getMemberStatusCountFail(err.message))
    console.log(err)

  });
};


const filterActionActions = (data) => ({
  type: actionTypes.SET_SESSIONS_FILTER_DATA,
  payload: data,
});
export const filterActionHandler = (data) => (dispatch, getState) => {
  return dispatch(filterActionActions(data));
};


const SortParamActions = (data) => ({
  type: actionTypes.SET_SESSIONS_SORT_DATA,
  payload: {...data},
});
export const sortParamActionsHandler = (data) => (dispatch, getState) => {
 return  dispatch(SortParamActions(data));
};


//=== bypass api errorCallback if video call starts =====//
const isComponentMountedAction = (data) => {
  return ({
    type: actionTypes.VIDEOCALL_STARTED,
    payload: data
  })
}
export const isComponentMounted = (isMounted) => (dispatch) => {
  return dispatch(isComponentMountedAction(isMounted))
}
//=========//