/* eslint-disable */
import { connect } from 'react-redux'
import { getFormValues, isPristine } from 'redux-form'
import { getDataSource } from '../../../../../Store/reducers/app'
import { DATASOURCES, FORMS } from '../../../../../constants/metrics'
import {
  getPatientFileData,
  setInitialPatientFileData,
  updateSelectedEkgs,
  updateSelectedBloodPressures,
  updateSelectedReports,
  handleOnTabClick,
  handleOnBloodPressureSortClick,
  handleOnBloodPressureFilterChange,
  handleOnFilterButtonClick,
  handleOnEkgSortClick,
  handleOnEkgFilterChange,
  handleOnEnrollmentSortClick,
  handleOnEnrollmentFilterChange,
  handleOnReportSortClick,
  handleOnReportFilterChange,
  getEkgRecordingIdsFromDataSource,
  getBloodPressureRecordingIdsFromDataSource,
  handleEditPatientFormSubmit,
  handlePrescribeForm,
  getEnrollmentIdsFromDataSource,
  getReportIdsFromDataSource,
  getWeightRecordingIdsFromDataSource,
  handleEnrollmentEditForm,
  handleIdtfEnrollmentEditForm,
  handleEnrollmentOnRevokeClick,
  handleOnReportsBulkAction,
  toggleCustomReportPopover,
  updateCustomReportRequest,
  fetchCustomReport,
  updateCustomReportPopoverState,
  handleOnEkgTableScroll,
  handleOnBloodPressureTableScroll,
  handleOnWeightTableScroll,
  handleOnEkgRowClick,
  handleOnReportRowClick,
  handleOnRefreshClick,
  handleOnEkgBulkAction,
  handleOnSelectionClick,
  setSelectedEkgRecording,
  clearSelectedEkgRecording,
  setSelectedBloodPressureRecording,
  clearSelectedBloodPressureRecording,
  setSelectedWeightRecording,
  clearSelectedWeightRecording,
  updateSelectedWeights,
  handleOnWeightFilterChange,
  handleOnWeightSortClick,
} from './module'
// import { setNSRStatus } from 'views/state/ProfileSettings/ProfileSettingsActions'
import PatientFile from './PatientFile'

const mapActionCreators = {
  getPatientFileData,
  setInitialPatientFileData,
  updateSelectedEkgs,
  updateSelectedBloodPressures,
  updateSelectedReports,
  handleOnTabClick,
  handleOnFilterButtonClick,
  handleOnBloodPressureSortClick,
  handleOnBloodPressureFilterChange,
  handleOnEkgSortClick,
  handleOnEkgFilterChange,
  handleEditPatientFormSubmit,
  handlePrescribeForm,
  handleOnEnrollmentSortClick,
  handleOnEnrollmentFilterChange,
  handleOnReportSortClick,
  handleOnReportFilterChange,
  handleEnrollmentEditForm,
  handleIdtfEnrollmentEditForm,
  handleEnrollmentOnRevokeClick,
  handleOnReportsBulkAction,
  toggleCustomReportPopover,
  updateCustomReportRequest,
  // setNSRStatus,
  fetchCustomReport,
  updateCustomReportPopoverState,
  handleOnEkgTableScroll,
  handleOnBloodPressureTableScroll,
  handleOnWeightTableScroll,
  handleOnEkgRowClick,
  handleOnReportRowClick,
  handleOnRefreshClick,
  handleOnEkgBulkAction,
  handleOnSelectionClick,
  setSelectedEkgRecording,
  clearSelectedEkgRecording,
  setSelectedBloodPressureRecording,
  clearSelectedBloodPressureRecording,
  setSelectedWeightRecording,
  clearSelectedWeightRecording,
  updateSelectedWeights,
  handleOnWeightFilterChange,
  handleOnWeightSortClick,
}

const mapStateToProps = (state, ownProps) => ({
  browser: state.app.browser,
  dataIsFetching: state.patientFile.dataIsFetching,
  dataLastUpdated: state.patientFile.dataLastUpdated,
  sidebarIsCollapsed: state.app.sidebarIsCollapsed,
  // authorizationType: state.auth.authorizationType,
  selectedIndex: state.patientFile.selectedIndex,
  selectedTab: state.patientFile.selectedTab,
  selectedPatientId: state.patientFile.selectedPatientId,
  selectedPopover: state.patientFile.selectedPopover,
  refreshClicked: state.patientFile.refreshClicked,
  bulkActionComplete: state.patientFile.bulkActionComplete,
  bulkActionIsFetching: state.patientFile.bulkActionIsFetching,
  bulkActionSuccessMessage: state.patientFile.bulkActionSuccessMessage,

  enrollmentSortBy: state.patientFile.enrollmentSortBy,
  enrollmentSortDirection: state.patientFile.enrollmentSortDirection,
  selectedEnrollmentStartStartDate: state.patientFile.selectedEnrollmentStartStartDate,
  selectedEnrollmentStartEndDate: state.patientFile.selectedEnrollmentStartEndDate,
  selectedEnrollmentEndStartDate: state.patientFile.selectedEnrollmentEndStartDate,
  selectedEnrollmentEndEndDate: state.patientFile.selectedEnrollmentEndEndDate,
  selectedEnrollmentPrescriptionId: state.patientFile.selectedEnrollmentPrescriptionId,
  selectedEnrollmentIcd10CodeId: state.patientFile.selectedEnrollmentIcd10CodeId,
  selectedEnrollmentBillingCode: state.patientFile.selectedEnrollmentBillingCode,
  selectedEnrollmentOrderNumber: state.patientFile.selectedEnrollmentOrderNumber,

  selectedBloodPressureRecordingId: state.patientFile.selectedBloodPressureRecordingId,
  selectedBloodPressureSource: state.patientFile.selectedBloodPressureSource,
  selectedBloodPressureSeverity: state.patientFile.selectedBloodPressureSeverity,
  selectedBloodPressureStartDate: state.patientFile.selectedBloodPressureStartDate,
  selectedBloodPressureEndDate: state.patientFile.selectedBloodPressureEndDate,
  selectedBloodPressureBpmStartValue: state.patientFile.selectedBloodPressureBpmStartValue,
  selectedBloodPressureBpmEndValue: state.patientFile.selectedBloodPressureBpmEndValue,
  selectedBloodPressureSystolicStartValue: state.patientFile.selectedBloodPressureSystolicStartValue,
  selectedBloodPressureSystolicEndValue: state.patientFile.selectedBloodPressureSystolicEndValue,
  selectedBloodPressureDiastolicStartValue: state.patientFile.selectedBloodPressureDiastolicStartValue,
  selectedBloodPressureDiastolicEndValue: state.patientFile.selectedBloodPressureDiastolicEndValue,
  selectedBloodPressures: state.patientFile.selectedBloodPressures,
  bloodPressureSortBy: state.patientFile.bloodPressureSortBy,
  bloodPressureSortDirection: state.patientFile.bloodPressureSortDirection,

  selectedEkgRecordingId: state.patientFile.selectedEkgRecordingId,
  selectedEkgResult: state.patientFile.selectedEkgResult,
  selectedEkgPhysicianId: state.patientFile.selectedEkgPhysicianId,
  selectedEkgPhysicians: state.patientFile.selectedEkgPhysicians,
  selectedEkgStartDate: state.patientFile.selectedEkgStartDate,
  selectedEkgEndDate: state.patientFile.selectedEkgEndDate,
  selectedEkgBpmStartValue: state.patientFile.selectedEkgBpmStartValue,
  selectedEkgBpmEndValue: state.patientFile.selectedEkgBpmEndValue,
  selectedEkgs: state.patientFile.selectedEkgs,
  ekgSortBy: state.patientFile.ekgSortBy,
  ekgSortDirection: state.patientFile.ekgSortDirection,

  selectedReportEsignedId: state.patientFile.selectedReportEsignedId,
  selectedReportExportedId: state.patientFile.selectedReportExportedId,
  selectedReportPhysicianId: state.patientFile.selectedReportPhysicianId,
  selectedReportPhysicians: state.patientFile.selectedReportPhysicians,
  selectedReportPrescriptionId: state.patientFile.selectedReportPrescriptionId,
  selectedReportBillingCode: state.patientFile.selectedReportBillingCode,
  selectedReportStartDate: state.patientFile.selectedReportStartDate,
  selectedReportEndDate: state.patientFile.selectedReportEndDate,
  reportSortBy: state.patientFile.reportSortBy,
  reportSortDirection: state.patientFile.reportSortDirection,
  selectedReports: state.patientFile.selectedReports,

  selectedWeightRecordingId: state.patientFile.selectedWeightRecordingId,
  selectedWeightStartDate: state.patientFile.selectedWeightStartDate,
  selectedWeightEndDate: state.patientFile.selectedWeightEndDate,
  selectedWeightSource: state.patientFile.selectedWeightSource,
  selectedWeightSeverity: state.patientFile.selectedWeightSeverity,
  selectedWeightBmiStartValue: state.patientFile.selectedWeightBmiStartValue,
  selectedWeightBmiEndValue: state.patientFile.selectedWeightBmiEndValue,
  selectedWeightWeightStartValue: state.patientFile.selectedWeightWeightStartValue,
  selectedWeightWeightEndValue: state.patientFile.selectedWeightWeightEndValue,
  selectedWeights: state.patientFile.selectedWeights,
  weightSortBy: state.patientFile.weightSortBy,
  weightSortDirection: state.patientFile.weightSortDirection,

  customReportDateRange: state.patientFile.customReportDateRange,
  customReportPopoverOpen: state.patientFile.customReportPopoverOpen,

  reportIds: getReportIdsFromDataSource(state),
  enrollmentIds: getEnrollmentIdsFromDataSource(state),
  ekgRecordingIds: getEkgRecordingIdsFromDataSource(state),
  bloodPressureRecordingIds: getBloodPressureRecordingIdsFromDataSource(state),
  weightRecordingIds: getWeightRecordingIdsFromDataSource(state),
  patient: getDataSource(state, DATASOURCES.PATIENT),
  patients: getDataSource(state, DATASOURCES.PATIENTS),
  ekgs: getDataSource(state, DATASOURCES.EKGS),
  bloodPressures: getDataSource(state, DATASOURCES.BLOOD_PRESSURES),
  weights: getDataSource(state, DATASOURCES.WEIGHTS),
  enrollments: getDataSource(state, DATASOURCES.ENROLLMENTS),
  reports: getDataSource(state, DATASOURCES.REPORTS),
  billingCodes: getDataSource(state, DATASOURCES.BILLING_CODES),
  icd10Codes: getDataSource(state, DATASOURCES.ICD10_CODES),
  physicians: getDataSource(state, DATASOURCES.PHYSICIANS),
  prescriptions: getDataSource(state, DATASOURCES.PRESCRIPTIONS),
  teamTriggers: getDataSource(state, DATASOURCES.TEAM_TRIGGERS),
  patientTriggers: getDataSource(state, DATASOURCES.PATIENT_TRIGGERS),
  customReportPdf: getDataSource(state, DATASOURCES.CUSTOM_REPORT_PDF),
  insurances: getDataSource(state, DATASOURCES.INSURANCES),
  addresses: getDataSource(state, DATASOURCES.ADDRESSES),
  idtfEnrollments: getDataSource(state, DATASOURCES.IDTF_ENROLLMENTS),
  prescribeFormData: getFormValues(FORMS.PRESCRIBE_FORM)(state),
  enrollmentFormData: getFormValues(FORMS.ENROLLMENT_FORM)(state),
  prescribeFormPristine: isPristine(FORMS.PRESCRIBE_FORM)(state),
  enrollmentFormPristine: isPristine(FORMS.ENROLLMENT_FORM)(state),
  editPatientFormPristine: isPristine(FORMS.EDIT_PATIENT_FORM)(state),
  me: getDataSource(state, DATASOURCES.ME),
  templateList: getDataSource(state, DATASOURCES.PRESCRIPTIONS),
  // kardiaRxIDTFEcg: state.kardiaRx.kardiaRxIDTFEcg,
  // kardiaRxCCTEcg: state.kardiaRx.kardiaRxCCTEcg,
  pateintEnrollOverview: getDataSource(state, DATASOURCES.ENROLL_PATIENT_OVERVIEW)
})

export default connect(
  mapStateToProps,
  mapActionCreators
)(PatientFile)
