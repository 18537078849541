import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Grid } from '@material-ui/core';
import { fontSizes, colors, fonts } from '../../../../style/styleSettings';
import { EnhancedTableHead } from '../../../Common/EnhancedTableHead';
import ViewNotes from '../../../Common/ViewNotes';
import ReportModal from '../../../../Components/AllAppointments/ReportModal';
import { useSelector, useDispatch } from 'react-redux';
import { commonStyles } from '../../styles';
import moment from 'moment';
import { TeleKardiaAPI, DoctorPortalAPI, GoAPI } from '../../../../Axios/axios';
import { getTimezoneName } from '../../../../Utilities/Utilities';
import { getToken } from '../../../../Auth/AuthUtilities';
import EcgLoader from '../../../../Components/Shared/EcgLoader';
import { useParams } from 'react-router';
import {
  getMemberProfileSessionLogData,
  getSessionLogsSuccess,
} from '../../../../Store/actions/memberProfile';

const useRowStyles = makeStyles({
  root: {
    // '& > *': {
    //   borderBottom: 'unset',
    // },
    minHeight: '100px',
    fontFamily: fonts.TABLE_HEADER,
    marginTop: '10px',
    '& .MuiTableCell-head': {
      fontWeight: 'bold !important',
      fontSize: fontSizes.BASE,
    },
    '& .MuiTypography-body1': {
      fontSize: fontSizes.BASE,
    },
  },
  tableBody: {
    fontFamily: `${fonts.SESSION} !important`,
    '&:nth-of-type(4n+1)': {
      background: 'rgb(250, 250, 250) !important',
    },
    '& .MuiTableCell-body': {
      fontSize: fontSizes.BASE,
    },
    '& .MuiTypography-h6': {
      fontSize: `${fontSizes.MEDIUM} !important`,
    },
    '& .MuiTypography-body2': {
      fontSize: `${fontSizes.BASE} !important`,
    },
    '& .MuiChip-outlinedPrimary': {
      color: 'black',
      border: `1px solid ${colors.SPRUCE}`,
    },
    '& .MuiChip-outlinedSecondary': {
      color: 'black',
      border: `1px solid ${colors.SESSION_CHIP_OUTLINE_RED}`,
    },
  },
  topMargins: {
    marginTop: '20px',
    position: 'relative',
  },
  hearderMargin: {
    padding: '25px 0 0 20px',
    font: fonts.SESSION,
    fontSize: fontSizes.BASE,
  },
  tableHeader: {
    fontWeight: 'bold',
    fontSize: fontSizes.MEDIUM,
  },
  viewNotesTableColumn: {
    color: colors.SPRUCE,
    fontWeight: 'bold',
    fontSize: `${fontSizes.CAPTION} !important`,
    cursor: 'pointer',
  },
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const [openReport, setopenReport] = useState(false);
  const [viewNotesData, setviewNotesData] = useState({});
  const [viewHHData, setViewHHData] = useState([]);
  const [loading, setloading] = useState(false);
  const teamId = useSelector((state) => state.user.team.id);
  const coachId = useSelector((state) => state.user.profile.id);
  const offsetInMinutes = new Date().getTimezoneOffset();
  const timeZone = getTimezoneName();

  const viewNotes = () => {
    if (row.appointmentIndex === -1) {
      // for cadiology session on click on view notes the report should show up
      setopenReport(true);
    } else if (row.appointmentStatus === 'Scheduled' || row.appointmentStatus === 'InProgress') {
      return; // no view notes for non documented session.
    } else {
      setloading(true);
      //api call to receive session notes, program focus and heart habit.
      TeleKardiaAPI.get(
        `/api/v1/teams/${teamId}/clinicians/${coachId}/patients/${row.patient.patientId}/appointments/${row.appointmentId}/detail?offsetInMinutes=${offsetInMinutes}&timeZone=${timeZone}`,
        {
          headers: { Authorization: ` Bearer ${getToken()}` },
        },
      )
        .then((res) => {
          const data = res.data.result;
          setviewNotesData(data);
          setloading(false);
        })
        .then(() => {
          //fetching heart habit data
          GoAPI.get(`/i/v1/kcc/heartHabit/${row.patient.patientId}/${row.appointmentId}`, {
            headers: { Authorization: ` Bearer ${getToken()}` },
          })
            .then((res) => {
              const data = res.data ? res.data : [];
              setViewHHData(data);
              // setNotesLoading(false);
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
      setOpen(!open); //toggle the accordion based on click of "view notes"
    }
  };
  const reportmodalCloseHandler = () => {
    setopenReport(false);
  };

  return (
    <React.Fragment>
      <TableRow className={classes.tableBody}>
        <TableCell component="th" scope="row">
          {moment(row.scheduledStartTime || row.startdatetime).format('MM/DD/yyyy')}
        </TableCell>
        <TableCell>
          {moment(row.scheduledStartTime || row.startdatetime).format('hh:mm A')} -{' '}
          {moment(row.scheduledEndTime || row.enddatetime).format('hh:mm A')}
        </TableCell>
        <TableCell>{row.appointmentStatusDisplayName}</TableCell>
        <TableCell>
          {moment(row.scheduledEndTime || row.enddatetime).diff(
            moment(row.scheduledStartTime || row.startdatetime),
            'minutes',
          )}{' '}
          mins
        </TableCell>
        {row.appointmentIndex === -1 ? (
          <TableCell>Cardiology</TableCell>
        ) : row.appointmentIndex === 0 ? (
          <TableCell>Initial</TableCell>
        ) : (
          <TableCell>Ongoing</TableCell>
        )}
        <TableCell>{row.appointmentChannelType}</TableCell>
        <TableCell className={classes.viewNotesTableColumn} onClick={viewNotes}>
          {row.appointmentStatus === 'Scheduled' || row.appointmentStatus === 'InProgress' ? (
            '-- --'
          ) : (
            <>
              {open ? 'HIDE NOTES' : 'VIEW NOTES'}
              <IconButton aria-label="expand row" size="small">
                {open ? (
                  <KeyboardArrowUpIcon style={{ color: colors.SPRUCE }} />
                ) : (
                  <KeyboardArrowRightIcon style={{ color: colors.SPRUCE }} />
                )}
              </IconButton>
            </>
          )}
        </TableCell>
      </TableRow>
      <TableRow className={classes.tableBody}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {!!loading ? (
              <EcgLoader />
            ) : (
              <ViewNotes sessionLogsViewNotes={viewNotesData} hhData={viewHHData} />
            )}
          </Collapse>
        </TableCell>
      </TableRow>
      {openReport && ( //Report modal is for cardiology consultations.
        <ReportModal
          key="report-modal"
          appointmentId={row.appointmentId}
          patientId={row.patient.patientId}
          memberId={coachId}
          show={openReport}
          handleClose={reportmodalCloseHandler}
        ></ReportModal>
      )}
    </React.Fragment>
  );
}

function SessionLogs(props) {
  const { tableHead, adminTableHead } = props;
  const { memberId } = useParams();
  const dispatch = useDispatch();
  const [sessionLogsDetails, setsessionLogsDetails] = useState([]);
  const sessionDetails = useSelector((state) => state.memberProfile?.sessionLogs);
  const sessionCredits = useSelector((state) => state.memberProfile?.programData);
  const teamId = useSelector((state) => state.user.team.id);
  const coachId = useSelector((state) => state.user.profile.id);
  const isAdmin = useSelector((state) => state.user.profile.permissions?.isAdmin);
  // const { programData: data } = useSelector((state) => state.memberProfile);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    setloading(true);
    let offsetInMinutes = new Date().getTimezoneOffset();

    const endpoint = `api/v1.1/teams/${teamId}/clinicians/${coachId}/appointments/coach?offsetInMinutes=${offsetInMinutes}&pageNumber=0&pageSize=100&patientId=${memberId}&appointmentStatus=&searchString=&sortOrder=&sortParam=&timeZone=${getTimezoneName()}`;

    const adminEndpoint = `api/v2/teams/${teamId}/admin/${coachId}/appointments?patientId=${memberId}&pageNumber=0&pageSize=100&offsetInMinutes=${offsetInMinutes}&timeZone=${getTimezoneName()}`;

    const doctorendpoint = `api/v1.1/teams/${teamId}/clinicians/${coachId}/appointments/coach?offsetInMinutes=${offsetInMinutes}&pageNumber=0&pageSize=100&patientId=${memberId}&appointmentStatus=&searchString=&sortOrder=&sortParam=&timeZone=${getTimezoneName()}`;

    let coachPortalEndpoint;
    if (isAdmin) {
      coachPortalEndpoint = adminEndpoint;
    } else {
      coachPortalEndpoint = endpoint;
    }

    TeleKardiaAPI.get(coachPortalEndpoint, {
      headers: { Authorization: ` Bearer ${getToken()}` },
    })
      .then((res) => {
        let coachResultData = res.data.result;
        let doctorPortalEndpoint
        if (isAdmin) {
          doctorPortalEndpoint = adminEndpoint;
        } else {
          doctorPortalEndpoint = doctorendpoint;
        }
        DoctorPortalAPI.get(doctorPortalEndpoint, {
          headers: { Authorization: `Bearer ${getToken()}` },
        })
          .then((response) => {
            let doctorResultData = response.data.result;
            const finalData = [...coachResultData.appointments, ...doctorResultData.appointments];
            console.log(finalData);
            dispatch(getSessionLogsSuccess(finalData));
            setsessionLogsDetails(finalData);
            setloading(false);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        console.log(err);
      });
    // dispatch(getMemberProfileSessionLogData(memberId));
  }, []);

  // useEffect(() => {
  //   if (sessionDetails !== null && sessionDetails.length !== 0) {
  //     // const filteredArray = sessionDetails.filter(item => {  //filter sessions based on member clicked
  //     //   return item.patient.patientId === data.participantId
  //     // })
  //     console.log('new array - ', sessionDetails);
  //     setsessionLogsDetails(sessionDetails);
  //     setloading(false);
  //   }
  //   // else {
  //   //   setloading(false);
  //   // }
  // }, [sessionDetails]);

  const classes = useRowStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('scheduledStartTime' || 'startdatetime');
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  return (
    <Paper className={classes.root}>
      {loading ? (
        <EcgLoader />
      ) : (
        <>
          {sessionLogsDetails && sessionLogsDetails.length === 0 ? (
            <Typography variant="h6" style={{ textAlign: 'center', paddingTop: '30px' }}>
              No data to show{' '}
            </Typography>
          ) : (
            <>
              <Grid container className={classes.hearderMargin}>
                <Grid item lg={3} md={3}>
                  <Typography variant="body1">Coaching Session</Typography>
                  <Typography variant="body1">
                    {(sessionCredits.coachRemainingCredits && sessionCredits.coachTotalCredits)
                      ? <b> {sessionCredits.coachRemainingCredits} of {sessionCredits.coachTotalCredits} available </b>
                      : <b> -- </b>
                    }
                  </Typography>
                </Grid>
                <Grid item lg={3} md={3}>
                  <Typography variant="body1">Cardiology Consultaiton</Typography>
                  <Typography variant="body1">
                  {(sessionCredits.doctorRemainingCredits && sessionCredits.doctorTotalCredits)
                      ? <b> {sessionCredits.doctorRemainingCredits} of {sessionCredits.doctorTotalCredits} available </b>
                      : <b> -- </b>
                    }
                  </Typography>
                </Grid>
              </Grid>
              <TableContainer component={Paper} className={classes.topMargins}>
                <Table aria-label="collapsible table">
                  <EnhancedTableHead
                    from="sessionLogs"
                    columnData={isAdmin ? adminTableHead : tableHead}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {sessionLogsDetails &&
                      sessionLogsDetails.length > 0 &&
                      stableSort(sessionLogsDetails, getComparator(order, orderBy)).map(
                        (row, index) => <Row key={index} row={row} />,
                      )}
                    {/* <div className={commonClasses.overlay}>Coming Soon!</div> */}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </>
      )}
    </Paper>
  );
}

export default SessionLogs;
