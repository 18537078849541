import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { components } from 'react-select';
import styles from './login.module.css';
import CopyrightStatement from '../../Components/CopyrightStatement';
import Loading from '../../Components/Loading';
import Logo from './Logo.js';
import SignInArrow from '../../Assets/images/icons/logout.svg';
import { useDispatch, useSelector } from 'react-redux';
import { authLogin } from '../../Store/actions/user';
import { ROUTES } from '../../constants/app';

const Login = (props) => {
  const history = useHistory();
  const userDetails = useSelector((state) => state.user);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  // const handleRegionChange = selectedOption => {
  //   this.setState({ selectedOption })
  //   setRegion(selectedOption.value)
  // }
  useEffect(() => {
    if (userDetails.profile) {
      history.push(ROUTES.ROOT);
    }
  }, [userDetails]);

  const submitLogin = () => {
    dispatch(authLogin({ email, password }));
  };
  const isOutdatedBrowser = false;
  const Option = (props) => (
    <div>
      {props.value === 'us' ? (
        <div className={styles.region}>
          <components.Option {...props}>
            {/* <UsFlag /> */}
            {props.children}
          </components.Option>
        </div>
      ) : (
        <div className={styles.region}>
          <components.Option {...props}>
            {/* <EuFlag /> */}
            {props.children}
          </components.Option>
        </div>
      )}
    </div>
  );

  const SingleValue = ({ children, ...props }) => (
    <div className={styles.selectedRegion}>
      {props.data.value === 'us' ? (
        <components.SingleValue {...props}>
          {/* <UsFlag /> */}
          {children}
        </components.SingleValue>
      ) : (
        <components.SingleValue {...props}>
          {/* <EuFlag /> */}
          {children}
        </components.SingleValue>
      )}
    </div>
  );

  return (
    <div data-page="login" className={styles.login}>
      <div className={styles.loginOuterCont}>
        <div className={styles.loginBox}>
          <section className={styles.header} onClick={() => props.history.push('/lol')}>
            <div className={styles.logo}>
              <Logo />
            </div>
            <h1 className={styles.lr}>
              <span>S</span>
              <span>I</span>
              <span>G</span>
              <span>N</span>
              <span>I</span>
              <span>N</span>
            </h1>
          </section>

          <section className={styles.content}>
            {isOutdatedBrowser ? null : ( // ? <BrowserOutdatedMessage />
              <div className={styles.contentInner}>
                <div className={styles.inputField}>
                  <input
                    className={styles.defaultTextInput}
                    autoComplete="new-username"
                    id="emailTextField"
                    name="username"
                    // onKeyDown={this.handleUsernameKeydown}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    type="email"
                    placeholder="Email"
                  />
                </div>
                <div className={styles.inputField}>
                  <input
                    // ref={input => { this.passwordInputRef = input }}
                    className={styles.defaultTextInput}
                    autoComplete="new-password"
                    id="passwordTextField"
                    name="password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    onKeyPress={(e) => {
                      if (e.charCode === 13) {
                        submitLogin();
                        return;
                      }
                    }}
                    value={password}
                    type="password"
                    placeholder="Password"
                  />
                </div>
                {/* <div className={styles.inputField}>
                  <Select
                    className="loginSelect react-select-filter-selector"
                    classNamePrefix="loginSelect react-select-filter-selector"
                    components={{ Option, SingleValue }}
                    defaultValue={selectedOption}
                    onChange={this.handleRegionChange}
                    options={options}
                    isSearchable={false}
                  />
                </div> */}

                {userDetails?.errorMsg && (
                  <h2 className={styles.errorMsg}>{userDetails.errorMsg}</h2>
                )}

                <div className={styles.forgotPassword}>
                  <p>
                    <Link to={ROUTES.REQUEST_NEW_PASSWORD}>Forgot password?</Link>
                  </p>
                </div>
                <div>
                  {userDetails?.loading ? (
                    <button className={styles.disabledButton}>
                      <div className={styles.loginLoading}>
                        <Loading />
                        {/* <p>Loading</p> */}
                      </div>
                    </button>
                  ) : (
                    <button className={styles.signInButton} onClick={() => submitLogin()}>
                      <SignInArrow />
                      Sign In
                    </button>
                  )}
                </div>
              </div>
            )}
          </section>

          <section className={styles.footer}>
            <CopyrightStatement />
          </section>
        </div>
      </div>
    </div>
  );
};

export default Login;
