import React, { useCallback, useEffect, useState } from 'react';
import {
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Popover,
  makeStyles,
  Button,
  Select,
  FormGroup,
  FormControlLabel,
  Checkbox,
  withStyles,
  MenuItem,
  ListItemText,
  OutlinedInput,
} from '@material-ui/core';
import { colors } from '../../../style/styleSettings';
import MuiTypography from '../../../Components/Shared/Typography/MuiTypography';
import FilterListIcon from '@material-ui/icons/FilterList';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { green } from '@material-ui/core/colors';
import { useDispatch, useSelector } from 'react-redux';
import { filterActionHandler, sortParamActionsHandler } from '../../../Store/actions/user';
import { GoAPI } from '../../../Axios/axios';
import { getToken } from '../../../Auth/AuthUtilities';

const useStyles = makeStyles({
  paddingTopmainmenu: { marginTop: '5px', background: 'white' },
  chipfont: {
    fontFamily: 'Work Sans !important',
    color: '#000000 !important',
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '-0.2px',
    fontWeight: 400,
  },
  popper: { display: 'flex', justifyContent: 'space-evenly', alignContent: 'center' },
  popovergrid2: { padding: '5px', height: 'auto', background: 'white', width: 'auto' },
  formControlchip2: {
    background: '#FFFFFF',
    marginLeft: '5px',
    marginRight: '5px',
    marginBottom: '5px',
    // minHeight: 280,
    width: 'auto',
    height: 'auto',
  },
  formControllabelstyle2: {
    marginLeft: '5px',
    marginRight: '5px',
    fontFamily: 'Work Sans !important',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: 'black',
  },
  buttonstyle: { marginBottom: '10px', color: '#F25F5F' },
});
const GreenCheckbox = withStyles({
  root: {
    color: '#DADADA',
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function Filter(props) {
  const { memberFilters } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const savedFilterData = useSelector((state) => state.sessions.sessionsFilterParams);
  const [anchorE2, setAnchorE2] = useState(null);
  const [chipArray, setchipArray] = useState(null);
  const makeInitData = () => {
    let initData = {};
    initData.status = {
      name: 'all-status',
      checked: true,
      type: 'status',
      code: '1,3,4,5,8,10',
    };
    initData.coach = {
      label: 'all-coach',
      checked: true,
      type: 'coach',
    };
    memberFilters.status.map((m) => {
      initData[m.id] = {
        name: m.name,
        checked: true,
        type: 'status',
        code: m.code,
      };
    });
    return initData;
  };
  //initialising data
  useEffect(() => {
    let data = makeInitData();
    setchipArray(data);
  }, []);

  // loading from redux
  useEffect(() => {
    if (savedFilterData) {
      setchipArray(savedFilterData);
      props.fetchAdminSessionsHandler();
    }
  }, [savedFilterData]);

  /**
   * Anchors for functions for managing buttons
   */
  const popoveropen2 = Boolean(anchorE2);
  const popoverid2 = popoveropen2 ? 'simple-popover' : undefined;

  const handlepopover2openClick = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handlepopovercloseclick = () => {
    setAnchorE2(null);
  };

  const handlepopoverresetclick2 = useCallback(() => {
    setchipArray(makeInitData());
    setSelectedCoach([]);
  }, [chipArray]);

  const dispatch = useDispatch();
  const [changeFilters, setChangeFilters] = useState(false);
  const [coachList, setCoachList] = useState([]);
  const [selectedCoach, setSelectedCoach] = useState([]);
  const data = ['status', 'coach'];
  /**
   * Fetchng all coach list and populating
   */
  const fetchCoachList = async () => {
    GoAPI.get(`/i/v1/kcc/coach`, {
      headers: { Authorization: ` Bearer ${getToken()}` },
    })
      .then((res) => {
        let resultData = res.data;
        // console.log(resultData);
        let options = resultData.map((d) => {
          return {
            value: d.coachID,
            label: `${d.coachLastName}, ${d.coachFirstName}`,
          };
        });
        setCoachList(options);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchCoachList();
  }, []);
  useEffect(() => {
    if (changeFilters) {
      onsubmitfilter();
      setChangeFilters((m) => !m);
    }
  }, [changeFilters]);

  /**
   * Handler to submit filter
   */
  const onsubmitfilter = () => {
    dispatch(filterActionHandler(chipArray)); //redux push
    handlepopovercloseclick()
    return;
  };

  /**
   * Handler to remove tag
   * @param {*} value
   */
  const removetag = (value, update = true) => {
    let temp = { ...chipArray };
    temp[value].checked = false;
    if (data.includes(value)) {
      let typeOfFilter = temp[value].type; //blood
      Object.values(temp).map((f) => {
        if (f.type === typeOfFilter) {
          f.checked = false;
        }
      });
    }
    setchipArray(temp);

    if (temp[value].type === 'coach') {
      let temp2 = [...selectedCoach];
      let indx = temp2.findIndex((item) => item.value === temp[value].value);
      temp2.splice(indx, 1);
      setSelectedCoach((t) => temp2);
    }
    if (update) {
      setChangeFilters(true);
    }
  };
  /**
   * On change function for coach
   * @param {*} item
   * @param {*} e
   */
  const onChangeSelectCoach = (item) => {
    //setting all coach as false
    chipArray['coach'].checked = false;
    // let checked = e.target.checked;
    let temp = { ...chipArray };
    item.checked = !item.checked;
    item.type = 'coach';
    if (item.checked) {
      temp[item.value] = item;
      setSelectedCoach((m) => [...m, item]);
      setchipArray(temp);
    } else {
      removetag(item.value, false);
    }
  };
  /**
   * On change function for all other checkboxes
   * @param {*} value
   * @param {*} event
   */
  const handleChange = (value, event) => {
    let temp = { ...chipArray };
    temp[value.id] = {
      name: value.name,
      checked: event.target.checked,
      type: value.type,
      code: value.code,
    };
    let typeOfFilter = value.type; //blood
    let temp2 = Object.values(temp).filter((f) => f.type === typeOfFilter);
    let flag = true;
    temp2.map((t) => {
      if (!t.checked && !t.name.includes('all-')) {
        flag = false;
      }
    });
    if (flag) {
      temp[typeOfFilter] = {
        //this part is a todo
        name: `all-${typeOfFilter}`,
        checked: true,
        type: value.type,
        code: value.code,
      };
    } else {
      temp[typeOfFilter] = {
        //this part is a todo
        name: `all-${typeOfFilter}`,
        checked: false,
        type: value.type,
        code: value.code,
      };
    }

    setchipArray(temp);
  };

  /**
   * Show chip array that has 'all' attribute
   * @returns
   */
  const showAllIfAny = () => {
    let temp = { ...chipArray };

    return data.map((d) => {
      if (temp[d].checked) {
        return (
          <>
            <Chip
              variant="outlined"
              className={classes.chipfont}
              key={temp[d].id}
              label={temp[d].name === 'all-status' ? `Status - All` : 'Coach - All'}
              onDelete={() => removetag(d)}
            />
            &nbsp;
          </>
        );
      }
    });
  };

  /**
   * Show chip array with all other attributes
   * @param {*} value
   * @returns
   */
  const showChip = (value) => {
    let typeOfFilter = value[1]?.type;
    let temp = { ...chipArray };
    let allTrue = false;
    if (typeOfFilter) {
      allTrue = temp[typeOfFilter].checked;
    }
    if (value[1].checked === true && !allTrue) {
      return (
        <>
          <Chip
            variant="outlined"
            className={classes.chipfont}
            key={value[0]}
            label={
              value[0].includes('status') ? `Status - ${value[1].name}` : `Coach- ${value[1].label}`
            }
            onDelete={() => removetag(value[0])}
          />
          &nbsp;
        </>
      );
    }
  };
  return (
    <>
      <Grid
        alignItems="flex-start"
        spacing={0}
        className={classes.paddingTopmainmenu}
        container
        justify="space-between"
        style={{ display: '-ms-flexbox', background: '#fff' }}
      >
        <Grid item>
          <Grid
            alignItems="center"
            spacing={2}
            container
            justify="flex-start"
            style={{ margin: '5px' }}
          >
            <Grid item>
              <MuiTypography
                fontFamily="Work Sans"
                fontSize="16px"
                fonStyle="normal"
                fontWeight={600}
                lineHeight="20px"
                color="#142A39"
              >
                Filter
              </MuiTypography>
            </Grid>
            <Grid item>
              <IconButton onClick={handlepopover2openClick} aria-label="Search">
                <FilterListIcon />
              </IconButton>
            </Grid>
            <Grid item>
              {chipArray &&
                Object.entries(chipArray).map((value) => {
                  return showChip(value);
                })}
              {chipArray && showAllIfAny()}
            </Grid>
          </Grid>
          <Popover
            id={popoverid2}
            open={popoveropen2}
            anchorEl={anchorE2}
            className={classes.popper}
            modifiers={{
              preventOverflow: {
                enabled: false,
                boundariesElement: 'window',
              },
              arrow: {
                enabled: true,
                //element: arrowRef,
              },
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Grid
              item
              container
              justify="flex-end"
              alignItems="flex-end"
              direction="row"
              spacing={2}
            >
              <Grid item>
                <IconButton aria-label="close" onClick={handlepopovercloseclick}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid
              container
              justify="space-between"
              direction="row"
              className={classes.popovergrid2}
            >
              <Grid item>
                <InputLabel className={classes.formControllabelstyle2}>Coach</InputLabel>
                <FormControl variant="outlined" className={classes.formControlchip1}>
                  <InputLabel id="demo-multiple-checkbox-label">Select Coach(s)</InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple={'true'}
                    value={selectedCoach}
                    input={<OutlinedInput label="Select Coach(s)" />}
                    renderValue={() =>
                      selectedCoach.length > 0 ? selectedCoach.map((m) => m.label).join(',') : []
                    }
                    style={{
                      width: '200px',
                    }}
                    // MenuProps={MenuProps}
                  >
                    {coachList.map((item, i) => {
                      return (
                        <MenuItem
                          onClick={() => onChangeSelectCoach(item)}
                          key={i}
                          value={item}
                          style={{ display: 'flex', justifyContent: 'space-between' }}
                        >
                          <ListItemText primary={item.label} />
                          {chipArray[item.value] && chipArray[item.value].checked && (
                            <DoneIcon style={{ color: colors.SPRUCE }} />
                          )}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <InputLabel className={classes.formControllabelstyle2}>Status</InputLabel>
                <FormControl variant="outlined" className={classes.formControlchip2}>
                  {chipArray &&
                    memberFilters.status.map((item) => {
                      return (
                        <FormGroup column key={item.id}>
                          <FormControlLabel
                            control={
                              <GreenCheckbox
                                onChange={(e) => handleChange(item, e)}
                                name={item.name}
                                checked={chipArray[item.id].checked}
                              />
                            }
                            label={item.name}
                          />
                        </FormGroup>
                      );
                    })}
                </FormControl>
              </Grid>
              <Grid
                item
                container
                justify="flex-end"
                alignItems="flex-end"
                direction="row"
                spacing={2}
              >
                <Grid item>
                  <Button className={classes.buttonstyle} onClick={handlepopoverresetclick2}>
                    <MuiTypography
                      fontSize="14px"
                      lineHeight="16.42px"
                      fontFamily="Work Sans"
                      letterSpacing="0.75px"
                      fontWeight={700}
                      color="#2D9F86"
                    >
                      {t('CLEAR ALL')}
                    </MuiTypography>
                  </Button>
                </Grid>
                <Grid item>
                  <div className="telekardia">
                    <button
                      type="button"
                      className="btn m-1 btn_teal py-2 px-4"
                      onClick={onsubmitfilter}
                    >
                      {t('APPLY')}
                    </button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {/* </Paper> */}
          </Popover>
        </Grid>
      </Grid>
    </>
  );
}

export default Filter;
