import * as actionTypes from '../actions/actionTypes';

const initialState = {
  loading: false,
  memberListData: [],
  searchMemberData: [],
  memberListFilter: null,
};
const profileInitialState = {
  laoding: false,
  programData: null,
  healthData: null,
  sessionLogs: null,
  error: null,
  programProgress: {},
  programFocus:{}
};
export function memberListReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_CHAT_USERS_START:
      return {
        ...state,
        loading: true,
        memberListData: [],
      };
    case actionTypes.GET_MEMBERS_LIST:
      return {
        ...state,
        loading: false,
        memberListData: action.payload.data,
        count: action.payload.count,
      };
    case actionTypes.GET_MEMBERS_LIST_NEXT_SESSION_DATA: {
      return {
        ...state,
        memberListData: action.payload.data,
      };
    }
    case actionTypes.GET_SEARCH_MEMBER:
      return {
        ...state,
        loading: false,
        searchMemberData: action.payload,
      };
    case actionTypes.SET_FILTER_DATA:
      return {
        ...state,
        memberListFilter: action.payload,
      };
    default:
      return state;
  }
}
export function memberProfileReducer(state = profileInitialState, action) {
  switch (action.type) {
    case actionTypes.GET_MP_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_MP_PROGRAM_SUCCESS:
      return {
        ...state,
        loading: false,
        programData: action.payload,
      };
    case actionTypes.GET_MP_PROGRAM_PP_LOADING:
      return {
        ...state,
        loading: false,
        programProgress: {
          loading: true,
        },
      };
    case actionTypes.GET_MP_PROGRAM_PP_FAIL:
      return {
        ...state,
        loading: false,
        programProgress: {
          ...state.programProgress,
          loading: false,
          error: action.payload,
        },
      };
    case actionTypes.GET_MP_PROGRAM_PP_SUCCESS:
      return {
        ...state,
        loading: false,
        programProgress: {
          ...state.programProgress,
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    case actionTypes.GET_MP_PROGRAM_PF_LOADING:
      return {
        ...state,
        loading: false,
        programFocus: {
          loading: true,
        },
      };
    case actionTypes.GET_MP_PROGRAM_PF_FAIL:
      return {
        ...state,
        loading: false,
        programFocus: {
          ...state.programFocus,
          loading: false,
          error: action.payload,
        },
      };
    case actionTypes.GET_MP_PROGRAM_PF_SUCCESS:
      return {
        ...state,
        loading: false,
        programFocus: {
          ...state.programFocus,
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    case actionTypes.GET_MP_HEALTH_SUCCESS:
      return {
        ...state,
        loading: false,
        healthData: action.payload,
      };
    case actionTypes.GET_MP_SESSIONLOG_SUCCESS:
      return {
        ...state,
        loading: false,
        sessionLogs: action.payload,
      };
    case actionTypes.GET_MP_SESSIONLOG_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_MP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
