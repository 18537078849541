import axios from 'axios';
import { getToken } from '../../Auth/AuthUtilities';
import { GoAPI, TeleKardiaAPI, DoctorPortalAPI } from '../../Axios/axios';
import { getTimezoneName } from '../../Utilities/Utilities';
import * as actionTypes from './actionTypes';

const getMemberProfileStart = () => ({
  type: actionTypes.GET_MP_LOADING,
});

const getMemberProfileSuccess = (data) => ({
  type: actionTypes.GET_MP_PROGRAM_SUCCESS,
  payload: data,
});

const getMemberProfileFail = (error) => ({
  type: actionTypes.GET_MP_FAIL,
  payload: error,
});

const getMpHealthSuccess = (data) => ({
  type: actionTypes.GET_MP_HEALTH_SUCCESS,
  payload: data,
});
const getMpProgramProgressStart = () => ({
  type: actionTypes.GET_MP_PROGRAM_PP_LOADING,
});
const getMpProgramProgressSuccess = (data) => ({
  type: actionTypes.GET_MP_PROGRAM_PP_SUCCESS,
  payload: data,
});

const getMpProgramFocusFail = (error) => ({
  type: actionTypes.GET_MP_PROGRAM_PF_FAIL,
  payload: error,
});
const getMpProgramFocusStart = () => ({
  type: actionTypes.GET_MP_PROGRAM_PF_LOADING,
});
const getMpProgramFocusSuccess = (data) => ({
  type: actionTypes.GET_MP_PROGRAM_PF_SUCCESS,
  payload: data,
});

const getMpProgramProgressFail = (error) => ({
  type: actionTypes.GET_MP_PROGRAM_PP_FAIL,
  payload: error,
});
export const getSessionLogsSuccess = (data) => ({
  type: actionTypes.GET_MP_SESSIONLOG_SUCCESS,
  payload: data,
});
const getMpSessionLogFail = (error) => ({
  type: actionTypes.GET_MP_SESSIONLOG_FAILURE,
  payload: error,
});

// Program tab
export const getMemberProfileDetails = (participantId) => (dispatch, getState) => {
  dispatch(getMemberProfileStart());
  GoAPI.get(`/i/v1/kcc/adminList/${participantId}`, {
    headers: { Authorization: ` Bearer ${getToken()}` },
  })
    .then((res) => {
      let resultData = res.data;
      console.log(resultData);
      dispatch(getMemberProfileSuccess(resultData));
    })
    .catch((err) => {
      console.log(err);
      dispatch(getMemberProfileFail(err.message));
    });
};

export const setCurrentWeekDetails = (currentWeek) => (dispatch, getState) => {
  const pgData = getState().memberProfile.programData;
  if (pgData) {
    dispatch(getMemberProfileSuccess({ ...pgData, currentWeek: currentWeek }));
  }
};

// health details tab
export const getMemberProfileHealthDetails =
  (teamId, coachId, memberId, offsetInMinutes, timezoneValue) => (dispatch, getState) => {
    dispatch(getMemberProfileStart());
    TeleKardiaAPI.get(
      `/api/v1/teams/${teamId}/clinicians/${coachId}/patients/${memberId}/heartHealthAssessment?timeZoneOffset=${offsetInMinutes}&timeZone=${timezoneValue}`,
      {
        headers: { Authorization: ` Bearer ${getToken()}` },
      },
    )
      .then((res) => {
        let resultData = res.data;
        if (resultData.success) {
          dispatch(getMpHealthSuccess(resultData.result));
          //get medications
          GoAPI.get(`/i/v1/users/${memberId}/medications`, {
            headers: { Authorization: ` Bearer ${getToken()}` },
          }).then((res) => {
            console.log(res.data);
            if (res.status === 200) {
              if (res.data.length > 0) {
                let medArray = [];
                res.data.map((r) => {
                  medArray.push(`${r.drugName} - ${r.dosage} ${r.dosageUnit}`);
                });
                resultData.result.medications.medicationList = medArray;
                dispatch(getMpHealthSuccess(resultData.result));
              }
            }
          });
          //get surgical history
          GoAPI.get(`/i/v1/users/${memberId}/surgicalHistory`, {
            headers: { Authorization: ` Bearer ${getToken()}` },
          }).then((res) => {
            console.log(res.data);
            if (res.status === 200) {
              if (res.data.length > 0) {
                let surgArray = [];
                res.data.map((r) => {
                  surgArray.push(`${r.surgeryName} - ${r.surgeryDate}`);
                });
                resultData.result.medicalHistory.surgicalHistory = surgArray;
                dispatch(getMpHealthSuccess(resultData.result));
              }
            }
          });
          //get family disease history
          GoAPI.get(`/i/v1/users/${memberId}/familyDiseaseHistory`, {
            headers: { Authorization: ` Bearer ${getToken()}` },
          }).then((res) => {
            console.log(res.data);
            if (res.status === 200) {
              if (res.data.diseases.length > 0) {
                let fmlyArray = [];
                res.data.diseases.map((r) => {
                  if (r.name != 'None' && r.toggle===true) {
                    fmlyArray.push(`${r.name}`);
                  }
                });
                resultData.result.medicalHistory.familyHistory = fmlyArray;

                dispatch(getMpHealthSuccess(resultData.result));
              }
            }
          });
        }
        console.log(resultData);
      })
      .catch((err) => {
        console.log(err);
        dispatch(getMemberProfileFail(err.message));
      });
  };

// program progress
export const getMemberProfilePPDetails = (weekNumber, memberId) => (dispatch, getState) => {
  dispatch(getMpProgramProgressStart());
  GoAPI.get(`/i/v1/kcc/programTab/${weekNumber}?userID=${memberId}`, {
    headers: { Authorization: ` Bearer ${getToken()}` },
  })
    .then((res) => {
      let resultData = res.data;
      if (res.status === 200) {
        dispatch(getMpProgramProgressSuccess(resultData));
      }
      console.log(resultData);
    })
    .catch((err) => {
      console.log(err);
      dispatch(getMpProgramProgressFail(err.message));
    });
};

// program focus
export const getMemberProfilePFDetails =
  (teamId, coachId, memberId, offsetInMinutes) => (dispatch, getState) => {
    dispatch(getMpProgramFocusStart());
    TeleKardiaAPI.get(
      `/api/v1/teams/${teamId}/clinicians/${coachId}/patients/${memberId}/appointments/latest?offsetInMinutes=${offsetInMinutes}&timeZone=${getTimezoneName()}`,
      {
        headers: { Authorization: ` Bearer ${getToken()}` },
      },
    )
      .then((res) => {
        let resultData = res.data;
        if (res.status === 200) {
          GoAPI.get(`/i/v1/kcc/heartHabits/${memberId}/latest`, {
            headers: { Authorization: ` Bearer ${getToken()}` },
          }).then((res) => {
            let data = res.data;
            console.log(data);
            resultData.result.heartHabit = data ? data : [];
            if (res.status === 200) {
              console.log(resultData);
              dispatch(getMpProgramFocusSuccess(resultData.result));
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(getMpProgramFocusFail(err.message));
      });
  };

//=== session logs api call ======//
export const getMemberProfileSessionLogData = (patientId) => (dispatch, getState) => {
  dispatch(getMpProgramProgressStart());

  const teamId = getState().user.team.id;
  const coachId = getState().user.profile.id;
  let offsetInMinutes = new Date().getTimezoneOffset();

  const endpoint = `api/v1.1/teams/${teamId}/clinicians/${coachId}/appointments?offsetInMinutes=${offsetInMinutes}&pageNumber=0&pageSize=100&patientId=${patientId}&appointmentStatus=&searchString=&sortOrder=&sortParam=&timeZone=${getTimezoneName()}`;

  TeleKardiaAPI.get(endpoint, {
    headers: { Authorization: ` Bearer ${getToken()}` },
  })
    .then((res) => {
      let coachResultData = res.data.result;
      DoctorPortalAPI.get(endpoint, {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
        .then((response) => {
          let doctorResultData = response.data.result;
          const finalData = [...coachResultData.appointments, ...doctorResultData.appointments];
          dispatch(getSessionLogsSuccess(finalData));
          console.log(finalData);
        })
        .catch();
    })
    .catch((err) => {
      console.log('error - ', err);
      dispatch(getMpSessionLogFail(err.message));
    });
};
