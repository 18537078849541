import { Box, Button, Card, CardContent, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  addSessionHeartHabitDetails,
  deleteSessionHeartHabitDetails,
  editSessionHeartHabitDetails,
} from '../../../../Store/actions/session';
import HeartHabitCard from './HeartHabitCard';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import moment from 'moment';
import { colors, fonts, fontSizes } from '../../../../style/styleSettings';
import Alert from '@material-ui/lab/Alert';
const myStyles = makeStyles((theme) => ({
  root: {
    fontFamily: `${fonts.SESSION}`,
    '& .telekardia h6': {
      fontWeight: '600 !important',
      color: '#000 !important',
    },
    '& .MuiButton-root': {
      width: '100%',
      color: `${colors.SPRUCE}`,
      background: '#FFF',
      fontWeight: '600',
      textAlign: 'center',
      // border: '1px solid #142A39',
      borderRadius: '4px',
    },
  },
  title: {
    fontWeight: '600 !important',
    color: '#000 !important',
    fontSize: `${fontSizes.MEDIUM} !important`,
  },
}));

const HeartHabitComponent = (props) => {
  const { patientDetails } = props;
  const { patient } = patientDetails;
  const dispatch = useDispatch();
  const [programFocus, setprogramFocus] = useState([]);
  const heartHealthData = useSelector((state) => state.session.heartHabit);
  const programFocusData = useSelector((state) => state.session.programFocus);
  const clinicianId = useSelector((state) => state.user.profile.id);
  const teamId = useSelector((state) => state.user.team.id);
  const [heartHabit, setheartHabit] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    setprogramFocus(programFocusData);
  }, [programFocusData]);
  useEffect(() => {
    setheartHabit(heartHealthData);
  }, [heartHealthData]);
  // console.log(programFocusData);
  /**
   * Add card handler
   * Locally updates the state to show a new card and not saving to api
   */

  const addCardHandler = () => {
    const pattern = 'YYYY-MM-DDTHH:mm:ss.SSS';
    let newHH = {
      id: null,
      focus: '',
      focusNote: '',
      recurrencePattern: 'RRULE:FREQ=DAILY;UNTIL=20211103T000000;BYDAY=MO,TU,WE,TH,FR',
      startDate: moment().format(pattern),
      endDate: moment().format(pattern),
    };
    setheartHabit((s) => [...s, newHH]);
  };

  /**
   * addCardHandlerSaveToApi
   * @param {*} data
   * @desc saves the newly added card to api
   */
  const getMinArray = (data, type) => {
    return data.map((d) => d[type]);
  };

  const addCardHandlerSaveToApi = (data) => {
    // console.log(data);
    let offsetInMinutes = new Date().getTimezoneOffset();
    let hhArray = [];
    if (heartHabit.length > 0) {
      hhArray = getMinArray(heartHabit, 'focus');
    }
    const check = hhArray.includes(data.focus);
    if (!check) {
      dispatch(
        addSessionHeartHabitDetails(
          teamId,
          clinicianId,
          patient?.patientId,
          props.appointmentId,
          offsetInMinutes,
          data,
        ),
      );
      return true;
    } else {
      setError('Cannot add more than one heart habit for a program');
      setTimeout(() => {
        setError(null);
      }, 5000);
      return false;
    }
  };

  /**
   * deleteCardHandler
   * @param {*} id
   * Deletes the new card locally and saves it to the api
   */
  const deleteCardHandler = (id) => {
    let offsetInMinutes = new Date().getTimezoneOffset();
    if (id === null) {
      setheartHabit(heartHealthData);
    } else {
      dispatch(
        deleteSessionHeartHabitDetails(
          teamId,
          clinicianId,
          patient?.patientId,
          props.appointmentId,
          offsetInMinutes,
          id,
        ),
      );
    }
  };

  /**
   * editCardHandler
   * @param {*} id
   * @param {*} data
   * Edits the card with the given id
   */
  const editCardHandler = (id, data) => {
    let offsetInMinutes = new Date().getTimezoneOffset();
    dispatch(
      editSessionHeartHabitDetails(
        teamId,
        clinicianId,
        patient?.patientId,
        props.appointmentId,
        offsetInMinutes,
        id,
        data,
      ),
    );
  };

  const classes = myStyles();
  return (
    <Box>
      <Box>{error && <Alert severity="error">{error}</Alert>}</Box>
      <Grid container className={classes.root} spacing={2}>
        {heartHabit &&
          heartHabit.map((item, index) => {
            return (
              <Grid item xs={6} key={item.id}>
                <HeartHabitCard
                  item={item}
                  index={index}
                  deleteCard={deleteCardHandler}
                  editCard={editCardHandler}
                  addCardHandlerSaveToApi={addCardHandlerSaveToApi}
                />
              </Grid>
            );
          })}
        <Grid item xs={6}>
          {heartHabit.length < 3 && (
            <Card>
              <CardContent style={{ width: '100%' }}>
                <Typography variant="h6" className={classes.title}>
                  New Heart Habit
                </Typography>
                <Button variant="contained" size="large" onClick={addCardHandler}>
                  <AddCircleOutlineIcon color={`${colors.SPRUCE}`} />
                  <p>ADD HEART HABIT</p>
                </Button>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default HeartHabitComponent;
