import { connect } from 'react-redux';
import { getChatUserAction, getS3BucketData, upLoadFiles, uploadFileSuccess } from '../../Store/actions';
import ChatInboxDetails from './ChatInboxDetails';

const mapActionCreators = {
  getChatUserAction,
  getS3BucketData,
  upLoadFiles,
  uploadFileSuccess
};

const mapStateToProps = (state) => ({
  profileId: state.user?.profile?.id,
  chat: state.chat,
});

export default connect(mapStateToProps, mapActionCreators)(ChatInboxDetails);
