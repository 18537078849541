import React, { Component } from 'react'
import Select, { components } from 'react-select'
import UncheckedCheckboxIcon from '../../../Assets/images/checkbox_empty.svg'
import CheckedCheckboxIcon from '../../../Assets/images/checkboxChecked.svg'

class MultiSelectSearchDropdown extends Component {
  onSelectChange = value => {
    if (typeof this.props.handleOnSelectionClick === 'function') {
      this.props.handleOnSelectionClick(value)
    }
  }

  defaultFilterOption = (option, input) => {
    const label = option.label.toLowerCase()
    const searchInputParts = input.toLowerCase().split(' ')
    return searchInputParts.every(text => label.includes(text))
  }

  render() {
    const {
      options,
      getOptionFilter,
      getOptionLabel,
      getOptionValue,
      value,
      enableSearch = true,
      className = 'interpretationSelect',
      controlShouldRenderValue,
      backspaceRemovesValue,
      onKeyDown,
    } = this.props

    const Option = props => (
      <React.Fragment>
        { props.isSelected ?
          <components.Option {...props}>
            <CheckedCheckboxIcon />
            {props.children}
          </components.Option>
          :
          <components.Option {...props}>
            <UncheckedCheckboxIcon />
            {props.children}
          </components.Option>
        }
      </React.Fragment>
    )

    const msComponents = { Control: enableSearch ? components.Control : () => null, Option }

    return (
      <Select
        autoFocus
        backspaceRemovesValue={backspaceRemovesValue || false}
        controlShouldRenderValue={controlShouldRenderValue || false}
        hideSelectedOptions={false}
        isClearable={false}
        closeMenuOnSelect={false}
        menuIsOpen
        isMulti
        onChange={this.onSelectChange}
        filterOption={getOptionFilter || this.defaultFilterOption}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        options={options}
        placeholder="Search..."
        className={`${className} react-select-filter-selector`}
        classNamePrefix={`${className} react-select-filter-selector`}
        tabSelectsValue={false}
        value={value}
        components={msComponents}
        onKeyDown={onKeyDown || null}
      />
    )
  }
}

export default MultiSelectSearchDropdown
