import React from 'react'
import { max, min, timeDay, extent, ticks, tickStep, tickIncrement, scaleTime, domain, range, scaleLinear, symbol, symbolTriangle } from 'd3'

import styles from './TemporaryBloodPressureHackedGraph.module.css'
import {
  TimelineGraph,
  ChartAxisLabel,
  ScrollingContainer,
  Legend,
  XAxis,
  Marker,
} from '../../../Components/Graphs'
import {
  getBloodPressureCounts,
  getBloodPressureSeverityOrder,
  getBloodPressureSeverityDisplayText,
  getBPSeverityClassName,
} from '../../../constants/app'

class TemporaryBloodPressureHackedGraph extends React.Component {
  constructor(props) {
    super(props)

    // COMMON SCALES & FUNCS
    this.xWidthPerDatum = 24
    this.radius = 6
    this.yAxisTicksWidth = 64

    this.paddingLeft = 76
    this.paddingRight = 8

    this.primaryChart = {
      height: 120,
      paddingTop: 40,
      paddingBottom: 25,
      paddingLeft: this.paddingLeft,
      paddingRight: this.paddingRight,
    }

    this.markerRadius = 8
    this.offsetTop = this.primaryChart.paddingTop - 10

    this.primaryTotals = this.primaryChart.height + this.primaryChart.paddingBottom + this.primaryChart.paddingTop

    this.combinedChartHeight = this.primaryTotals
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      recordingIds,
      selectedRecording,
      clearSelectedRecording,
    } = nextProps

    if (selectedRecording && recordingIds.length === 0) {
      clearSelectedRecording()
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!this.props.recordingIds && nextProps.recordingIds) {
      return true
    }

    if (this.props.recordingIds && nextProps.recordingIds && (this.props.recordingIds.length !== nextProps.recordingIds.length)) {
      return true
    }

    if (!this.props.selectedRecording && nextProps.selectedRecording) {
      return true
    }

    if (this.props.selectedRecording && nextProps.selectedRecording && (this.props.selectedRecording.id !== nextProps.selectedRecording.id)) {
      return true
    }

    if (!nextProps.selectedRecording) {
      return true
    }

    return false
  }

  render() {
    const {
      completeRecordings = [],
      recordingIds = [],
      recordings = [],
      setSelectedRecording,
      selectedRecording,
      width,
    } = this.props

    const unselectedRecordings = completeRecordings
      .filter(rec => !recordingIds.includes(rec.id))

    /* GRAPH SECTION */
    const chartAxisLabelClass = 'default'
    let chartWidth
    const floorDay = dateStr => timeDay.floor(new Date(dateStr))

    // figure out how to set the timescale
    let timeExtent
    const minArr = []
    const maxArr = []
    const hasRecordings = completeRecordings.length > 0
    const bloodPressureCounts = getBloodPressureCounts(completeRecordings)

    const getMaxMin = (d, accessor) => {
      const maxResult = max(d, r => floorDay(r[accessor]))
      maxArr.push(maxResult)

      const minResult = min(d, r => floorDay(r[accessor]))
      minArr.push(minResult)
    }

    if (hasRecordings) {
      getMaxMin(completeRecordings, 'recorded_at')
    }

    if (!hasRecordings) {
      timeExtent = [ floorDay(new Date()), floorDay(new Date()) ]
    } else {
      timeExtent = [ min(minArr), max(maxArr) ]
    }

    // most recent recordings are on the right
    const minDaysForWidth = Math.floor(((width - this.paddingLeft - this.paddingRight - this.yAxisTicksWidth) / this.xWidthPerDatum) - 1)

    // fill the screen with future dates if there aren't enough dates in the data
    // count is 0 based
    const extentWidth = timeDay.count(timeExtent[0], timeExtent[1]) + 1
    let rightTimeExtent

    if (extentWidth >= minDaysForWidth) {
      rightTimeExtent = timeExtent[1]
    } else {
      rightTimeExtent = timeDay.offset(timeExtent[0], minDaysForWidth)
    }

    const timeExtentObj = [ new Date(timeExtent[0]), new Date(rightTimeExtent) ]

    const xScale = () => scaleTime()
      .domain(timeExtentObj)
      .range([ 0, chartWidth ])
    const returnX = d => xScale()(floorDay(d))

    const dayTicks = xScale().ticks(timeDay)
    const dayTicksCount = dayTicks.length
    chartWidth = dayTicksCount * this.xWidthPerDatum
    const svgWidth = chartWidth + this.paddingLeft + this.paddingRight

    // let myExtent = extent(recordings, recording => recording.systolic)

    const dataArray = []
    completeRecordings.forEach(d => {
      dataArray.push(d.systolic)
      dataArray.push(d.diastolic)
    })
    let myExtent = extent(dataArray)


    let yTicks = ticks(myExtent[0], myExtent[1], 5)

    if (yTicks.length >= 2) {
      const extZero = myExtent[0] - 20
      const extOne = myExtent[1] + 20
      myExtent = [ extZero, extOne ]
      yTicks = ticks(extZero - 5, extOne + 5, 5)
    }

    if (yTicks.length < 2) {
      const extZero = myExtent[0] - 20
      const extOne = myExtent[1] + 20
      myExtent = [ extZero, extOne ]
      yTicks = ticks(extZero - 5, extOne + 5, 5)
    }

    // handle case where there are no recordings
    if (yTicks.length === 0) {
      myExtent = [ 40, 300 ]
      yTicks = ticks(myExtent[0], myExtent[1], 5)
    }

    // scales
    // yscale for ekgtimeline
    const yScale = () => scaleLinear()
      .domain(myExtent)
      .range([ this.primaryChart.height, 0 ])
      .nice()
    const returnY = d => yScale()(d)

    let markerPosition
    let selectedRecordingDate
    if (selectedRecording) {
      selectedRecordingDate = selectedRecording.recorded_at
      markerPosition = parseInt((returnX(selectedRecordingDate) + this.paddingLeft) - this.radius - 1, 10)
    }

    /* GRAPH SECTION */

    const renderRecordings = () => {
      const points = []
      unselectedRecordings.forEach((recording, i) => {
        const cx = returnX(recording.recorded_at)
        const cy1 = returnY(recording.systolic)
        const cy2 = returnY(recording.diastolic)
        const cssClass = recording.severityClass

        points.push(<circle
          key={`${i}_unselected_systolic`}
          className={`${styles.circle} ${styles[cssClass]} ${styles.unselected}`}
          cy={cy1}
          cx={cx}
          r={this.radius}
        />)

        points.push((
          <g
            key={`${i}_unselected_diastolic`}
            transform={`translate(${cx}, ${cy2})`}
          >
            <path
              className={`${styles[cssClass]} ${styles.unselected}`}
              d={symbol().type(symbolTriangle).size(this.radius * 10.667)()}
            />
          </g>
        ))
      })
      recordings.forEach((recording, i) => {
        const cx = returnX(recording.recorded_at)
        const cy1 = returnY(recording.systolic)
        const cy2 = returnY(recording.diastolic)
        const cssClass = recording.severityClass

        points.push(<circle
          key={`${i}_selected_systolic`}
          onClick={() => (typeof setSelectedRecording === 'function' ? setSelectedRecording(recording) : undefined)}
          className={`${styles.circle} ${styles[cssClass]} ${styles.selected}`}
          cy={cy1}
          cx={cx}
          r={this.radius}
        />)

        points.push((
          <g
            key={`${i}_selected_diastolic`}
            transform={`translate(${cx}, ${cy2})`}
          >
            <path
              className={`${styles[cssClass]} ${styles.selected}`}
              onClick={() => (typeof setSelectedRecording === 'function' ? setSelectedRecording(recording) : undefined)}
              d={symbol().type(symbolTriangle).size(this.radius * 10.667)()}
            />
          </g>
        ))
      })
      return points
    }

    return (
      <div className={styles.graph}>
        <Legend
          items={bloodPressureCounts}
          getLegendClassname={getBPSeverityClassName}
          legendOrder={getBloodPressureSeverityOrder().slice(1)}
          getLegendDisplayText={getBloodPressureSeverityDisplayText}
          isBloodPressure={true}
        />
        <ChartAxisLabel
          heading=""
          tickLabel="mm Hg"
          returnY={returnY}
          yScale={yScale}
          yTicks={yTicks}
          chart={this.primaryChart}
          yAxisTicksWidth={this.yAxisTicksWidth}
          classPrefix={chartAxisLabelClass}
          labelName="Primary"
          labelOffset={110}
        />
        <ScrollingContainer
          recordings={recordings}
          xScale={xScale}
          windowWidth={width}
          setInitialSelectedRecording={setSelectedRecording}
          selectedRecording={selectedRecording}
          dateField="recorded_at"
        >
          <TimelineGraph
            chart={this.primaryChart}
            chartWidth={chartWidth}
            dayTicks={dayTicks}
            returnY={returnY}
            svgWidth={svgWidth}
            xScale={xScale}
            yTicks={yTicks}
            renderRecordings={renderRecordings}
          />
          <XAxis
            yOffset={0}
            chartHeight={this.combinedChartHeight}
            width={chartWidth}
            paddingLeft={this.paddingLeft}
            paddingRight={this.paddingRight}
            offsetTop={this.offsetTop}
            dayTicks={dayTicks}
            yTicks={yTicks}
            returnX={returnX}
            returnY={returnY}
          />
          {selectedRecordingDate && (
            <Marker
              markerPosition={markerPosition}
              height={this.combinedChartHeight}
              width={chartWidth}
              markerRadius={this.markerRadius}
              offsetTop={this.offsetTop - (this.markerRadius / 2)}
              paddingLeft={this.paddingLeft}
              paddingRight={this.paddingRight}
            />
          )}
        </ScrollingContainer>
      </div>
    )
  }
}

export default TemporaryBloodPressureHackedGraph
