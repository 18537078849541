import React from 'react'
import classes from './XAxis.module.css'
import * as d3 from 'd3'

class XAxis extends React.PureComponent {
  render() {
    const {
      yOffset,
      chartHeight,
      width,
      paddingLeft,
      paddingRight,
      offsetTop,
      dayTicks,
      yTicks,
      returnY,
      returnX,
    } = this.props

    const renderXTicks = () => {
      const formatTime = d3.timeFormat('%d')
      const formatMonth = d3.timeFormat('%B')

      const checkForFirstOfDaMonth = day => {
        const isDaFirst = parseInt(formatTime(day), 10) === 1
        return isDaFirst ? (
          <g>
            <line
              className={classes.monthLine}
              x1="0"
              y1="22"
              x2="0"
              y2={chartHeight}
              transform={`translate(-12, ${-1 * (chartHeight - offsetTop)})`} // 10 is to make the vertical line a little higher
            />
            <text
              dx="-.65em"
              dy="0"
              y="5"
              x="0"
              style={{ textAnchor: 'start' }}
            >
              {formatMonth(day)}
            </text>
          </g>
        ) : null
      }

      return dayTicks.map((dayTick, i) => {
        const xCoord = returnX(dayTick)
        return (
          <g
            key={i}
            transform={`translate(${xCoord}, 0)`}
            className={classes.dayTick}
          >
            {checkForFirstOfDaMonth(dayTick)}
            <text
              dx="0"
              dy=".71em"
              y="9"
              x="0"
              style={{ textAnchor: 'middle' }}
            >
              {formatTime(dayTick)}
            </text>
          </g>
        )
      })
    }

    return (
      <div className={classes.xAxis}>
        <svg
          className={classes.xAxisSvg}
          width={width + paddingLeft + paddingRight}
          height={chartHeight}
        >
          <g
            className={classes.xTicks}
            transform={`translate(${paddingLeft}, ${chartHeight - 20})`}
          >
            {renderXTicks()}
          </g>
        </svg>
      </div>
    )
  }
}

export default XAxis
