/* eslint-disable */
import axios from 'axios';
import * as actionTypes from '../Store/actions/actionTypes';
import { store } from '..';
import { clearToken } from '../Auth/AuthUtilities';
import { ROUTES } from '../Components/chart/Constants';
import { PORTAL_ERROR_MESSAGES } from '../constants/app';
import { useSelector } from 'react-redux';
export const GoAPI = axios.create({
  baseURL: process.env.REACT_APP_GOKARDIA_API_BASE,
});

export const TeleKardiaAPI = axios.create({
  baseURL: process.env.REACT_APP_TELEKARDIA_API_BASE,
});
export const DoctorPortalAPI = axios.create({
  baseURL: process.env.REACT_APP_DOCTOR_SERVICE,
});
export const ChatKardiaAPI = axios.create({
  baseURL: process.env.REACT_APP_CHAT_KARDIA_SERVICE,
});

export const ResponseCallback = (res) => {
  return res;
};
let lastRequest = null;
export const RequestCallback = (req) => {
  lastRequest = req;
  if (req.headers.Authorization.split(' ')[1] != 'null') {
    return req;
  } else {
    console.log('DIAGNOSTIC: Cancelling request');
    return false;
  }
};

export const ErrorCallback = (err) => {
  const isComponentMounted = store.getState().user.isComponentMounted;
  //Using debugger here!
  // console.log(err, lastRequest)
  // store.dispatch({
  //   type: actionTypes.ERROR_LOGS,
  //   payload: { err: err, url: '' },
  // });
  switch (err.response?.status) {
    case 401: {
      if (isComponentMounted) return; //avoid dispatching api error if videocall is in progress.
      console.log('DIAGNOSTIC: Session Expired, logging you out');
      clearToken();
      store.dispatch({
        type: actionTypes.BACKEND_DOWN,
        payload: PORTAL_ERROR_MESSAGES.SESSION_TIMEOUT,
      });
      return setTimeout(clearToken, 2000);
    }
    case 502:
    case 503: {
      if (isComponentMounted) return; //avoid dispatching api error if videocall is in progress.
      console.log('DIAGNOSTIC: Backend Server down, logging you out');
      store.dispatch({
        type: actionTypes.BACKEND_DOWN,
        payload: PORTAL_ERROR_MESSAGES.BACKEND_DOWN,
      });
      return setTimeout(clearToken, 2000);
    }
  }
  throw err;
};

// ========= File upload request/response/error interceptors' callback ===========//
let isPendingrequest = 0;
export const chatRequestCallback = (req) => {
  ++isPendingrequest
  store.dispatch({
    type: actionTypes.IS_PENDING_REQUEST,
    payload: isPendingrequest
  })
  return req
}
export const chatResponseCallback = (res) => {
  --isPendingrequest
  store.dispatch({
    type: actionTypes.IS_PENDING_REQUEST,
    payload: isPendingrequest
  })
  return res
}
export const chatErrorCallback = (err) => {
  --isPendingrequest
  store.dispatch({
    type: actionTypes.IS_PENDING_REQUEST,
    payload: isPendingrequest
  })
  throw err
}
//======== ends ==========//

// Add a response interceptor
GoAPI.interceptors.request.use(RequestCallback, ErrorCallback);
GoAPI.interceptors.response.use(ResponseCallback, ErrorCallback);
// TeleKardiaAPI.interceptors.request.use(TkRequestCallback, ErrorCallback);
TeleKardiaAPI.interceptors.response.use(ResponseCallback, ErrorCallback);
DoctorPortalAPI.interceptors.request.use(RequestCallback, ErrorCallback);
DoctorPortalAPI.interceptors.response.use(ResponseCallback, ErrorCallback);
ChatKardiaAPI.interceptors.request.use(chatRequestCallback, chatErrorCallback) //file upload request interceptor
ChatKardiaAPI.interceptors.response.use(chatResponseCallback, chatErrorCallback) //file upload response interceptor
