import {
  Button,
  Grid,
  makeStyles,
  createMuiTheme,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getSearchedMember } from '../../Store/actions'
import { ROUTES } from '../../constants/app';

const newStyles = makeStyles((theme) => ({
  input: {
    color: '#142A39 !important',
    width: '90%',
    fontFamily: 'Work Sans !important',
    marginLeft: '25px',
    '& input::placeholder': {
      fontFamily: 'Work Sans !important',
      fontWeight: 400,
      color: '#142A39 !important',
      opacity: 1,
      textAlign: 'left',
      padding: '5px',
    },
    '& input:focus': {
      borderColor: '#000',
    },
    border: 'unset',
  },
  listbox: {
    width: 'auto',
    height: 'auto',
    marginTop: 0,
    marginLeft: '30px',
    padding: '10px',
    zIndex: 1,
    position: 'absolute',
    listStyle: 'none',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    border: '1px solid rgba(0,0,0,.25)',
    '& li[data-focus="true"]': {
      backgroundColor: '#C1C1C1',
      color: 'white',
      cursor: 'pointer',
    },
    '& li:active': {
      backgroundColor: '#2977f5',
      color: 'white',
    },
  },
}));

const UseAutocomplete = () => {
  const classes = newStyles();
  const searchData = useSelector(state => state.memberList.searchMemberData)
  const memberData = searchData.reduce((acc, cur) => {
    const datas = {title : `${cur.lastName} ${cur.firstName}`, id : cur.id}
    return [...acc, datas]
  },[])
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    value,
    groupedOptions,
  } = useAutocomplete({
    id: 'use-autocomplete-demo',
    options: memberData,
    getOptionLabel: (option) => option.title
  });

  const history = useHistory();
  useEffect(() => {
    if (value) {
      history.push(`${ROUTES.MEMBER_PROFILE}/${value?.id}`);
    }
  }, [history, value]);

  return (
    <div>
      <div {...getRootProps()} style={{position: 'relative'}}>
        <SearchIcon style={{ position: 'absolute' }} />
        <input placeholder="Search Member..." className={classes.input} {...getInputProps()} />
      </div>
      {groupedOptions.length > 0 ? (
        <ul className={classes.listbox} {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <li
              {...getOptionProps({ option, index })}
            >{option.title}</li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

const useStyles = makeStyles({
  topbar: { justifyContent: 'space-between' },
});
const muitheme = createMuiTheme({
  palette: {
    primary: {
      main: '#2D9F86',
    },
  },
  typography: {
    fontFamily: 'Work Sans',
  },
});

function GlobalSearch() {
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    dispatch(getSearchedMember())
  }, [])
  const onSearchClick = () => {};
  return (
    <Grid
      alignItems="center"
      container
      className={classes.topbar}
    >
      <Grid item xs={7} style={{ borderBottom: '1px solid #000' }}>
        <UseAutocomplete />
      </Grid>
      <Grid item xs={5}>
        <Button onClick={onSearchClick}>Search</Button>
      </Grid>
    </Grid>
  );
}

export default GlobalSearch;
