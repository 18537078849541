import React, { useEffect, useState } from 'react'
import { Modal, Button, makeStyles, Box, Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router'
import { ROUTES } from '../../constants/app';
import WarningIcon from '@material-ui/icons/Warning';
import { useSelector } from 'react-redux';
import { fonts, fontSizes } from '../../style/styleSettings';
import EcgLoader from '../../Components/Shared/EcgLoader';

function rand() {
    return Math.round(Math.random() * 10) - 10;
  }
  
//===positioning the modal ===//
  function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }
//============//

  const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: 600,
      backgroundColor: theme.palette.background.paper,
    //   border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      textAlign: 'center',
      '& .MuiTypography-h4':{
        fontSize: fontSizes.LARGE,
        fontFamily: fonts.POPPINS,
        fontWeight: 'bold'
      },
      '& .MuiTypography-body2':{
        fontSize: fontSizes.BASE,
        fontFamily: fonts.SESSION
      },
      '& .MuiButton-root':{
        padding: '12px',
        fontWeight: 'bold',
        fontFamily: fonts.POPPINS,
        minWidth: '100%',
        border: '1px solid #142A39',
        textTransform: 'uppercase'
      },
      '& .MuiSvgIcon-root':{
          fontSize: '5.5rem',
          color: '#FDC246'
      }
    },
  }));

function MemberMissedAlert(props) {
    const classes = useStyles()
    const [modalStyle] = React.useState(getModalStyle) // getModalStyle is not a pure function, we roll the style only on the first render
    const [patientName, setpatientName] = useState({})
    const patientDetails = useSelector((state) => state.appointments?.patientDetails?.patient); //patient details from redux store
    const history = useHistory()
    const [open, setOpen] = React.useState(true);

    useEffect(() => {
        setpatientName(patientDetails)     

    }, [patientDetails])

    const handleClose = () => {
        setOpen(false);
    };

    const inboxNavigation = patientId => {  //navigation route to member inbox page
        history.push(ROUTES.CHAT_INBOX)
        handleClose()
    }
    return (
        <>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Box style={modalStyle} className={classes.paper}>
              {!patientDetails ? 
                <EcgLoader />
              : (
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                      <WarningIcon />
                  </Grid>
                  <Grid item xs={12}>
                      <Typography variant={'h4'}>{`${patientName?.firstName} ${patientName?.lastName}`} has missed this session</Typography>
                  </Grid>
                  <Grid item xs={12}>
                      <Typography variant={'body2'}>{`${patientName?.firstName} ${patientName?.lastName}`} did not join waiting room so the session will be considered missed and 1 credit will be deducted from their session credits.</Typography>
                  </Grid>
                  <Grid item xs={12}>
                      <Button onClick={() => inboxNavigation(patientName?.patientId)}>Message {`${patientName?.firstName} ${patientName?.lastName}`}</Button>
                  </Grid>
                  <Grid item xs={12}>
                      <Button style={{backgroundColor: '#142A39', color: 'white'}} onClick={handleClose}>Okay</Button>                        
                  </Grid>
                </Grid>
              )}
            </Box>
        </Modal>
        </>
    )
}

export default MemberMissedAlert
