import eMask from 'text-mask-addons/dist/emailMask';
import moment from 'moment';
import { getDateOfBirthFormat, getRegion } from '../../../constants/app';

export const emailMask = eMask;
export const phoneNumberMask = [
  /[1-9]/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const phoneNumberMaskRegex = /^[1-9]\d\d \d\d\d-\d\d\d\d$/;
export const phoneNumberMaskRegexNoSymbols = /^[1-9]\d\d\d\d\d\d\d\d\d$/;
export const phoneNumberMaskRegexFor1Prefix = /^[1-9]\d\d\d\d\d\d\d\d\d\d$/;
export const getDateOfBirthMask = (me) => {
  if (me) {
    return me && me.data && me.data.teams[0].useIsoTimeFormat
      ? [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]
      : [/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  }
  return [/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
};

export const integerMask = [/\d/];
export const spaceRegex = /^\s*$/;

export const requiredValidation = (value) => (value ? undefined : 'Required');

export const notJustSpacesValidation = (value) =>
  value && spaceRegex.test(value) ? 'Empty spaces not allowed' : undefined;

export const dateValidation = (value, p1, props) => {
  if (value && value !== '--') {
    const mmt = moment(value, getDateOfBirthFormat(props.me), true);
    const now = moment();
    return !(mmt.isValid() && mmt.isBefore(now, 'day') && mmt.isAfter('0001-01-01', 'year'))
      ? 'Must be a valid date before present time'
      : undefined;
  } else {
    return undefined;
  }
};

export const emailValidation = (value) =>
  value && !/^[A-Z0-9'._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Must be a valid email address'
    : undefined;

export const phoneValidation = (value) =>
  value && value.length > 1 && !phoneNumberMaskRegexNoSymbols.test(value.replace(/[()-\s]/g, ''))
    ? 'Must be a valid 10 digit phone number'
    : undefined;
export const phoneValidationWith1Prefix = (value) =>
  value && value.length > 1 && !phoneNumberMaskRegexFor1Prefix.test(value.replace(/[()-\s]/g, ''))
    ? 'Must be a valid 10 digit phone number with prefix of "1" for country code'
    : undefined;

export const minLenValidationCreator = (min) => (value) =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const minLen1Validation = minLenValidationCreator(1);

export const notesLenValidation = (value) =>
  value && value.length > 10000 ? 'Must be less than 10,000 characters' : undefined;

export const nameValidation = value => {
   return value && /[0-9'._%+-@#$&^*()=+,<>/?]/i.test(value)
          ? 'Must be a valid name' : undefined;
}

let phnRegex = /^\(?([0-9]{3})\)?[]?([0-9]{3})[]?([0-9]{4})$/;

export const minPhoneNumberValidation = value => value && !phnRegex.test(value) ? 'invalid phone number: must be 10 digit number' : undefined

export const ophn1Prefeix = value => value && value[0] != 1 ? 'number should start with 1' : undefined