/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import classes from './Chat.module.css';
import moment from 'moment';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { saveAs } from 'file-saver'; //file downloader npm lib
import GetAppIcon from '@material-ui/icons/GetApp';
/**
 * Display message on chat screen
 * @param {Object} message - contain message data like text, time.
 * @param {string} position -  msg position Left or Right
 * @returns {ReactElement}
 */

const MessageBox = ({
  message,
  setChatLoaded,
  position = 'Right',
  memberName,
  checkForHyperLink,
}) => {
  const fileBaseUrl = process.env.REACT_APP_FILE_RENDER_URL;
  const [chatMessage, setChatMessage] = useState('');

  useEffect(() => {
    setChatLoaded(true);
    return () => setChatLoaded(false);
  }, []);
  useEffect(() => {
    if (message?.text.includes('asset://')) {
      setChatMessage(`${fileBaseUrl}${message.text.split('//')[1].split('?')[0]}`);
    } else {
      setChatMessage(message.text);
    }
  }, [message]);

  return (
    <div className={classes[`message${position}`]}>
      {false ? (
        <div className={classes.imgBox}>
          <img src="https://miro.medium.com/max/640/1*Mom9A9c2MVqI-V-KPz14ag.png" alt="message" />
        </div>
      ) : (
        <div className={classes.messageContainer}>
          {position === 'Left' && (
            <div className={classes.userimg}>{memberName[0].toUpperCase()}</div>
          )}
          {
            // when chat message contains files
            chatMessage && chatMessage.includes(fileBaseUrl) ? (
              chatMessage.includes('mp4') || chatMessage.includes('mov') ? (
                <video
                  width="auto"
                  height="320px"
                  controls
                  poster={
                    chatMessage.includes('mp4')
                      ? chatMessage.replace('.mp4', '-tmb.jpg')
                      : chatMessage.replace('.mov', '-tmb.jpg')
                  }
                >
                  <source src={chatMessage} type="video/mp4" />
                </video>
              ) : chatMessage.includes('pdf') ? (
                <iframe title="docs" src={chatMessage} width="540px" height="400px" />
              ) : chatMessage.includes('mp3') ? (
                // <audio controls="controls" type='audio/mpeg'>
                //   <source type='audio/mp3' src={chatMessage} />
                // </audio>
                <audio src={chatMessage} controls type="audio/mp3"></audio>
              ) : (
                <div className={classes[`imageMessageInnerContainer`]}>
                  {/* to download an image file */}
                  <GetAppIcon
                    onClick={() => {
                      saveAs(chatMessage);
                    }}
                    className={classes['downloadIcon']}
                  />
                  {/* to zoom the image file on click */}
                  <Zoom>
                    <img alt="" src={chatMessage} width="380px" height="auto" />
                  </Zoom>
                </div>
              )
            ) : (
              <div className={classes[`messageInnerContainer${position}`]}>
                <p
                  className={classes.messageContent}
                  dangerouslySetInnerHTML={{ __html: checkForHyperLink(chatMessage) }}
                ></p>
              </div>
            )
          }
        </div>
      )}
      <div className={classes[`messageTimestamp${position}`]}>
        {moment(new Date(message.time)).format('LT')}
      </div>
    </div>
  );
};

export default MessageBox;
