/* eslint-disable */
import React, { Component } from 'react';
import '../../Assets/styles/bs-stepper.scss';
import '../../Assets/styles/bootstrap.scss';
import Stepper from 'bs-stepper';
import Details from '../Appointments/Calendar/Details';
import CountdownTimer from './CountdownTimer';
import moment from 'moment';

export default class appointmentContent extends Component {
  state = {
    timer: null,
    step: 1,
    endTimeFlag: false,
    appointmentDuration: 0,
    counter_title: 'Time remaining',
    visibility_flag: 'visible',
    graceTimeEnds: false,
    graceTimer: 300,
  };

  componentDidMount() {
    if (
      (this.props.appointmentStatus === 'Coach-Review' ||
        this.props.appointmentStatus === 'Encounter-Summary-Preparation' ||
        this.props.appointmentStatus === 'Scheduled' ||
        this.props.appointmentStatus === 'InProgress' ||
        this.props.appointmentStatus.trim() === 'Waiting-Room') &&
      this.props.steppervalue === 1
    ) {
      this.stepper = new Stepper(document.querySelector('#stepper1'), {
        linear: false,
        animation: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.steppervalue !== this.props.steppervalue) {
      if (this.props.steppervalue !== 1) this.stepper.next();
    }
  }

  //calc updated time
  calcUpdatedTime = (duration) => {
    let start = this.props.appointmentStatus === 'InProgress' ? this.props.actualStartTime : this.props.startTime;
    let diffInSeconds = moment().diff(moment(start), 'seconds');
    if (diffInSeconds > duration + this.state.graceTimer) {
      this.state.graceTimeEnds = true;
      return;
    }else if(diffInSeconds < 0){
      return duration - 600;
    }
    // console.log('DIFF IN SECONDS', diffInSeconds);
    return duration - diffInSeconds - 600;
  };
  endTimer = (timer) => {
    if (timer === this.state.graceTimer) {
      this.state.graceTimeEnds = true;
    }else{
      this.state.endTimeFlag = true;
      (this.state.counter_title = 'Grace Period'), (this.state.visibility_flag = 'hidden');
    }
    this.forceUpdate();
  };
  
  render() {
    let end = moment(this.props.endTime);
    let start = moment(this.props.startTime);
    let duration = end.diff(start, 'seconds');

    this.state.appointmentDuration = this.calcUpdatedTime(duration);
    return (
      <div className="telekardia">
        <div className="w-100">
          <div id="stepper1" className="bs-stepper">
            <div className="col-12 p-0">
              <div className="kardiaprotitle">
                <b>Kardia</b>Complete
              </div>
              <div
                className="bs-stepper-header col-12 col-md-6 col-lg-8 col-xl-5 text-center d-flex ml-md-auto mr-md-auto mr-lg-auto m-xl-auto"
                style={{ minHeight: '100px' }}
              >
                <div className="step" data-target="#test-l-1">
                  <button className="step-trigger d-flex flex-column">
                    <span className=""></span>
                    <span className="bs-stepper-label font_title"></span>
                    <span className="bs-stepper-label font_med font_grey m-0"></span>
                  </button>
                </div>
                {/* <div className="line"></div>
                <div className="step" data-target="#test-l-2">
                  <button className="step-trigger  d-flex flex-column">
                    <span className="bs-stepper-circle">2</span>
                    <span className="bs-stepper-label font_title">Step 2</span>
                    <span className="bs-stepper-label font_med font_grey m-0">
                      Session in progress
                    </span>
                  </button>
                </div>
                <div className="line"></div>
                <div className="step" data-target="#test-l-3">
                  <button className="step-trigger  d-flex flex-column">
                    <span className="bs-stepper-circle">3</span>
                    <span className="bs-stepper-label font_title">Step 3</span>
                    <span className="bs-stepper-label font_med font_grey m-0">
                      Post Session
                    </span>
                  </button>
                </div> */}
              </div>
              <div className="limelimit">
                <p className="text-uppercase mb-0 text-center f600 f14">
                  {this.state.counter_title}
                </p>
                <h5 className="d-flex flex-row">
                  {this.state.endTimeFlag && (
                    <CountdownTimer
                      count={this.state.graceTimer}
                      key={1}
                      color={'#CC3D3F'}
                      endTimer={this.endTimer}
                    />
                  )}
                </h5>
                <h5
                  className="d-flex flex-row"
                  style={{ visibility: `${this.state.visibility_flag}` }}
                >
                  <CountdownTimer
                    count={this.state.appointmentDuration}
                    key={1}
                    color={'#e6c029'}
                    endTimer={this.endTimer}
                  />
                </h5>
              </div>
            </div>

            <div className="bs-stepper-content">
              <div id="test-l-1" className="content overflow_modal pt-1 mt-4">
                {this.props.steppervalue === 1 && this.state.step === 1 && (
                  <Details
                    {...this.props}
                    step={1}
                    onSubmit={this.props.onSubmit}
                    disable={this.props.disable}
                    startcalldisable={this.props.startcalldisable}
                    steppervalue={this.props.steppervalue}
                    status={this.props.status}
                    clicked={this.props.clicked}
                    setConsultationNote={this.props.setConsultationNote}
                    consultationNote={this.props.consultationNote}
                    updateoverReadReport={this.props.updateoverReadReport}
                    handleClose={this.props.handleClose}
                    tabvalue={this.props.tabvalue}
                    changetabvalue={this.props.changetabvalue}
                    endTimeFlag={this.state.endTimeFlag}
                    graceTimeEnds={this.state.graceTimeEnds}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
