export const AppointmentStatusTypes = {
  Scheduled: 1,
  Physician_Review: 2,
  InProgress: 3,
  Completed: 4,
  Cancelled: 5,
  Rescheduled: 6,
  Encounter_Summary_Preparation: 7,
  Missed: 8,
  Cancel_For_Reschedule: 9,
  closed: 10,
  Declined: 11,
  MemberInWaitingRoom: 12,
};
export const programMap = {
  'AfibAndHypertension-Id': 'Afib and HTN',
  'Afib-Id': 'Afib',
  'Hypertension-Id': 'HTN',
  'HeartHealth-Id': 'Heart Health',
};
export const deProgramMap = {
  'Afib and HTN': 'AfibAndHypertension-Id',
  'Afib': 'Afib-Id',
  'HTN': 'Hypertension-Id',
  'Heart Health': 'HeartHealth-Id',
};
export const AppointmentClosedReasons = {
  'Patient did not turn up': 1,
  'Technical issue at my end': 3,
  "Technical issue at patient's end": 2,
  'Patient declined consultation': 4,
};

export const CancellationTypes = {
  Patient: 1,
  Physician: 2,
  Auto: 3,
};

export const HeartConditions = {
  Atrial_Fibrillation: 1,
  Bradycardia: 2,
  unreadable: 3,
  Tachycardia: 4,
  Sinus_Tach: 5,
};

export const ProviderStatus = {
  Available: 1,
  Offline: 2,
};
