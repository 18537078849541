import { Box, Grid } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import AsyncChat from '../../../../Components/Chat/AsyncChat';
import { commonStyles } from '../../styles';
import classnames from 'classnames';

const Messages = (props) => {
  const commonClasses = commonStyles();
  const { programData: data, loading, error } = useSelector((state) => state.memberProfile);
  return (
    <Grid container>
      <Grid item lg={12} md={12} style={{ borderLeft: '1px solid #e9e9e9' }}>
        <Box className={classnames(commonClasses.divWrapper)} style={{ marginTop: 10 }}>
          {
            data.chatUserID && 
            <AsyncChat 
              memberName={`${data.participantFirstName} ${data.participantLastName}`} 
              chatUserId={data.chatUserID.toLowerCase()} 
              getS3BucketData={null} 
              upLoadFiles={props.upLoadFiles}
              uploadFileSuccess={props.uploadFileSuccess}
            />
          }
        </Box>
      </Grid>
    </Grid>
  );
};

export default Messages;
